export const noQuotesPunctuation = `!()+,\\-./:;<=>?[\\]^_\`{|}~¡¿—–`; // !()+,\-./:;<=>?[\]^_`{|}~¡¿—–
export const punctuation = `'"${noQuotesPunctuation}`; // !"'()+,-./:;<=>?[]^_`{|}~¡¿—–

// const punctuationRegexOld = /[!"'()+,\-./:;<=>?[\]^_`{|}~¡¿—–]/g;
export const punctuationRegex = new RegExp(`[${punctuation}]`, 'g');
// const trailingPunctuationRegexOld = /([!"'()+,\-./:;<=>?[\]^_`{|}~¡¿—–]+)\s*$/g;
export const trailingPunctuationRegex = new RegExp(
  `([${punctuation}]+)\\s*$`,
  'g'
);
// const leadingPunctuationRegexOld = /^([!"'()+,\-./:;<=>?[\]^_`{|}~¡¿—–]+)\s*/g;
export const leadingPunctuationRegex = new RegExp(
  `^([${punctuation}]+)\\s*`,
  'g'
);
// const allPunctuationRegexOld = /[!"'()+,\-./:;<=>?[\]^_`{|}~¡¿—–]/g;
export const allPunctuationRegex = new RegExp(`[${punctuation}]`, 'g');
export const whitespaceRegex = /\s+/g;

export const splitLines = (text: string): string[] => text.split(/\r?\n/);
export const splitLinesFiltered = (text: string): string[] => {
  return splitLines(text).filter(l => l);
};

export const pathTail = (text: string): string => {
  const paths = text.split('/');
  return paths[paths.length - 1];
};

export function stripTrailingPunctuation(str: string) {
  if (!str) {
    return str;
  }
  return str.replace(trailingPunctuationRegex, '');
}

export function stripLeadingPunctuation(str: string) {
  if (!str) {
    return str;
  }
  return str.replace(leadingPunctuationRegex, '');
}

export function trimPunctuation(str: string) {
  str = stripTrailingPunctuation(str);
  return stripLeadingPunctuation(str);
}
