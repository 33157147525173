import * as React from 'react';
import classNames from 'classnames';

import { BackToFurthestButton } from './back-to-furthest-button';
import { CancelPendingPauseButton } from './cancel-pending-pause-button';
import { ForwardButton } from './forward-button';
import { NextSentenceButton } from './next-sentence-button';
import { observer } from 'mobx-react';
import { paletteKey } from '../../../study/views/study-palette';
import { PlaybackRateControl } from './playback-rate-control';
import { PlayerMode } from 'common/misc-types';
import { PlayPauseButton } from './play-pause-button';
import { PrevSentenceButton } from './prev-sentence-button';
import { ProgressBar } from './progress-bar';
import { Responsive } from '@naan/primitives/responsive';
import { RewindButton } from './rewind-button';
import { StudyFromHereButton } from './study-from-here-button';
import { styled } from '@naan/stitches.config';
import { TranslationButton } from './translation-button';
import { usePlayerKeyboardControls } from './use-keyboard-controls';
import { usePlayerModel } from '../player-model-context';
import { TranslationButtonState } from 'player/models/base-player-model';
import { sentenceScrollObserver } from 'study/views/fx/scrolling';
import { useReaction } from '@common/hooks/use-reaction';
import { RecenterButton } from './recenter-button';
import { isStudyModel } from 'player/models/player-model-handle';

const TransportSpacer = styled('div', {
  width: 64,
  height: 64,
});

const PlayerControlsWrapper = styled('div', {
  display: 'flex',
  alignItems: 'center',
  position: 'relative',
  background: paletteKey('$$transport__backgroundColor'),
  transition: 'background-color 0.2s ease-in',
  paddingTop: 4,

  width: '100%',
  '& > .front': {
    background: paletteKey('$$transport__backgroundColor'),
    display: 'flex',
    padding: '0 16',
    height: 80,
    zIndex: 2,
    alignItems: 'center',
    justifyContent: 'center',
    transition: 'background-color 0.2s ease-in',
    width: '100%',

    '@playerSmallAndUp': {
      margin: '0 auto',
      width: 616,
      position: 'relative',

      '.playback-rate': {
        position: 'absolute',
        left: 0,
      },

      '.translation-button': {
        position: 'absolute',
        right: 0,
      },
    },

    '& > * + *': {
      marginLeft: 5,
    },
  },
  '& > .back': {
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'center',
    position: 'absolute',
    inset: 0,
    zIndex: 1,
    width: '100%',

    '@playerSmallAndUp': {
      margin: '0 auto',
      width: 616,
    },

    '& > .cancel-auto-pause': {
      paddingBottom: 16,
      transform: 'translateY(-100%)',
    },

    '& .sliding-button': {
      // paddingBottom: 16,
      transform: 'translateY(calc( 100% + 16px))',
      transition: 'transform 0.2s ease-out 0.15s',
      position: 'absolute',
      bottom: 0,
      // '&.hidden': {
      //   transform: 'translateY(0)',
      // },
    },

    '& > .sliding-buttons': {
      background: 'red',
      position: 'relative',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      '&.sentence-visible > .study-from-here': {
        transform: 'translateY(-16px)',
      },
      '&:not(.sentence-visible) > .recenter': {
        transform: 'translateY(-16px)',
      },
    },

    // '& > .study-from-here': {
    //   paddingBottom: 16,
    //   transform: 'translateY(-100%)',
    //   transition: 'transform 0.2s ease-out',
    //   '&.hidden': {
    //     transform: 'translateY(0)',
    //   },
    // },

    '& > .back-to-furthest-button': {
      position: 'absolute',
      top: -64,
      right: 0,
      transition: 'translate 0.2s ease-out',
      '&.hidden': {
        translate: '0 200%',
      },
      // transform: 'translateY(50%)',
    },
  },

  '& > .bar': {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
  },
});

export const TranslationButtonContainer = observer(() => {
  const { translationButtonState } = usePlayerModel();

  if (translationButtonState === TranslationButtonState.enabled) {
    return <TranslationButton />;
  }

  if (translationButtonState === TranslationButtonState.disabled) {
    return (
      <>
        {/* <UncontrolledTooltip
          event="click"
          placement={'top'}
          offset={[0, 0]}
          tipContent={
            <Box css={{ minWidth: 300, textAlign: 'left' }}>
              {__(
                'Translation will be available after answer is shown',
                'soundbite.translationWillBeAvailableAfterAnswerIsShown'
              )}
            </Box>
          }
        > */}
        <TranslationButton disabled />
        {/* </UncontrolledTooltip> */}
      </>
    );
  }

  // assuming hidden
  return null;
});

// @armando, what's the best naming convention here?
export const ProgressBarContainer = observer(() => {
  const model = usePlayerModel();
  const ref = React.useRef<HTMLDivElement>(null);
  React.useLayoutEffect(() => {
    function update() {
      if (!ref.current) {
        return;
      }
      ref.current?.style.setProperty(
        '---furthest',
        model.furthestPercentage + '%'
      );
      ref.current?.style.setProperty(
        '---current',
        model.currentPercentage + '%'
      );
      requestAnimationFrame(update);
    }
    update();
  }, [model]);
  if (model.progressBarUI) {
    return (
      <div className="bar">
        <ProgressBar ref={ref} />
      </div>
    );
  } else {
    return null;
  }
});

const useSentenceVisibilityReaction = (
  ref: React.MutableRefObject<HTMLDivElement>
) => {
  useReaction(
    () => sentenceScrollObserver.isCurrentSentenceVisible,
    () => {
      if (ref.current) {
        if (sentenceScrollObserver.isCurrentSentenceVisible) {
          ref.current.classList.add('sentence-visible');
        } else {
          ref.current.classList.remove('sentence-visible');
        }
      }
    }
  );
};

export const PlayerControls = observer(() => {
  const model = usePlayerModel();
  usePlayerKeyboardControls(model);

  const listenMode = model.playerMode === PlayerMode.FLUENT_LISTEN;
  const renderComplexPlayPauseButton = model.complexPlayActionEnabled;

  const studyFromHereRef = React.useRef<HTMLDivElement>(null);

  // useReaction(
  //   () => sentenceScrollObserver.isCurrentSentenceVisible,
  //   () => {
  //     if (studyFromHereRef.current) {
  //       if (sentenceScrollObserver.isCurrentSentenceVisible) {
  //         studyFromHereRef.current.classList.remove('hidden');
  //       } else {
  //         studyFromHereRef.current.classList.add('hidden');
  //       }
  //     }
  //   }
  // );

  useSentenceVisibilityReaction(studyFromHereRef);

  return (
    <PlayerControlsWrapper>
      <ProgressBarContainer />
      <div className="front">
        {listenMode ? (
          <TransportSpacer />
        ) : (
          <div className="playback-rate">
            <PlaybackRateControl />
          </div>
        )}
        <Responsive renderMediumAndUp={() => <PrevSentenceButton />} />
        <RewindButton />
        <PlayPauseButton complex={renderComplexPlayPauseButton} />
        <ForwardButton />
        <Responsive renderMediumAndUp={() => <NextSentenceButton />} />
        <div className="translation-button">
          <TranslationButtonContainer />
        </div>
      </div>
      <div className="back">
        <div className="cancel-auto-pause">
          <CancelPendingPauseButton />
        </div>
        {isStudyModel(model) ? (
          <div className="sliding-buttons" ref={studyFromHereRef}>
            <div className="sliding-button study-from-here">
              <StudyFromHereButton />
            </div>
            <div className="sliding-button recenter">
              <RecenterButton />
            </div>
          </div>
        ) : null}

        {model.backToFurthestUI ? (
          <div
            className={classNames('back-to-furthest-button', {
              hidden: !model.canSeekToFurthest,
            })}
          >
            <BackToFurthestButton />
          </div>
        ) : null}
      </div>
    </PlayerControlsWrapper>
  );
});
