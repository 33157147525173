import { compact, includes, pick } from 'lodash';

import { createLogger } from 'app/logger';
import { applySnapshot, ModelTreeNode, snap } from 'ts-state-tree/tst-core';

import {
  BEGINNING_OF_CHAPTER,
  END_OF_CHAPTER,
  END_OF_STORY_CHAPTER,
  END_OF_VOLUME_UNIT,
} from 'core/lib/constants/vars';

import invariant from 'core/lib/invariant';
import { ChapterRef, LocationPointer } from './location-pointer';
import { Story } from '../story-manager';
import { Root } from '../root';
import { IObservableArray, runInAction } from 'mobx';
import { ListeningStats } from './listening-stats';
import { getBaseRoot } from '../base-root';
import { ChapterCatalogData, UnitCatalogData } from '../catalog';

const log = createLogger('um:story-progress');

export const enum StoryStatus {
  UNQUEUED = 'UNQUEUED',
  QUEUED = 'QUEUED',
  STARTED = 'STARTED', // assigned when 'Begin studying' performed
  COMPLETED = 'COMPLETED', // redundant w/ location pointer
}

/**
 * StoryProgress
 *
 * holds user's listening progress of a particular story
 */
export class StoryProgress extends ModelTreeNode {
  static CLASS_NAME = 'StoryProgress' as const;

  static create(snapshot: any) {
    return super.create(StoryProgress, snapshot) as StoryProgress;
  }

  slug: string;
  storyStatus: StoryStatus; // not sure the best name for this
  currentPoint: LocationPointer = snap({});
  furthestPoint: LocationPointer = snap({});
  vocabs: string[] = []; //  word group slugs, i.e. "765-cruzar" (millis/100 + first word)
  lastListened: number = 0; // millis since epoch

  get root(): Root {
    return getBaseRoot(this);
  }

  get story(): Story {
    const { storyManager } = this.root;
    if (!storyManager) return null;
    return storyManager.story(this.slug);
  }

  get currentUnit(): UnitCatalogData {
    if (this.currentPoint.atEndOfStory) {
      return null;
    }
    const unitNumber = this.currentPoint.unit;
    return this.story.unitDataByNumber(unitNumber);
  }

  get currentChapter(): ChapterCatalogData {
    return this.story?.chapterForPoint(this.currentPoint);
  }

  get furthestChapter(): ChapterCatalogData {
    return this.story?.chapterForPoint(this.furthestPoint);
  }

  // when current chapter is before furthest chapter
  get inReviewMode(): boolean {
    return !this.furthestPoint.matchesChapter(this.currentPoint);
  }

  updateCurrentPoint(point: LocationPointer, updateLastListened = false) {
    log.info(
      `updateCurrentPoint - new point: ${point.sortableString}, old furthest: ${
        this.furthestPoint.sortableString
      }, atEndOfChapter: ${String(point.atEndOfChapter)}`
    );
    runInAction(() => {
      applySnapshot(this.currentPoint, point);
      if (this.furthestPoint.isLessThan(point)) {
        log.info(`new furthest point: ${point.sortableString}`);
        applySnapshot(this.furthestPoint, point);
      }
      if (updateLastListened) {
        this.lastListened = Date.now(); // millis since epoch
      }
    });

    // // handle the case that we've completed the first listen
    // // and then switched back to study mode during fluent listen
    // if (point.chapter === this.furthestPoint.chapter) {
    //   this.furthestPoint.fluentListenStatus = point.fluentListenStatus;
    // }
  }

  /**
   *
   * [ ] - invoked upon exiting study view,
   * [ ] - update the StoryProgress' currentPoint and potentially furthestPoint
   * [ ] - if chapter is completed (based on lastPoint) then add/update ListeningLog entry
   */
  updateProgress({
    unitNumber,
    chapterPosition,
    sessionIteration,
    millis,
  }: {
    unitNumber: number;
    chapterPosition: number;
    sessionIteration: number;
    millis: number;
    // furthestListenedAddress: string, - now just derived from millis
  }) {
    log.info(
      `updateProgress to: ${millis}, chap: ${unitNumber}-${chapterPosition}`
    );
    const { userManager } = this.root;

    log.info(`old cp: ${this.currentPoint.sortableString}`);
    if (
      sessionIteration !== this.currentPoint.iteration ||
      chapterPosition !== this.currentPoint.chapter ||
      unitNumber !== this.currentPoint.unit
    ) {
      log.error(`updateProgress w/ mismatched chapter or iteration - ignoring`);
      return;
    }

    const chapter = this.currentChapter;
    invariant(!!chapter, 'updateProgress - no currentChapter');
    log.info(`current chapter: ${chapter.unitNumber}/${chapter.position}`);
    const isRelisten = sessionIteration > 1;
    const { durationMillis: chapterLengthMillis, markCompleteMillis } = chapter;

    const furthestListenedAddress =
      millis >= markCompleteMillis ? END_OF_CHAPTER : millisToAddress(millis);

    const isChapterComplete = furthestListenedAddress === END_OF_CHAPTER;

    const newPointData = { ...this.currentPoint };

    let automaticallyMarkComplete = false;
    // debugger;
    if (isChapterComplete) {
      // todo: consider removing cap. right now won't record listening for additional listens
      const newIteration = Math.min(sessionIteration + 1, 3);

      if (newIteration !== this.currentPoint.iteration) {
        userManager.userData.addListeningLog(
          this.slug,
          isRelisten ? 0 : chapterLengthMillis,
          isRelisten ? chapterLengthMillis : 0
        );
      }

      newPointData.address = BEGINNING_OF_CHAPTER;
      newPointData.millisPlayed = 0;
      newPointData.iteration = newIteration;

      // debugger;
      // only automatically advance now when in review mode. otherwise, explicit user action needed
      if (newIteration >= 3 && this.inReviewMode) {
        automaticallyMarkComplete = true;
      }
    } else {
      newPointData.address = furthestListenedAddress;
      newPointData.millisPlayed = millis;
    }

    this.updateCurrentPoint(LocationPointer.create(newPointData));

    // could be optimized into a single operation
    if (automaticallyMarkComplete) {
      log.info('automatically marking complete');
      this.markCurrentChapterComplete(); // persists
    } else {
      userManager.persistUserData(); // async
    }
  }

  vocabExists(slug: string): boolean {
    return includes(this.vocabs, slug);
  }

  addVocab(slug: string): void {
    if (!this.vocabExists(slug)) {
      this.vocabs.push(slug);
    }
  }

  addVocabs(slugs: string[] = []): void {
    log.info(`addVocabs[${slugs}]`);
    slugs.map(() => this.addVocab);
  }

  removeVocab(slug: string): void {
    // should we check it exists?
    (this.vocabs as IObservableArray).remove(slug);
  }

  clearVocabs(): void {
    log.info('clearVocabs');
    (this.vocabs as IObservableArray).clear();
  }

  removeVocabs(slugs: string[] = []) {
    log.info(`removeVocabs[${slugs}`);
    slugs.map(() => this.removeVocab);
  }

  /**
   * remove any vocabs now found in current story data
   */
  pruneOrphanVocabs(): void {
    const vocabLookupData = this.story.vocabLookupData;
    const orphans = this.vocabs.filter(slug => {
      return !(vocabLookupData as any)[slug]; // TODO
    });
    if (orphans.length > 0) {
      log.info(`removing orphaned vocabs: ${JSON.stringify(orphans)}`);
      this.removeVocabs(orphans);
    }
  }

  markStoryComplete() {
    const location = {
      unit: END_OF_VOLUME_UNIT,
      chapter: END_OF_STORY_CHAPTER,
    };
    this.currentPoint = LocationPointer.create(location);
    this.furthestPoint = LocationPointer.create(location);
    this.root.userManager.persistUserData(); // async
  }

  resetStory() {
    this.setStoryStatus(StoryStatus.UNQUEUED);
    this.currentPoint = LocationPointer.create({});
    this.furthestPoint = LocationPointer.create({});
    this.clearVocabs();
    this.root.userManager.persistUserData(); // async
  }

  // // used when navigating back to a completed unit
  // relistenStory() {
  //   this.openChapter(1);
  // }

  // openChapter(chapterPosition: number) {
  //   log.info(
  //     `furthestPoint.chapter: ${this.furthestPoint.chapter}, chapterPosition: ${chapterPosition}`
  //   );
  //   const story = this.story;
  //   if (this.currentPoint.chapter === chapterPosition) {
  //     log.info(`resuming current chapter - no change to pointers`);
  //     return;
  //   }
  //   if (this.furthestPoint.chapter < chapterPosition) {
  //     if (chapterPosition > story.chapterCount) {
  //       this.markStoryComplete();
  //     } else {
  //       log.info(`advancing to new chapter: ${chapterPosition}`);
  //       const location = {
  //         chapter: chapterPosition,
  //       };
  //       this.currentPoint = LocationPointer.create(location);
  //       this.furthestPoint = LocationPointer.create(location);
  //     }
  //   } else if (this.furthestPoint.chapter > chapterPosition) {
  //     log.info(`revisiting previous chapter: ${chapterPosition}`);
  //     this.currentPoint = LocationPointer.create({
  //       chapter: chapterPosition,
  //       iteration: 2,
  //     });
  //   } else {
  //     log.info(
  //       `resuming furthest chapter: ${chapterPosition} - old furthest point: ${JSON.stringify(
  //         this.furthestPoint
  //       )}`
  //     );
  //     this.currentPoint = LocationPointer.create({
  //       ...this.furthestPoint,
  //     });
  //   }
  // }

  unlockChapter(chapterRef: ChapterRef): void {
    this.markCompleteChapter(this.story.priorChapterRef(chapterRef));
  }

  reviewChapter(chapterRef: ChapterRef): void {
    this.currentPoint = LocationPointer.create({
      unit: chapterRef.unit,
      chapter: chapterRef.chapter,
      iteration: 3,
    });
    this.root.userManager.persistUserData(); // async
  }

  restartAtChapter(chapterRef: ChapterRef): void {
    this.currentPoint = LocationPointer.create({ ...chapterRef, iteration: 1 });
    this.furthestPoint = this.currentPoint;
    this.root.userManager.persistUserData(); // async
  }

  resumeStudy(): void {
    this.currentPoint = this.furthestPoint; // should we clone it?
    this.root.userManager.persistUserData(); // async
  }

  markCurrentChapterComplete(): void {
    this.markCompleteChapter(this.currentPoint);
  }

  // should probably only ever be used with current chapter
  // also used by "skip to chapter"
  markCompleteChapter(chapterRef: ChapterRef): void {
    const nextChapterRef = this.story.nextChapterRef(chapterRef);
    if (!nextChapterRef) {
      this.markStoryComplete();
      return;
    }

    const iteration = this.furthestPoint.beforeChapter(nextChapterRef) ? 1 : 3;
    const locationData = { ...nextChapterRef, iteration }; // default values are fine for the rest of the properties

    if (this.furthestPoint.matchesChapter(nextChapterRef)) {
      log.info(`advancing to furthest chapter - resuming from furthest point`);
      this.currentPoint = this.furthestPoint;
    } else {
      runInAction(() => {
        this.currentPoint = LocationPointer.create(locationData);
        if (this.furthestPoint.isLessThan(this.currentPoint)) {
          this.furthestPoint = LocationPointer.create(locationData);
        }
      });
    }
    this.root.userManager.persistUserData(); // async
  }

  get unplayed(): boolean {
    return !this.played;
  }

  get played(): boolean {
    return this.furthestPoint?.played;
  }

  get unstarted(): boolean {
    return [StoryStatus.UNQUEUED, StoryStatus.QUEUED].includes(this.status);
  }

  get started(): boolean {
    return this.status === StoryStatus.STARTED;
  }

  get unqueued(): boolean {
    return this.status === StoryStatus.UNQUEUED;
  }

  // i'm pretty sure this is no longer a meaningful state
  // get inProgress(): boolean {
  //   return this.furthestPoint?.played && !this.completed;
  // }

  // effectively a sub-state of completed.
  // used by the volume-level logic which reflects the distinction
  // of 'inProgress' when the user has started relistening after completing a volume
  get relistening(): boolean {
    return this.completed && this.listening;
  }

  // drives the "continue" CTA label
  get listening(): boolean {
    return this.currentPoint?.listening;
  }

  get completed(): boolean {
    return this.furthestPoint?.chapter === END_OF_STORY_CHAPTER;
  }

  get completedChapters(): number {
    // const chaptersCompleted = progress.storyProgress?.furthestPoint ? progress.storyProgress.furthestPoint.chapter + progress.storyProgress.furthestPoint.iteration - 2 : null;
    // if (this.furthestPoint?.chapter && this.furthestPoint?.iteration) {
    //   return this.furthestPoint.chapter + this.furthestPoint.iteration - 2;
    // } else {
    //   return 'n/a';
    // }
    return this.furthestPoint?.completedChapters;
  }

  get displayProgress(): string {
    // todo: localize
    // todo: figure out why this.story is sometimes undefined
    return `${this.completedChapters}/${this.story?.chapterCount} chapters complete`;
  }

  setStoryStatus(status: StoryStatus) {
    console.log(`setStoryStatus: ${status}`);
    this.storyStatus = status;
  }

  get status(): StoryStatus {
    // if (this.unplayed) {
    //   return 'UNPLAYED';
    // }
    // if (this.completed) {
    //   return 'COMPLETED';
    // }
    // return 'IN_PROGRESS';

    if (this.completed) {
      return StoryStatus.COMPLETED;
    }
    if (this.storyStatus) {
      return this.storyStatus;
    }
    if (this.played) {
      return StoryStatus.STARTED;
    }
    return StoryStatus.UNQUEUED;
  }

  // @armando should be using `vocabs` or `vocab` for these getter names?
  get vocabsCount(): number {
    return this.vocabs.length;
  }

  get hasVocab(): boolean {
    return this.vocabs.length > 0;
  }

  /**
   * data needed by vocab view
   */
  get vocabsViewData() {
    const story = this.story;
    if (!story) return null;

    const { vocabLookupData } = story;
    const list = compact(
      this.vocabs.map(slug => {
        return (vocabLookupData as any)[slug]; // TODO
      })
    ).sort((vocabA, vocabB) => {
      if (vocabA.chapterPosition !== vocabB.chapterPosition) {
        return vocabA.chapterPosition - vocabB.chapterPosition;
      }
      return vocabA.address.localeCompare(vocabB.address);
    });
    const chapterMap: { [index: number]: any } = {}; // TODO
    list.forEach(row => {
      let chapterData = chapterMap[row.chapterPosition];
      if (!chapterData) {
        chapterData = pick(row, ['chapterPosition', 'chapterTitle']);
        chapterData.data = [];
        chapterMap[row.chapterPosition] = chapterData;
      }
      chapterData.data.push(row);
    });
    return Object.values(chapterMap);
  }

  get listeningStats(): ListeningStats {
    const { userManager } = this.root;
    if (!userManager) return null;
    return userManager.userData.storyListeningStats(this.slug);
  }
}

// hack mapping from millis listened to old concept of a listening address
const millisToAddress = (millis: number) =>
  String(Math.trunc(millis / 1000)).padStart(3, '0') + '.';
