import {
  IElement,
  IDTOfET,
  ExtractETForIDT,
  IntervalIndexed,
  IndexRange,
  IdRange,
  KTOfET,
  ExtractETForKT,
  SKTOfET,
  ExtractETForSKT,
  WordIdRange,
  WordId,
  ElementList,
  WordElementList,
  ElementId,
  OneIndexedKind,
  OneTimedKind,
  ExtractIndexed,
  ExtractTimed,
  WordElement,
  ELTOf,
} from '../basic-types';
import {
  IntersectOf,
  NeverOnNeverElseND,
  NeverOnNullElseND,
} from '../type-utils';

import { Interval, Intervals } from '../intervals/intervals';
import { GenericElementListFactory } from './element-list-impl';

export const internedKindLists: any[] = [];
export function internKindsList(kinds: readonly string[]) {
  if (internedKindLists.includes(kinds)) {
    return;
  }
  internedKindLists.push(kinds);
}

export interface ElementListSelfReferential<ELT, WLT> {
  words: WLT;
  filterByKind(kind: string): ELT;
  filterByKinds(kinds: readonly string[]): ELT;
  filterBySubKind(subKind: string): ELT;
  getKindListsAsDict(kinds: readonly string[]): { [index in string]: ELT };
  fromIds(ids: ElementId[]): ELT;
  fromIndexes(indexes: number[]): ELT;
  difference(other: any): ELT;
  filter(f: any): ELT;
}

export interface ElementListType<
  ET extends IElement,
  WIT extends any = OneIndexedKind<ET> extends never ? null : Intervals,
  TIT extends any = OneTimedKind<ET> extends never ? null : Intervals
> extends ElementListSelfReferential<any, any> {
  values: ET[];
  words: ElementList<WordElement>;
  // idToIndexMap: { [index in IDTOfET<ET>]: number };
  idToIndexMap: { [index in ElementId]: number };
  getIndex(id: IDTOfET<ET>): number;
  getId(index: number): IDTOfET<ET>;
  getElement<IDT extends ElementId>(
    id: IDT
  ): ExtractETForIDT<ET, IDT & IDTOfET<ET>>;
  lookupElement<IDT extends IDTOfET<ET>>(id: IDT): ExtractETForIDT<ET, IDT>;
  hasElement(id: ElementId): boolean;
  isEmpty(): boolean;
  notEmpty(): boolean;

  wordIntervals: WIT;
  timeIntervals: TIT;

  idRangeToIndexRange(range: IdRange<IDTOfET<ET>>): IndexRange;
  indexRangeToIdRange(range: IndexRange): IdRange<IDTOfET<ET>>;
  stepId(
    id: ElementId,
    direction: number,
    domain?: any, // TODO some kind of union type with useAddresses = true?
    useAddresses?: boolean
  ): IDTOfET<ET>;
  nextId(
    id: ElementId,
    domain?: any, // TODO
    useAddresses?: boolean
  ): IDTOfET<ET>;
  prevId(
    id: ElementId,
    domain?: any, // TODO
    useAddresses?: boolean
  ): IDTOfET<ET>;
  // rangeAsSeq(range: IndexRange): Generator<number, void, unknown>; REMOVE
  idRangeAsIds(range: IdRange<IDTOfET<ET>>): IDTOfET<ET>[];
  rangeAsElements(range: IndexRange): ET[];
  idRangeAsElements(range: IdRange<IDTOfET<ET>>): ET[];
  findNext(startId: IDTOfET<ET>, f: (el: ET) => boolean): ET;
  findPrevious(startId: IDTOfET<ET>, f: (el: ET) => boolean): ET;
  findStep(startId: IDTOfET<ET>, f: (el: ET) => boolean, direction: number): ET;
  findNextOfKinds<KT extends KTOfET<ET>>(
    startId: IDTOfET<ET>,
    kinds: readonly KT[]
  ): ExtractETForKT<ET, KT>;
  findPreviousOfKinds<KT extends KTOfET<ET>>(
    id: IDTOfET<ET>,
    kinds: readonly KT[]
  ): ExtractETForKT<ET, KT>;
  filter(f: (el: ET) => boolean): ElementList<ET>;
  fromIds<IDT extends IDTOfET<ET>>(
    ids: IDT[]
  ): ElementList<ExtractETForIDT<ET, IDT>>;
  fromIndexes(indexes: number[]): ElementList<ET>;

  // TODO change names to ex. filterByKind?
  filterByKind<KT extends KTOfET<ET>>(
    kind: KT
  ): ElementList<ExtractETForKT<ET, KT>>;
  filterByKinds<KT extends KTOfET<ET>>(
    kinds: readonly KT[]
  ): ElementList<ExtractETForKT<ET, KT>>;
  filterBySubKind<SKT extends SKTOfET<ET>>(
    kind: SKT
  ): ElementList<ExtractETForSKT<ET, SKT>>;
  // getKindsSubListsAsArray(kinds: EKind[]): LT[]; REMOVE

  getKindListsAsDict<KT extends KTOfET<ET>>(
    kinds: readonly KT[]
  ): IntersectOf<{
    KT: ElementList<ExtractETForKT<ET, KT>>;
  }>;
  address<IDT extends IDTOfET<ET>>(
    id: IDT
  ): NeverOnNeverElseND<ExtractIndexed<ExtractETForIDT<ET, IDT>>, number>; // TODO X
  endAddress<IDT extends IDTOfET<ET>>(
    id: IDT
  ): NeverOnNeverElseND<ExtractIndexed<ExtractETForIDT<ET, IDT>>, number>; // TODO X
  getWordInterval<IDT extends IDTOfET<ET>>(
    id: IDT
  ): NeverOnNeverElseND<ExtractIndexed<ExtractETForIDT<ET, IDT>>, Interval>; // TODO X
  getElementsIntersectWordIndexRange(
    range: IndexRange
  ): NeverOnNullElseND<WIT, ET[]>;
  getElementsIntersectWordIdRange(
    range: WordIdRange
  ): NeverOnNullElseND<WIT, ET[]>;
  hasElementsIntersectWordIdRange(
    range: WordIdRange
  ): NeverOnNullElseND<WIT, boolean>;
  hasElementsIntersectWordIndexRange(
    range: IndexRange
  ): NeverOnNullElseND<WIT, boolean>;
  getElementsIntersectRangeOf(
    element: IntervalIndexed
  ): NeverOnNullElseND<WIT, ET[]>;
  hasElementsIntersectRangeOf(
    element: IntervalIndexed
  ): NeverOnNullElseND<WIT, boolean>;
  getElementsStartWithinWordIndexRange(
    range: IndexRange
  ): NeverOnNeverElseND<ExtractIndexed<ET>, ET[]>;
  getElementStartWithinWordIdRange(
    range: WordIdRange
  ): NeverOnNeverElseND<ExtractIndexed<ET>, ET[]>;
  hasElementsStartWithinWordIndexRange(
    range: IndexRange
  ): NeverOnNeverElseND<ExtractIndexed<ET>, boolean>;
  hasElementsStartWithinWordIdRange(
    range: WordIdRange
  ): NeverOnNeverElseND<ExtractIndexed<ET>, boolean>;
  getElementsStartWithinRangeOf(
    element: IntervalIndexed
  ): NeverOnNeverElseND<ExtractIndexed<ET>, ET[]>;
  hasElementsStartWithinRangeOf(
    element: IntervalIndexed
  ): NeverOnNeverElseND<ExtractIndexed<ET>, boolean>;
  time<IDT extends IDTOfET<ET>>(
    id: IDT
  ): NeverOnNeverElseND<ExtractTimed<ExtractETForIDT<ET, IDT>>, number>; // TODO X
  endTime<IDT extends IDTOfET<ET>>(
    id: IDT
  ): NeverOnNeverElseND<ExtractTimed<ExtractETForIDT<ET, IDT>>, number>; // TODO X
  getTimeInterval<IDT extends IDTOfET<ET>>(
    id: IDT
  ): NeverOnNeverElseND<ExtractTimed<ExtractETForIDT<ET, IDT>>, Interval>; // TODO X
  getElementContainingWordAddress(address: number): NeverOnNullElseND<WIT, ET>;
  getElementContainingWordId(id: WordId): NeverOnNullElseND<WIT, ET>;
  getElementContainingTime(time: number): NeverOnNullElseND<TIT, ET>;
  // joinWithIdMap<T>(key: string, map: ElementIdMap<T>, defaults: any): LT;
  difference(elementList: ElementList<ET>): ElementList<ET>;
  remapContentDimensionedArray<T>(remap: T[], newList: ELTOf<IElement>): T[];

  // transformHeadsToSpans(
  //   // TODO problem because does not change type of elements in list?
  //   // also because getting rid of *_HEAD and *_SPAN types
  //   headKind: IDTOfET<ET>,
  //   spanKind: IDTOfET<ET>,
  //   options: {
  //     expandIntoGaps?: boolean;
  //     breakWordMap?: Map<number, number>;
  //     audioEndTime?: number;
  //   }
  // ): void;
}

export function CreateElementList<
  ET extends IElement,
  WLT extends WordElementList | null = null
>({ elements, words }: { elements: ET[]; words?: WLT }): ElementList<ET> {
  return GenericElementListFactory(elements, words);
}
