import { NO_INDEX } from '../basic-types';
import { Intervals } from '../intervals/intervals';

export class TrackingState {
  intervals: Intervals = null;
  vIdx = 0;
  position = 0;
  furthestPosition = 0;
  currentIsUnderIndex = NO_INDEX;
  lastBeforeIndex = NO_INDEX;
  lastFurthestIndex = NO_INDEX;

  isUnderOldChangeIndex = NO_INDEX;
  isUnderNewChangeIndex = NO_INDEX;
  isBeforeChangeRangeStart = NO_INDEX;
  isBeforeChangeRangeEnd = NO_INDEX;
  isVisitedChangeRangeStart = NO_INDEX;
  isVisitedChangeRangeEnd = NO_INDEX;
  anyChangeRecord = false;
}

// // TODO constructor etc

export function refreshIntervals(state: TrackingState, intervals: Intervals) {
  state.intervals = intervals;
}

export function advanceVIdxBecauseStateChange(state: TrackingState) {
  state.vIdx = state.vIdx + 1;
}

export function clearChangeRecords(state: TrackingState) {
  state.isUnderOldChangeIndex = NO_INDEX;
  state.isUnderNewChangeIndex = NO_INDEX;
  state.isBeforeChangeRangeStart = NO_INDEX;
  state.isBeforeChangeRangeEnd = NO_INDEX;
  state.isVisitedChangeRangeStart = NO_INDEX;
  state.isVisitedChangeRangeEnd = NO_INDEX;
  state.anyChangeRecord = false;
}

export function recordChanged(state: TrackingState) {
  state.anyChangeRecord = true;
}

export function recordIsUnderChanges(
  state: TrackingState,
  oldIndex: number,
  newIndex: number
) {
  state.isUnderOldChangeIndex = oldIndex;
  state.isUnderNewChangeIndex = newIndex;
}

export function recordIsBeforeChanges(
  state: TrackingState,
  begin: number,
  end: number
) {
  state.isBeforeChangeRangeStart = begin;
  state.isBeforeChangeRangeEnd = end;
}

export function recordIsVisitedChanges(
  state: TrackingState,
  begin: number,
  end: number
) {
  state.isVisitedChangeRangeStart = begin;
  state.isVisitedChangeRangeEnd = end;
}

const visitedTolerance = 2;
export function recordChangesForNewPosition(
  state: TrackingState,
  position: number
) {
  state.position = position;
  const intervals = state.intervals;

  if (position > state.furthestPosition) {
    state.furthestPosition = position;
  }

  if (
    state.currentIsUnderIndex !== NO_INDEX &&
    intervals.doesContain(state.currentIsUnderIndex, position) &&
    state.currentIsUnderIndex === state.lastBeforeIndex
  ) {
    return;
  }

  const currentIsUnderIndex = intervals.containing(position);
  let currentBeforeIndex = intervals.lastStartsBeforeOrAt(position);
  if (currentBeforeIndex !== NO_INDEX) {
    const start = intervals.startPoints[currentBeforeIndex];
    // considers range only "visited" if position exceeds tolerance from start
    // needed because of possible overshoot on pauseafter into starting word of next sentence
    if (start + visitedTolerance > position) {
      currentBeforeIndex--;
    }
  }

  if (
    currentIsUnderIndex === state.currentIsUnderIndex &&
    currentBeforeIndex === state.lastBeforeIndex
  ) {
    return;
  }

  advanceVIdxBecauseStateChange(state);
  recordChanged(state);

  if (currentIsUnderIndex !== state.currentIsUnderIndex) {
    recordIsUnderChanges(state, state.currentIsUnderIndex, currentIsUnderIndex);
    state.currentIsUnderIndex = currentIsUnderIndex;
  }

  if (currentBeforeIndex === state.lastBeforeIndex) {
    return;
  }

  let startChangeIndex =
    Math.min(currentBeforeIndex, state.lastBeforeIndex) + 1;
  let endChangeIndex = Math.max(currentBeforeIndex, state.lastBeforeIndex);
  recordIsBeforeChanges(state, startChangeIndex, endChangeIndex);

  state.lastBeforeIndex = currentBeforeIndex;

  if (state.lastFurthestIndex > currentBeforeIndex) {
    return;
  }

  startChangeIndex = Math.min(currentBeforeIndex, state.lastFurthestIndex) + 1;
  endChangeIndex = Math.max(currentBeforeIndex, state.lastFurthestIndex);
  recordIsVisitedChanges(state, startChangeIndex, endChangeIndex);
  state.lastFurthestIndex = currentBeforeIndex;
}

export function intervalAt(state: TrackingState, idx: number) {
  return state.intervals.intervalAt(idx);
}

export function currentIsUnder(state: TrackingState) {
  return state.currentIsUnderIndex;
}

export function isUnder(state: TrackingState, idx: number) {
  return state.intervals.doesContain(idx, state.position);
}

export function isBefore(state: TrackingState, idx: number) {
  return state.intervals.doesStartBeforeOrAt(idx, state.position);
}

export function isVisited(state: TrackingState, idx: number) {
  const position = state.position;
  if (position <= state.furthestPosition) {
    return idx <= state.lastFurthestIndex;
  } else {
    const interval = intervalAt(state, idx);
    return interval.begin <= position;
  }
}
