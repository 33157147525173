import React from 'react';

import { useTitle } from 'common/hooks/use-title';
import { PageHeading } from 'components/page-heading';
import { FilteringProvider } from 'common/filtering/filtering-provider';
import { FiltersUi } from 'components/discover/filters-ui';
import { FilteredUnits } from 'components/discover/filtered-units/filtered-units';
// import { TeacherResources } from 'components/teacher-resources';
import { styled } from 'naan/stitches.config';
import { observer } from 'mobx-react';

// import { OldHeaderContainer } from 'components/header';
import {
  ContentGrid,
  ContentGridInnerContainer,
} from 'components/global-layout';
import { WelcomeMessage } from 'components/welcome-message';
import { useLocation } from 'react-router-dom';

import __ from 'core/lib/localization';
import { DeepScrollRestoration } from 'lib/scroll-utils/deep-scroll-restoration';

const LayoutWrapper = styled('section', {
  gridColumn: '1/-1',
});

export const DiscoverScreen = observer(() => {
  useTitle(__('Discover', 'discover.discover'));

  const location = useLocation();

  // useScrollRestoration(location.pathname);

  return (
    <>
      <DeepScrollRestoration pageKey={location.pathname} />
      <FilteringProvider>
        <WelcomeMessage />
        <ContentGridInnerContainer>
          <PageHeading title={__('Discover', 'discover.discover')} />
          <FiltersUi />
          <ContentGrid>
            <LayoutWrapper>
              <FilteredUnits />
            </LayoutWrapper>
          </ContentGrid>
        </ContentGridInnerContainer>
      </FilteringProvider>
    </>
  );
});

export default DiscoverScreen;
