import { keyframes, styled } from '@naan/stitches.config';
import * as React from 'react';

export const PlayControlIcon = ({
  color = 'currentColor',
  size = 64,
}: {
  color?: string;
  size?: number;
}) => (
  <>
    <svg
      width={size}
      height={size}
      viewBox="0 0 64 64"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        width={4}
        height={4}
        rx={1}
        transform="matrix(0.75914 0.650927 -0.650928 0.759139 31.6035 28)"
        fill={color}
      />
      <circle
        cx={32}
        cy={32}
        r={23}
        // fill="#F9F9F9"
        fill="transparent"
        stroke={color}
        strokeWidth={2}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M42.112 32.6691L28.4395 41.6245C28.0699 41.8666 27.574 41.7632 27.3319 41.3936C27.2466 41.2634 27.2012 41.111 27.2012 40.9553V23.0445C27.2012 22.6027 27.5593 22.2445 28.0012 22.2445C28.1569 22.2445 28.3092 22.29 28.4395 22.3753L42.112 31.3307C42.4816 31.5728 42.585 32.0686 42.3429 32.4382C42.2826 32.5302 42.204 32.6088 42.112 32.6691Z"
        fill={color}
      />
    </svg>
  </>
);

export const SimplePauseControlIcon = ({
  color = 'currentColor',
  size = 64,
}: {
  color?: string;
  size?: number;
}) => (
  <>
    <svg
      width={size}
      height={size}
      viewBox="0 0 64 64"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle
        cx={32}
        cy={32}
        r={23}
        // fill="#F9F9F9"
        fill="transparent"
        stroke={color}
        strokeWidth={2}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M29 22C29.5523 22 30 22.4477 30 23V41C30 41.5523 29.5523 42 29 42H26C25.4477 42 25 41.5523 25 41V23C25 22.4477 25.4477 22 26 22H29ZM38 22C38.5523 22 39 22.4477 39 23V41C39 41.5523 38.5523 42 38 42H35C34.4477 42 34 41.5523 34 41V23C34 22.4477 34.4477 22 35 22H38Z"
        fill={color}
      />
    </svg>
  </>
);

export const RequestPauseControlIcon = ({
  color = 'currentColor',
  size = 64,
}: {
  color?: string;
  size?: number;
}) => (
  <>
    <svg
      width={size}
      height={size}
      viewBox="0 0 64 64"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M23 4C23 2.89543 23.8954 2 25 2H39C40.1046 2 41 2.89543 41 4C41 5.10457 40.1046 6 39 6H25C23.8954 6 23 5.10457 23 4Z"
        fill={color}
      />
      <rect
        width={4}
        height={4}
        rx={1}
        transform="matrix(0.75914 0.650927 -0.650928 0.759139 50.6035 8)"
        fill={color}
      />
      <circle
        cx={32}
        cy={32}
        r={23}
        fill="transparent"
        stroke={color}
        strokeWidth={2}
      />
      <path
        d="M29 22C29.5523 22 30 22.4477 30 23V41C30 41.5523 29.5523 42 29 42H26C25.4477 42 25 41.5523 25 41V23C25 22.4477 25.4477 22 26 22H29ZM38 22C38.5523 22 39 22.4477 39 23V41C39 41.5523 38.5523 42 38 42H35C34.4477 42 34 41.5523 34 41V23C34 22.4477 34.4477 22 35 22H38Z"
        fill={color}
      />
    </svg>
  </>
);

const anim = keyframes({
  '0%': { strokeDashoffset: '$$initialOffset' },
  '100%': { strokeDashoffset: '$$finalOffset' },
});

const CircularSvg = styled('svg', {
  $$duration: '3000ms',
  fill: 'none',
  '& > .bg-circle': {
    stroke: '$colors$red-100',
  },
  '& > .progress-circle': {
    stroke: '$colors$red-500',
    // strokeDashoffset: '$$initialOffset',

    strokeDashoffset: '$$finalOffset',
    animation: `${anim} $$duration cubic-bezier(0.12, 0, 0.39, 0)`,
    transformBox: 'fill-box',
    transformOrigin: 'center',
    transform: 'rotate(-90deg)',
  },
  '& > .icon': {
    fill: '$colors$red-500',
  },
});

export const PauseControlIcon = ({
  durationInMs = 4000,
  size = 64,
}: {
  durationInMs: number;
  size?: number;
}) => {
  const center = size / 2;
  const strokeWidth = 2;
  const radius = 23;
  const dashArray = 2 * Math.PI * radius;

  return (
    <>
      <CircularSvg
        width={size}
        height={size}
        viewBox="0 0 64 64"
        xmlns="http://www.w3.org/2000/svg"
        css={{
          $$initialOffset: dashArray,
          $$finalOffset: 0,
          $$duration: `${durationInMs}ms`,
        }}
      >
        <circle
          cx={center}
          cy={center}
          r={radius}
          strokeWidth={strokeWidth}
          className="bg-circle"
        />
        <circle
          cx={center}
          cy={center}
          r={radius}
          className="progress-circle"
          strokeWidth={strokeWidth}
          strokeDasharray={dashArray}
          strokeDashoffset={dashArray}
        />
        <path
          className="icon"
          d="M29 22C29.5523 22 30 22.4477 30 23V41C30 41.5523 29.5523 42 29 42H26C25.4477 42 25 41.5523 25 41V23C25 22.4477 25.4477 22 26 22H29ZM38 22C38.5523 22 39 22.4477 39 23V41C39 41.5523 38.5523 42 38 42H35C34.4477 42 34 41.5523 34 41V23C34 22.4477 34.4477 22 35 22H38Z"
        />
      </CircularSvg>
    </>
  );
};

export const RewindControlIcon = ({
  color = 'currentColor',
  size = 64,
}: {
  color?: string;
  size?: number;
}) => (
  <>
    <svg
      width={size}
      height={size}
      viewBox="0 0 64 64"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M29.0722 19.2929C28.6817 19.6834 28.6817 20.3166 29.0722 20.7071L33.3148 24.9497C33.7054 25.3403 34.3385 25.3403 34.729 24.9497C35.1196 24.5592 35.1196 23.9261 34.729 23.5355L31.9496 20.7561C31.9663 20.7569 31.9831 20.7573 32 20.7573C38.6274 20.7573 44 26.1299 44 32.7574C44 39.3848 38.6274 44.7574 32 44.7574C25.3726 44.7574 20 39.3848 20 32.7574C20 32.2051 19.5523 31.7574 19 31.7574C18.4477 31.7574 18 32.2051 18 32.7574C18 40.4893 24.268 46.7574 32 46.7574C39.732 46.7574 46 40.4893 46 32.7574C46 25.1691 39.9629 18.9909 32.4297 18.7638L34.729 16.4645C35.1196 16.0739 35.1196 15.4408 34.729 15.0503C34.3385 14.6597 33.7054 14.6597 33.3148 15.0503L29.0722 19.2929Z"
        fill={color}
      />
    </svg>
  </>
);

export const FFControlIcon = ({
  color = 'currentColor',
  size = 64,
}: {
  color?: string;
  size?: number;
}) => (
  <>
    <svg
      width={size}
      height={size}
      viewBox="0 0 64 64"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M34.9278 19.2929L35.6349 20L34.9278 20.7071L30.6852 24.9497C30.2946 25.3402 29.6615 25.3402 29.271 24.9497C28.8804 24.5592 28.8804 23.926 29.271 23.5355L32.0504 20.7561C32.0337 20.7569 32.0169 20.7573 32 20.7573C25.3726 20.7573 20 26.1299 20 32.7573C20 39.3847 25.3726 44.7573 32 44.7573C38.6274 44.7573 44 39.3847 44 32.7573C44 32.205 44.4477 31.7573 45 31.7573C45.5523 31.7573 46 32.205 46 32.7573C46 40.4893 39.732 46.7573 32 46.7573C24.268 46.7573 18 40.4893 18 32.7573C18 25.1691 24.0371 18.9909 31.5703 18.7638L29.271 16.4644C28.8804 16.0739 28.8804 15.4407 29.271 15.0502C29.6615 14.6597 30.2946 14.6597 30.6852 15.0502L34.9278 19.2929Z"
        fill={color}
      />
    </svg>
  </>
);

export const TranslationControlIcon = ({
  color = 'currentColor',
  size = 64,
}: {
  color?: string;
  size?: number;
}) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 64 64"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M38.6667 24.1477C38.6667 24.295 38.5473 24.4144 38.4001 24.4144H35.4694C34.3107 27.564 32.7773 30.138 30.7776 32.215C31.7115 32.9744 32.7388 33.6481 33.8611 34.227C33.9812 34.2889 34.036 34.4308 33.9887 34.5573L33.3917 36.1552C33.3652 36.2261 33.3098 36.2825 33.2393 36.3101C33.1688 36.3378 33.0899 36.3342 33.0222 36.3001C31.5939 35.5824 30.3123 34.7197 29.1652 33.7174C26.9381 35.5657 24.2305 36.9266 21.0331 37.9556C20.8989 37.9988 20.7543 37.93 20.703 37.7987C20.643 37.6449 20.4994 37.3922 20.3221 37.126C20.1466 36.8624 19.9536 36.6089 19.8062 36.4528C19.7423 36.3852 19.7183 36.2892 19.7428 36.1994C19.7673 36.1097 19.8368 36.0392 19.9262 36.0135C23.0091 35.1248 25.5549 33.904 27.6454 32.2485C25.6661 30.0975 24.1528 27.4722 23.0317 24.4144H20.2601C20.1128 24.4144 20.0001 24.295 20.0001 24.1477V22.6753C20.0001 22.5281 20.1128 22.4087 20.2601 22.4087H28.2148V19.2667C28.2148 19.1194 28.3342 19 28.4815 19H29.9996C30.1469 19 30.2663 19.1194 30.2663 19.2667V22.4087L38.4001 22.4087C38.5473 22.4087 38.6667 22.5281 38.6667 22.6753V24.1477ZM33.1583 24.4144H25.2637C26.2035 26.849 27.5163 29.0037 29.2313 30.8044C30.8924 29.0747 32.1867 26.9677 33.1583 24.4144ZM40.5321 39.4787C40.6447 39.4787 40.7451 39.5494 40.7831 39.6553L41.9197 42.8235C41.9577 42.9294 42.0582 43.0001 42.1707 43.0001H44.0143C44.2008 43.0001 44.3297 42.8135 44.2637 42.6391L39.039 28.833C38.9998 28.7293 38.9005 28.6607 38.7896 28.6607H37.2881C37.1772 28.6607 37.0778 28.7294 37.0386 28.8332L31.8234 42.6392C31.7575 42.8136 31.8864 43.0001 32.0729 43.0001H33.9063C34.0191 43.0001 34.1197 42.9291 34.1576 42.8229L35.2849 39.6559C35.3227 39.5497 35.4233 39.4787 35.5361 39.4787H40.5321ZM36.3315 37.6524C36.1478 37.6524 36.0192 37.4711 36.0797 37.2978L37.7821 32.4245C37.8654 32.1863 38.2024 32.1862 38.2856 32.4245L39.988 37.2978C40.0486 37.4711 39.9199 37.6524 39.7363 37.6524H36.3315Z"
      fill={color}
    />
  </svg>
);

export const PrevSentenceControlIcon = ({
  color = 'currentColor',
  size = 64,
}: {
  color?: string;
  size?: number;
}) => (
  <>
    <svg
      width={size}
      height={size}
      viewBox="0 0 64 64"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M46 32L23 32M23 32L32.5 23M23 32L32.5 41M19 21L19 43"
        strokeWidth={2}
        stroke={color}
        strokeLinejoin="round"
      />
    </svg>
  </>
);

export const NextSentenceControlIcon = ({
  color = 'currentColor',
  size = 64,
}: {
  color?: string;
  size?: number;
}) => (
  <>
    <svg
      width={size}
      height={size}
      viewBox="0 0 64 64"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M18 32L41 32M41 32L31.5 41M41 32L31.5 23M45 43L45 21"
        strokeWidth={2}
        stroke={color}
        strokeLinejoin="round"
      />
    </svg>
  </>
);
