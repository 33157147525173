import { makeObservable, observable } from 'mobx';
import { StructuredPlayer } from './structured-player';
import { Navigation, NavigationPoint } from '../navigation/timeline-navigator';
// import { AudioTransport, TransportState } from './audio-transport';

const REWIND_TO_PREVIOUS_WHILE_PLAYING_TOLERANCE_MS = 500;
export const NAVIGATION_SOFT_PAUSE_MS = 300;

export class NavigationState {
  navigationPoint: NavigationPoint;
  constructor() {
    this.navigationPoint = null;
    makeObservable(this, {
      navigationPoint: observable.ref,
    });
  }
}

type GConstructor<T = {}> = new (...args: any[]) => T;
type PlayerThingy = GConstructor<StructuredPlayer>;

export function MultiNavigating<TBase extends PlayerThingy>(Base: TBase) {
  return class MultiNavigator extends Base {
    navigationState: NavigationState;
    nav: Navigation;

    initNavigation(navigationState: NavigationState, navigation: Navigation) {
      this.navigationState = navigationState;
      this.nav = navigation;
    }

    // TODO expose move method on navigators and eliminate duplicate code?
    next() {
      const currentPoint = this.navigationState.navigationPoint;
      const nextPoint = this.nav.next(currentPoint);
      if (nextPoint !== currentPoint) {
        this.seek(nextPoint.position);
        this.navigationState.navigationPoint = nextPoint;
      }
    }

    prev() {
      const currentPoint = this.navigationState.navigationPoint;
      const nextPoint = this.nav.prev(currentPoint);
      if (nextPoint !== currentPoint) {
        this.seek(nextPoint.position);
        this.navigationState.navigationPoint = nextPoint;
      }
    }

    // beware 'keepPauseAfter' is intertwined with undesired chaat auto-rewind, should probably always be true for non-chaat code
    nextClosest(keepPauseAfter = false) {
      const point = this.navigationState.navigationPoint;
      if (point) {
        const nextPoint = this.nav.nextClosest(point, this.getAudioPosition());
        if (nextPoint) {
          this.navigationState.navigationPoint = nextPoint;
          if (this.transportState.isPlaying) {
            this.pauseThenPlayAt(
              NAVIGATION_SOFT_PAUSE_MS,
              nextPoint.position,
              keepPauseAfter
            );
          } else {
            this.seek(nextPoint.position, keepPauseAfter);
          }
        }
      }
    }

    // beware 'keepPauseAfter' is intertwined with undesired chaat auto-rewind, should probably always be true for non-chaat code
    prevClosest(keepPauseAfter = false) {
      const point = this.navigationState.navigationPoint;
      if (point) {
        const tolerance = this.transportState.isPlaying
          ? REWIND_TO_PREVIOUS_WHILE_PLAYING_TOLERANCE_MS
          : 10;
        const nextPoint = this.nav.prevClosest(
          point,
          this.getAudioPosition() - tolerance
        );
        // TODO factor out this code also in nextClosest??
        if (nextPoint) {
          this.navigationState.navigationPoint = nextPoint;
          if (this.transportState.isPlaying) {
            this.pauseThenPlayAt(
              NAVIGATION_SOFT_PAUSE_MS,
              nextPoint.position,
              keepPauseAfter
            );
          } else {
            this.seek(nextPoint.position, keepPauseAfter);
          }
        }
      }
    }

    playSection() {
      const point = this.navigationState.navigationPoint;
      if (point) {
        const nextPoint = this.nav.nextClosest(
          point,
          this.getAudioPosition() + 50
        );
        if (nextPoint) {
          this.audioTransport.setPauseAfter(nextPoint.position);
          this.audioTransport.clearAudioRestartPosition();
          this.play();
        }
      }
    }

    seekToNavigationPoint(point: NavigationPoint) {
      if (point) {
        this.seek(point.position);
      }
    }

    setNavigationPoint(point: NavigationPoint) {
      this.navigationState.navigationPoint = point;
      this.seekToNavigationPoint(point);
    }

    navigate(key: string, index: number) {
      const navigator = this.nav.getNavigatorForKey(key);
      const point = navigator.navigationPoint(index);
      this.setNavigationPoint(point);
    }
  };
}
