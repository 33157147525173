import ReactGA from 'react-ga4';

import { getConfig } from 'app/env';
import { createLogger } from 'app/logger';
import { AnalyticsAdapter } from '../analytics-adapter';

const log = createLogger('analytics:ga');

const gaKey = getConfig('analytics.googleAnalyticsKey', null);
const prefix = getConfig('analytics.eventPrefix');

export class GoogleAnalytics extends AnalyticsAdapter {
  serviceName = 'GoogleAnalytics';
  isEnabled = gaKey !== null;

  private initialized: boolean = false;

  identify(userId: string) {
    log.debug('identify', { userId });
    ReactGA.initialize(gaKey, { userId } as any); // todo: confirm if the userId is even meaningful here
    this.initialized = true;
  }

  ensureInitialized() {
    if (!this.initialized) {
      ReactGA.initialize(gaKey);
      this.initialized = true;
    }
  }

  track(eventName: string, data?: any) {
    log.debug('track', { eventName, data });
    this.ensureInitialized();
    const params = { category: prefix } as any;
    if (data) {
      for (const prop in data) {
        params[`metric_${prop}`] = data[prop];
      }
    }
    log.info('params: ', params);
    ReactGA.event(eventName, params);
  }

  page(pageName: string, data?: any) {
    // @armando, do you recall why we didn't track page view if not initalized, but we still tracked events?
    // if (this.initialized) {
    log.debug('page', { pageName, data });
    this.ensureInitialized();
    ReactGA.send({
      hitType: 'pageview',
      page: pageName,
      title: pageName,
      // metric_foo: 'bar', // not sure if custom metric data can be added to page views
    });
    // }
  }
}
