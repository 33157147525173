import * as React from 'react';
import { ContentGrid } from 'components/global-layout';
import { useRootStore } from 'mst';
import { AccountForm } from 'components/account/account-form';
import { AccountFlash } from 'components/account/account-flash';
import { MembershipTable } from 'components/account/membership-table';
import { CheckoutResultTarget } from 'components/account/checkout-result-target';
import { AccountCta } from 'components/account/account-cta';
import { useTitle } from 'common/hooks/use-title';
import { PageHeading } from 'components/page-heading';
// import { VSpacer } from 'naan/primitives/spacer';
import { observer } from 'mobx-react';
// import { CouponButton } from 'components/account/marketing-links/coupon-button';
import { JoinedClassrooms } from './joined-classrooms';
import { styled } from 'naan/stitches.config';
import { TableLayoutWrapper } from 'components/account/account-layout';
// import { AccountLayout } from 'components/layouts/account-layout';
import { Button } from 'naan/primitives/button';
import { SignOutExtraSmallIcon } from 'naan/icons/sign-out-icon';
import { QuickLinks } from 'components/account/quick-links';
import { LargeGap, SectionEnds } from 'components/ds/common/spacers';

import __ from 'core/lib/localization';

const MobileHeaderSpacer = styled('div', {
  height: '24px',
  '@large': {
    height: '0',
  },
});

export const AccountScreen = observer(() => {
  useTitle(__('My account', 'account.myAccount'));
  const store = useRootStore();
  // const accountData = store.userManager?.accountData;
  // const isTrial = accountData.membershipState === 'trial';
  // const [extending, extendingSwitch] = useSwitch(false);

  return (
    <>
      <ContentGrid>
        <PageHeading
          title={__('My account', 'account.myAccount')}
          size="large"
          showAccessoryInSmall
          renderAccessory={() => {
            /// Added so we can logout form a non-teacher account.
            return (
              <Button
                size={'small'}
                presentation={'grayLight'}
                leftIcon={<SignOutExtraSmallIcon />}
                label={__('Sign out', 'common.signOut')}
                onClick={store?.userManager?.logout}
              />
            );
          }}
        />
        <MobileHeaderSpacer />
        <AccountFlash />
        <CheckoutResultTarget />
        <AccountCta />
        <TableLayoutWrapper>
          <JoinedClassrooms />
        </TableLayoutWrapper>
        <TableLayoutWrapper>
          <AccountForm />
        </TableLayoutWrapper>
        <TableLayoutWrapper>
          <MembershipTable />
          {/* // @armando, i'm pretty sure this is supposed to be gone now. tentatively removing */}
          {/* {isTrial ? null : (
            <>
              <CouponButton />
              <VSpacer size="20" />
            </>
          )} */}
        </TableLayoutWrapper>
        {/* <DownloadLinks /> */}
      </ContentGrid>
      <QuickLinks />
      <LargeGap />
      <SectionEnds />
    </>
  );
});
export default AccountScreen;
