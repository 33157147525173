import __ from './localization';

const partSeparator = __(' ', 'common.wordSeparator');

const join = (parts: (string | null)[]) =>
  parts.filter(part => part !== null).join(partSeparator);

export const minutesToPrettyDuration = (durationInMinutes: number): string => {
  const durationInMilliseconds = durationInMinutes * 60 * 1000;
  return millisToPrettyDuration(durationInMilliseconds);
};

export const millisToPrettyDuration = (durationInMillis: number): string => {
  // defend against unexpectedly missing arg data
  if (durationInMillis === undefined || durationInMillis === null) {
    return '';
  }
  const durationInSeconds = Math.floor(durationInMillis / 1000);
  const durationInMinutes = Math.floor(durationInSeconds / 60);
  const hours = Math.floor(durationInMinutes / 60);
  const minutes = Math.floor((durationInMillis / (1000 * 60)) % 60);
  const seconds = Math.floor((durationInMillis / 1000) % 60);

  const hoursDurationString =
    hours > 0 ? __('%{count}hr', 'duration.hours', { count: hours }) : null;

  const minutesDurationString =
    minutes > 0
      ? __('%{count}m', 'duration.minutes', { count: minutes })
      : null;

  const secondsDurationString =
    seconds > 0 && hours === 0 //  never show seconds if we have hours
      ? __('%{count}s', 'duration.seconds', { count: seconds })
      : null;

  return join([
    hoursDurationString,
    minutesDurationString,
    secondsDurationString,
  ]);
};

export const millisToMinutes = (millis: number) =>
  Math.ceil(millis / (60 * 1000));

// export const durationDescription = () => {
//   const chapters = __(
//     { one: '%{count} chapter', other: '%{count} chapters' },
//     'story.nChapters',
//     { count: this.chapters.length }
//   );

//   const duration = minutesToPrettyDuration(this.durationMinutes);

//   return __('%{chapters}, %{duration}', 'story.durationDescription', {
//     chapters,
//     duration,
//   });
// };

// hopefully obsolete version
// export const millisToPrettyDuration = durationInMillis => {
//   const durationInSeconds = Math.floor(durationInMillis / 1000);
//   const durationInMinutes = Math.floor(durationInSeconds / 60);
//   const hours = Math.floor(durationInMinutes / 60);
//   const minutes = Math.floor((durationInMillis / (1000 * 60)) % 60);
//   const seconds = Math.floor((durationInMillis / 1000) % 60);
//
//   const hoursDurationString =
//     hours > 0
//       ? __(
//           { one: '%{count} hr', other: '%{count} hrs' },
//           'duration.hoursPluralized',
//           { count: hours }
//         )
//       : null;
//
//   const minutesDurationString =
//     minutes > 0
//       ? __(
//           { one: '%{count} min', other: '%{count} mins' },
//           'duration.minutesPluralized',
//           { count: minutes }
//         )
//       : null;
//
//   const secondsDurationString =
//     seconds > 0 && hours === 0 //  never show seconds if we have hours
//       ? __('%{seconds}s', 'duration.seconds', {
//           seconds,
//         })
//       : null;
//
//   return join([
//     hoursDurationString,
//     minutesDurationString,
//     secondsDurationString,
//   ]);
// };
