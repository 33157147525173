import {
  Element,
  Word,
  Sentence,
  ElementList,
  SimpleElementList,
  ElementNode,
  ElementTracker,
  EmptyElementList,
  // Player,
  // CreatePlayer,
  Paragraph,
  Passage,
  IDTOf,
} from '@tikka/client/client-aliases';

import { AudioTransport, TransportState } from '@tikka/player/audio-transport';
import { CreateTracker, Tracker } from '@tikka/tracking/tracker';
import { fromIntervals, Interval, Intervals } from '@tikka/intervals/intervals';
import {
  // Navigation,
  CanNavigateResult,
  NavigationPoint,
  TimelineNavigator,
} from '@tikka/navigation/timeline-navigator';
import {
  // NavigationState,
  NAVIGATION_SOFT_PAUSE_MS,
} from '@tikka/player/multi-navigation-mixin';

import { createAudioSource } from './audio-source-factory';

import {
  buildContentElements,
  buildElementNodes,
  extractItalicsRanges,
} from '@tikka/client/client-data';
import {
  createSingletonElement,
  ElementId,
  IDTOfET,
  useTimeIntervals,
  WordId,
  ElementList as BElementList,
} from '@tikka/basic-types';
import { computed, observable, reaction, runInAction, untracked } from 'mobx';
import { RedactionMode } from './redaction-modes';
import { PlayerData } from '@tikka/client/catalog-types';
import { PlayerMode } from '@common/misc-types';
import {
  CreateMembershipList,
  MembershipList,
} from '@tikka/membership-reconciliation/membership-reconciler';
import { createLogger } from 'app/logger';
import { LoadingStatus, PlayerModel, PlayerStatus } from './player-model';
import { Speaker } from '@core/models/catalog/speaker';
import { ClientPlayer, CreateClientPlayer } from './client-player';
import { getConfig } from 'app/env';
import { track } from 'app/track';
import { getKindFromId } from '@tikka/elements/element-id-utils';
import { CreateElementList } from '@tikka/elements/element-list';

const log = createLogger('player-model');

export const infinityTime = 1000 * 60 * 60 * 30;

const SENTENCE_PLAYTHROUGH_PAUSE_REWIND_TOLERANCE_MS = 1000;

export const enum PlayerType {
  STUDY = 'STUDY',
  SOUNDBITE = 'SOUNDBITE',
}

export const enum Milestone {
  INTRO = 'INTRO', // intro audio
  SPOKEN = 'SPOKEN', // start of spoken audio
  COMPLETE = 'COMPLETE', // chapter complete marker if set, otherwise end of spoken audio
  OUTRO = 'OUTRO', // end of spoken audio
  END = 'END', // end of audio as determined during ingestion. used to stop player
  INFINITY = 'INFINITY', // needed by the interval interface
}

export enum TranslationButtonState {
  hidden,
  disabled,
  enabled,
}

export abstract class BasePlayerModel implements PlayerModel {
  abstract readonly playerType: string;

  @observable.ref data: PlayerData;

  // the main tree model used by the view
  @observable.ref elementNodes: ElementNode[];

  // the heterogenious list of all content elements
  @observable.ref elements: ElementList<Element> = EmptyElementList;

  @observable.ref words: ElementList<Word> = EmptyElementList;
  outroElement = createSingletonElement('OUTRO', { time: 0, endTime: 0 }); // todo: real times here would be nice
  @observable.ref sentences: ElementList<Sentence> = EmptyElementList;
  @observable.ref lines: BElementList<Sentence | typeof this.outroElement> =
    EmptyElementList as BElementList<any>;
  @observable.ref speakerLabels: ElementList<Paragraph> = EmptyElementList;
  @observable.ref passages: ElementList<Passage> = EmptyElementList;

  @observable.ref player: ClientPlayer;
  @observable.ref transportState: TransportState;

  @observable.ref wordTracker: ElementTracker<Word> = null;
  @observable.ref navStopTracker: ElementTracker<Word> = null;
  @observable.ref lineTracker: Tracker<
    IDTOfET<typeof this.lines.values[number]>,
    typeof this.lines.values[number]
  > = null;
  @observable.ref speakerLabelTracker: ElementTracker<Paragraph> = null;
  @observable.ref passageTracker: ElementTracker<Passage> = null;

  // @observable.ref chapterTracker: Tracker<number, null> = null;
  @observable.ref milestoneTracker: Tracker<string, null> = null;

  milestoneTuples: [Milestone, number][] = [];

  navStopNavigator: TimelineNavigator = null;
  navLineNavigator: TimelineNavigator = null;
  // @observable.ref canNavigateForward = false;
  // @observable.ref canNavigateBack = false;
  @observable.ref navStopCanNavigate: CanNavigateResult = 0;
  @observable.ref sentenceCanNavigate: CanNavigateResult = 0;
  @observable.ref pauseAfterSentenceId: IDTOf<Sentence> = null;

  @observable.ref _redactionMode: RedactionMode = RedactionMode.SHOW_SOME;
  @observable.ref playerMode: PlayerMode = PlayerMode.STUDY;

  // used for one-time welcome for soundbite player, and chapter notes for study player
  @observable.ref onloadModalNeeded: boolean = false;

  // ui toggled mode to show/hide translation panel
  @observable.ref _translationsShown = false;

  // ui toggled mode to enable wordgroup underlines
  @observable.ref debugMode = false;

  @observable.ref trickyMembershipList: MembershipList = null;
  @observable.ref notationsMembershipList: MembershipList = null;

  @observable.ref sicStarts: Set<WordId> = null;
  @observable.ref sicIntended: Map<WordId, string> = null;
  @observable.ref sicMembershipList: MembershipList = null;
  @observable.ref italicsMembershipList: MembershipList = null;
  @observable.ref navStopMembershipList: MembershipList = null;

  // @observable.ref hasBeenPlayed = false; // true once any audio has been played
  @observable.ref completionReached = false; // true if end of meaningful content reached during this session

  // hack state to only track a single play event per session
  // @jason, what's a better approach here?
  playTracked = false;

  // set to true for soundbite until the script actions are scrolled into view
  @observable.ref forceDisablePlayback = false;

  @observable.ref restoreSpeed = 0;
  @observable.ref sentenceRedactionOverride: Map<ElementId, boolean> = null;
  lastSentenceEndTime = 0;
  beganPlayAtSentenceId: ElementId = null;

  notionallyCompleteTime: number;
  audioDurationMillis: number;

  @observable.ref loadingStatus: string = LoadingStatus.UNINITIALIZED;

  @observable.ref helpOverlayShown: boolean = false;
  @observable.ref debugOverlayShown: boolean = false;

  disposers: (() => void)[] = [];

  // beware, makeObservable must be called from the subclass
  // constructor() {
  //   makeObservable(this);
  // }

  dispose() {
    for (const disposer of this.disposers) {
      disposer();
    }
    this.disposers = [];
  }

  async initFromPlayerData(data: PlayerData) {
    log.info('initFromData');

    if (this.player) {
      // @jason any more player state to clean up when we reset?
      if (this.player?.transportState?.isPlaying) {
        this.player.pause();
      }
    }

    // reset base model state
    // this.hasBeenPlayed = false;
    this.completionReached = false;
    this.playTracked = false;
    this.debugMode = getConfig('player.debug', false);

    this.data = data;

    const transportState = new TransportState();
    // const navigationState = new NavigationState();
    this.transportState = transportState;

    const audioSource = createAudioSource();
    audioSource.setAudioSourceDefinitions(data.slug, {
      audioUrl: data.audioUrl,
    });

    const audioTransport = new AudioTransport(transportState);
    audioTransport.setAudioSource(audioSource);

    // const navigation = new Navigation();
    const player = CreateClientPlayer(audioTransport, transportState);

    this.player = player;

    const elements = buildContentElements(data); // TODO
    this.elements = elements;
    const words = this.elements.words;
    this.words = words;
    this.elementNodes = buildElementNodes(elements);

    const wordTracker = CreateTracker({
      elements: words,
      positionFunction: () => transportState.audioPosition,
      triggerFunction: () => transportState.audioPosition,
      intervals: useTimeIntervals,
    });

    this.disposers.push(() => wordTracker.dispose());
    this.wordTracker = wordTracker;

    const sentences = this.elements.filterByKind('SENTENCE');
    this.sentences = sentences;

    const sentenceSpanTimePoints = sentences.timeIntervals.startPoints;

    const sentenceEndTimes = sentences.timeIntervals.endPoints;
    const startSpokenTime = sentenceSpanTimePoints[0];
    const endSpokenTime = sentenceEndTimes[sentenceEndTimes.length - 1];
    this.lastSentenceEndTime = endSpokenTime;
    this.notionallyCompleteTime = this.resolveEndOfMaterialMillis();
    const navLineTimePoints = sentenceSpanTimePoints.slice();
    sentenceSpanTimePoints[0] = 0; // @jason, do you recall the implications of this?

    const paragraphs = elements.filterByKind('PARAGRAPH');
    const speakerLabels = paragraphs.filter(
      paragraph => !!paragraph.speakerLabel
    );
    this.speakerLabels = speakerLabels;
    this.speakerLabelTracker = CreateTracker({
      elements: speakerLabels,
      positionFunction: () => transportState.audioPosition,
      triggerFunction: () => wordTracker.anyIsChangedSignal.watch(),
      intervals: () => speakerLabels.timeIntervals.fromStartPoints(),
    });

    this.passages = this.elements.filterByKind('PASSAGE');
    this.passageTracker = CreateTracker({
      elements: this.passages,
      positionFunction: () => transportState.audioPosition,
      triggerFunction: () => wordTracker.anyIsChangedSignal.watch(),
      intervals: () => this.passages.timeIntervals.fromStartPoints(),
    });

    // +5 to make sure our milestones don't overlap
    const finalLineTime = endSpokenTime + 5;

    const wordIntervals = words.timeIntervals;
    let navStopWordIndexes = data.navStopWordIndexes;
    let navStopTimePoints = wordIntervals
      .translateStartPointsToValues(navStopWordIndexes)
      .map((t: number) => t + 1);
    navStopTimePoints.push(finalLineTime);
    // console.log('finalLineTime: ', finalLineTime);

    navStopWordIndexes = navStopWordIndexes
      .map((idx: number) => idx - 1)
      .filter((idx: number) => idx >= 0);
    const navGaps = navStopWordIndexes.map((idx: number) =>
      wordIntervals.getPriorGapInterval(idx + 1)
    );
    const navGapIntervals = fromIntervals(navGaps);

    const navStopWords = navStopWordIndexes.map(
      (idx: number) => words.values[idx]
    );
    const navStopWordElementList = SimpleElementList(navStopWords);

    const navStopTracker = CreateTracker({
      elements: navStopWordElementList,
      positionFunction: () => transportState.audioPosition,
      triggerFunction: () => transportState.audioPosition,
      intervals: () => navGapIntervals,
    });

    this.navStopTracker = navStopTracker;
    this.sentenceRedactionOverride = observable.map({});

    const navStopNavigator = new TimelineNavigator();
    this.navStopNavigator = navStopNavigator;
    navStopNavigator.setIntervals(new Intervals(navStopTimePoints));
    // navigation.addNavigator(navigator);
    // navigationState.navigationPoint = navigator.navigationPoint(0);
    reaction(
      () => transportState.pauseAfterTriggeredCount,
      () => this.handlePauseAfterTrigger()
    );

    // +5 to make sure our milestones don't overlap
    this.audioDurationMillis =
      Math.max(this.data.durationMillis || 0, finalLineTime) + 5; // lops off outro if durationMillis missing (stale ingestions)

    this.milestoneTuples = [
      // @jason, can we use our milestone tracker to capture the transition from
      // the "never played" state?
      // [Milestone.NEVER_PLAYED, -1],
      [Milestone.INTRO, 0],
      [Milestone.SPOKEN, startSpokenTime],
      [Milestone.COMPLETE, this.notionallyCompleteTime],
      [Milestone.OUTRO, finalLineTime],
      [Milestone.END, this.audioDurationMillis],
      [Milestone.INFINITY, infinityTime],
    ] as [Milestone, number][];

    // this.outroElement.time = endSpokenTime;
    // this.outroElement.endTime = this.audioDurationMillis;

    const lineElements: typeof this.lines.values =
      this.sentences.values.slice();
    lineElements.push(this.outroElement);
    this.lines = CreateElementList<typeof lineElements[number], typeof words>({
      elements: lineElements,
      words,
    });

    const lineSpanTimePoints = sentenceSpanTimePoints.slice();
    lineSpanTimePoints.push(finalLineTime);
    lineSpanTimePoints.push(infinityTime);
    const lineSpanIntervals = new Intervals(lineSpanTimePoints, null);

    const lineTracker = CreateTracker({
      elements: this.lines,
      positionFunction: () => transportState.audioPosition,
      triggerFunction: () => wordTracker.anyIsChangedSignal.watch(),
      intervals: () => lineSpanIntervals,
    });

    this.lineTracker = lineTracker;

    const milestoneTimes = this.milestoneTuples.map(tup => tup[1]);
    const milestoneIntervals = new Intervals(milestoneTimes, null);

    this.milestoneTracker = CreateTracker<string>({
      elements: this.milestoneTuples.map(tup => tup[0]),
      positionFunction: () => transportState.audioPosition,
      triggerFunction: () => transportState.audioPosition,
      intervals: milestoneIntervals,
    });

    // const milestonesMap = new Map(this.milestoneTuples);

    const navLineTracker = new TimelineNavigator();
    this.navLineNavigator = navLineTracker;
    navLineTimePoints.push(finalLineTime);
    navLineTracker.setIntervals(new Intervals(navLineTimePoints));

    const trickys = this.elements.filterByKind('TRICKY');
    this.trickyMembershipList = CreateMembershipList({
      memberships: ['TRICKY'],
      elements: trickys,
      useRanges: true,
    });

    // notation membership needed by base player to properly unredact vocab
    const notations = this.elements.filterByKind('NOTATION');
    this.notationsMembershipList = CreateMembershipList({
      memberships: ['NOTATION'],
      elements: notations,
      useRanges: true,
    });

    const sics = this.elements.filterByKind('SIC');
    const sicStarts = new Set<WordId>();
    const sicIntended = new Map<WordId, string>();

    for (const sic of sics.values) {
      const beginId = sic.address.toString();
      const endId = sic.endAddress.toString();
      const intended = sic.intended || '?';

      sicStarts.add(beginId as WordId);
      sicIntended.set(endId as WordId, intended);
    }

    this.sicStarts = sicStarts;
    this.sicIntended = sicIntended;
    this.sicMembershipList = CreateMembershipList({
      memberships: ['SIC'],
      elements: sics,
      useRanges: true,
    });

    this.italicsMembershipList = CreateMembershipList({
      memberships: ['ITALIC'],
      elements: extractItalicsRanges(words),
      useRanges: true,
    });

    this.navStopMembershipList = CreateMembershipList({
      memberships: ['NAV_STOP'],
      elements: navStopWords,
    });

    reaction(
      () => wordTracker.anyIsChangedSignal.watch(),
      () => this.calcCanNavigate(),
      { fireImmediately: true } // needed to fix initial transport bar button enable/disable states
    );

    reaction(
      () => this.milestoneStatus,
      () => this.handleChangeMilestoneStatus(),
      // not entire sure if fireImmediatly needed, but seems prudent to future proof evolving business logic
      { fireImmediately: true }
    );

    // // drives the "tap play to listen" state of the answer panel of the soundbite player
    // reaction(
    //   () => this.playerStatus === PlayerStatus.PLAYING,
    //   () => {
    //     if (!this.hasBeenPlayed && this.playerStatus === PlayerStatus.PLAYING) {
    //       this.hasBeenPlayed = true;
    //     }
    //   }
    // );

    reaction(
      () => this.inSnailReplayMode,
      () => this.handleSnailReplayRestore()
    );

    // reaction(
    //   () => this.currentLineId,
    //   () =>
    //     log.debug(
    //       `reaction - currentLineId: ${this.currentLineId}, currentMillis: ${this.currentMillis}`
    //     )
    // );

    // more state to reset?
    this.completionReached = false;
  }

  // overridden in StudyModel to honor CHAPTER_COMPLETE element
  resolveEndOfMaterialMillis(): number {
    const result = Math.floor(this.lastSentenceEndTime);
    return result;
  }

  get ready() {
    return this.loadingStatus === LoadingStatus.READY;
  }

  setReady() {
    log.info('application state now ready()');
    this.loadingStatus = LoadingStatus.READY;
  }

  setStatus(status: LoadingStatus): void {
    this.loadingStatus = status;
  }

  get currentNavStopMembershipList() {
    const wordId = this.navStopTracker.observableIsUnder();
    const words = this.elements.words;
    const currentNavStopWord = words.getElement(wordId);
    let elements = currentNavStopWord ? [currentNavStopWord] : [];
    return CreateMembershipList({
      memberships: ['CURRENT_NAV_STOP'],
      elements,
    });
  }

  @computed
  get wordMembershipLists() {
    let result: Map<string, MembershipList> = new Map();
    result.set('trickyWord', this.trickyMembershipList);
    result.set('notationWord', this.notationsMembershipList);
    result.set('sicWord', this.sicMembershipList);
    result.set('italicWord', this.italicsMembershipList);
    result.set('navStopWord', this.navStopMembershipList);
    result.set('currentNavStopWord', this.currentNavStopMembershipList);
    return result;
  }

  get currentLineMembershipList() {
    const currentLineElement = this.currentLineElement;
    let elements = currentLineElement ? [currentLineElement] : [];
    return CreateMembershipList({ memberships: ['current'], elements });
  }

  get visitedLineMembershipList() {
    // jason needs to fix the range tracker
    // const visitedRange = this.lineTracker.observableVisitedRange();
    // return CreateMembershipList({
    //   memberships: ['visited'],
    //   elements: this.lines,
    //   range: visitedRange,
    // });

    // 2518: hardwiring to all lines always visited for soundbite m1
    const elements = this.lines.values;
    return CreateMembershipList({ memberships: ['visited'], elements });
  }

  get exposeUnredactSentenceMembershipList() {
    const exposeUnredactSentenceElement = this.lines.getElement(
      this.exposeUnredactSentenceId
    );
    let elements = exposeUnredactSentenceElement
      ? [exposeUnredactSentenceElement]
      : [];
    return CreateMembershipList({
      memberships: ['expose-unredact-sentence'],
      elements,
    });
  }

  get pausingAfterSentenceMembershipList() {
    const pauseAfterSentenceElement = this.pausingAfterSentenceElement;
    let elements = pauseAfterSentenceElement ? [pauseAfterSentenceElement] : [];
    return CreateMembershipList({
      memberships: ['pausing-after'],
      elements,
    });
  }

  handlePauseAfterTrigger() {
    this.pauseAfterSentenceId = null;
  }

  @computed
  get lineMembershipLists() {
    let result: Map<string, MembershipList> = new Map();
    result.set('currentLine', this.currentLineMembershipList);
    result.set('visitedLine', this.visitedLineMembershipList);
    result.set(
      'exportUnredactSentence',
      this.exposeUnredactSentenceMembershipList
    );
    // result.set('beforeTest', this.beforeSentenceMembershipList);
    result.set('pausingAfterSentence', this.pausingAfterSentenceMembershipList);
    return result;
  }

  @computed
  get speakerMembershipLists() {
    let result: Map<string, MembershipList> = new Map();
    result.set('current', this.currentSpeakerMembershipList);
    result.set('visited', this.visitedSpeakerMembershipList);
    return result;
  }

  get currentSpeakerMembershipList() {
    const currentSpeakerElement = this.currentSpeakerLabelElement;
    let elements = currentSpeakerElement ? [currentSpeakerElement] : [];
    return CreateMembershipList({ memberships: ['current'], elements });
  }

  get currentSpeakerLabelElement(): Paragraph {
    const id = this.speakerLabelTracker.observableIsUnder();
    return this.speakerLabels.getElement(id);
  }

  get visitedSpeakerMembershipList() {
    const visitedRange = this.speakerLabelTracker.observableVisitedRange();
    return CreateMembershipList({
      memberships: ['visited'],
      elements: this.speakerLabels,
      range: visitedRange,
    });
  }

  @computed
  get passageMembershipLists() {
    let result: Map<string, MembershipList> = new Map();
    result.set('visited', this.visitedPassageMembershipList);
    return result;
  }

  get visitedPassageMembershipList() {
    const visitedRange = this.passageTracker.observableVisitedRange();
    return CreateMembershipList({
      memberships: ['visited'],
      elements: this.passages,
      range: visitedRange,
    });
  }

  handleChangeMilestoneStatus() {
    if (this.afterNotionalCompletion) {
      if (!this.completionReached) {
        this.track('end_of_audio_reached');
      }
      this.completionReached = true;

      this.wordTracker.forceFurthestVisitedTime(this.lastSentenceEndTime);
    }

    if (this.atAudioEnd) {
      this.pause();
    }
  }

  // true when the current position is past the chapter complete marker
  @computed
  get afterNotionalCompletion(): boolean {
    return [Milestone.COMPLETE, Milestone.OUTRO, Milestone.END].includes(
      this.milestoneStatus
    );
  }

  // true when the current position is past the last sentence and the end-of-chapter card should be focused
  get afterSpoken(): boolean {
    return [Milestone.OUTRO, Milestone.END].includes(this.milestoneStatus);
  }

  // not currently used
  get beforeSpoken(): boolean {
    return this.milestoneStatus === Milestone.INTRO;
  }

  get atAudioEnd(): boolean {
    return this.milestoneStatus === Milestone.END;
  }

  get milestoneStatus(): Milestone {
    return this.milestoneTracker?.observableIsUnder() as Milestone;
  }

  // @jason, is this a trustworth approach?
  // true if any audio has been played
  get hasBeenPlayed(): boolean {
    return this.milestoneStatus !== undefined;
  }

  calcCanNavigate() {
    const transportState = this.transportState;
    const pos = transportState.audioPosition;
    const furthestPos = this.wordTracker.furthestTrackedPosition();
    runInAction(() => {
      this.navStopCanNavigate = this.navStopNavigator.queryCanNavigate(
        pos,
        0,
        furthestPos
      );
      this.sentenceCanNavigate = this.navLineNavigator.queryCanNavigate(
        pos,
        0,
        furthestPos
      );
    });
  }

  // @jason: note, there's currently some odd interplay between sentence level and navstop
  // level navigation, where after going back one sentence, the next navstop forward
  // navigation can leave you in the same appaarent place

  get currentMillis(): number {
    return this.transportState.audioPosition;
  }

  get furthestMillis(): number {
    return this.wordTracker.furthestTrackedPosition();
  }

  @computed
  get currentPercentage(): number {
    return (
      // todo: confirm what refresh granularity we want
      Math.round((1000 * this.currentMillis) / this.lastSentenceEndTime) / 10
      // Math.round((100 * this.currentMillis) / this.lastSentenceEndTime)
    );
  }

  @computed
  get furthestPercentage(): number {
    return (
      Math.round((1000 * this.furthestMillis) / this.lastSentenceEndTime) / 10
    );
    // return Math.round((100 * this.furthestMillis) / this.lastSentenceEndTime);
  }

  get playerStatus(): PlayerStatus {
    const transportState = this.transportState;
    if (transportState.isPlaying) {
      if (transportState.pendingPause) {
        return PlayerStatus.PENDING_PAUSE;
      } else {
        return PlayerStatus.PLAYING;
      }
    } else {
      return PlayerStatus.PAUSED;
    }
  }

  get pauseDurationInMs() {
    if (!this.transportState.pendingPause) {
      return 0;
    }
    let currentPlayPosition = 0;
    // need to use untracked or player controls will rerender constantly
    untracked(() => {
      currentPlayPosition = this.transportState.audioPosition;
    });
    return this.player.audioTransport.pauseAfter - currentPlayPosition;
  }

  @computed
  get inSnailReplayMode(): boolean {
    return (
      this.playerStatus === PlayerStatus.PENDING_PAUSE && this.restoreSpeed > 0
    );
  }

  handleSnailReplayRestore(force?: boolean) {
    if ((force || !this.inSnailReplayMode) && this.restoreSpeed) {
      this.player.setPlaybackRate(this.restoreSpeed);
      this.restoreSpeed = 0;
    }
  }

  snailReplayCurrentSentence() {
    this.restoreSpeed = this.transportState.playbackRate;
    this.player.adjustPlaybackRate(-2);
    this.replayCurrentSentence();
  }

  pause() {
    this.clearPauseAfter();
    this.player.pause(true /*keepPauseAfter*/);
  }

  play() {
    if (this.playActionDisabled) {
      log.debug(`play action ignored`);
      return; // ignore keyboard control
    }
    log.trace(`play - currentMillis`, this.currentMillis);

    if (!this.playTracked) {
      this.playTracked = true;
      this.track('player_started');
    }

    // only relevant for soundbite player
    // restart at beginning if paused at the end
    if (this.atAudioEnd) {
      this.player.seek(
        0,
        true /* keepPauseAfter - must be true for non-chaat code */
      );
    }

    this.beganPlayAtSentenceId = this.currentSentenceId;
    this.player.play(/*keepPauseAfter = false*/); // @jason is this safe?
  }

  simplePlayPauseAction() {
    if (this.playerStatus === PlayerStatus.PLAYING) {
      this.pause();
    } else {
      this.play();
    }
  }

  complexPlayPauseAction() {
    switch (this.playerStatus) {
      case PlayerStatus.PLAYING:
        this.pauseAfterCurrentSentence();
        break;
      case PlayerStatus.PENDING_PAUSE:
        this.pause();
        break;
      default:
        this.play();
        break;
    }
  }

  abstract get complexPlayActionEnabled(): boolean;

  // never shown for soundbite player
  get backToFurthestUI(): boolean {
    return true;
  }

  get progressBarUI(): boolean {
    // // conditionally disable for testing
    // if (this.debugMode) {
    //   return false;
    // }
    return true;
  }

  abstract get playActionDisabled(): boolean;

  abstract playPauseAction(): void;

  clearPauseAfter() {
    runInAction(() => {
      this.player.audioTransport.clearPauseAfter();
      this.pauseAfterSentenceId = null;
    });
  }

  pauseAfterCurrentSentence() {
    const currentSentenceId = this.currentSentenceId;
    if (!currentSentenceId) {
      this.pause();
      return;
    }
    // log.debug(
    //   `pauseAfterCurrentSentence: curId: ${currentSentenceId}, begPlayId: ${this.beganPlayAtSentenceId}`
    // );
    if (this.beganPlayAtSentenceId !== currentSentenceId) {
      const sentenceInterval = this.currentSentenceTimeInterval;
      if (sentenceInterval) {
        // handle pause at start of sentence case
        const audioPosition = this.transportState.audioPosition;
        if (
          audioPosition <
          sentenceInterval.begin +
            SENTENCE_PLAYTHROUGH_PAUSE_REWIND_TOLERANCE_MS
        ) {
          const previousSentenceId = this.lines.prevId(currentSentenceId);
          if (previousSentenceId) {
            const previousSentence = this.lines.getElement(previousSentenceId);
            // @jason, 'keepPauseAfter' defaults to false for these. is this safe?
            this.player.seek(
              previousSentence.endTime /* - 3, no longer desireable to hack endTime */
            );
            this.player.pause();
            return;
          }
          // fall through handling to normal pause after behavior for first sentence of chapter
        }
      } else {
        log.warn(`pause request with missing currentSentenceTimeInterval`);
        // not sure if this can ever happen, but let pass through to original logic
      }
    }

    const sentence = this.lines.getElement(currentSentenceId);
    runInAction(() => {
      this.pauseAfterSentenceId = currentSentenceId;
      const pauseAfterTime = sentence.endTime; /* + 2*/
      log.debug(`setPauseAfter: ${pauseAfterTime}`);
      this.player.audioTransport.setPauseAfter(pauseAfterTime);
    });
  }

  cancelPendingPause() {
    this.clearPauseAfter();
  }

  replayCurrentSentence() {
    const currentSentenceId = this.currentSentenceId;
    if (!currentSentenceId) {
      return;
    }
    this.seekSentenceStartTime(this.currentSentenceId);
    this.pauseAfterCurrentSentence();
    this.play();
  }

  rewind() {
    if (this.canSeekPreviousNavStop) {
      this.player.prevClosest(this.navStopNavigator, true /*keepPauseAfter*/);
      log.trace('rewind - currentMillis', this.currentMillis);
    }
  }

  forward() {
    if (this.canSeekNextNavStop) {
      this.player.nextClosest(this.navStopNavigator, true /*keepPauseAfter*/);
      log.trace('forward - currentMillis', this.currentMillis);
    }
  }

  get canSeekNextNavStop(): boolean {
    return !!(this.navStopCanNavigate & CanNavigateResult.CAN_NAVIGATE_FORWARD);
  }

  get canSeekPreviousNavStop(): boolean {
    return !!(this.navStopCanNavigate & CanNavigateResult.CAN_NAVIGATE_BACK);
  }

  // debugger convenience
  get nextStopPoint(): NavigationPoint {
    return this.navStopNavigator.nextClosest(this.transportState.audioPosition);
  }

  get prevStopPoint(): NavigationPoint {
    return this.navStopNavigator.prevClosest(this.transportState.audioPosition);
  }

  get nextLinePoint(): NavigationPoint {
    return this.navLineNavigator.nextClosest(this.transportState.audioPosition);
  }

  get prevLinePoint(): NavigationPoint {
    return this.navLineNavigator.prevClosest(this.transportState.audioPosition);
  }

  get currentLineId(): ElementId {
    const lineId = this.lineTracker.observableIsUnder();
    return lineId;
  }

  get currentSentenceId(): IDTOf<Sentence> {
    // @jason todo: clean this up
    if (this.afterSpoken) {
      return null;
    }
    const lineId = this.currentLineId;
    if (lineId && getKindFromId(lineId) === 'SENTENCE') {
      return lineId as any;
    }
    return null;
  }

  // @jason, please confirm this is the best way to get this,
  // note, it's only needed the confirm behavior when clicking on a sentence, so
  // it doesn't need to be particularly optimized
  get furthestSentenceId(): ElementId {
    return this.sentences.getElementContainingTime(this.furthestMillis)?.id;
  }

  sentenceIsBeyondFurthest(candidateId: ElementId): boolean {
    const sentence = this.lines.getElement(candidateId);
    return !sentence || sentence.time > this.furthestMillis;
  }

  seekNextLine() {
    if (this.canSeekNextLine) {
      this.player.nextClosest(this.navLineNavigator);
    }
  }

  seekPreviousLine() {
    if (this.canSeekPreviousLine) {
      this.player.prevClosest(this.navLineNavigator);
    }
  }

  get canSeekNextLine(): boolean {
    return !!(
      this.sentenceCanNavigate & CanNavigateResult.CAN_NAVIGATE_FORWARD
    );
  }

  get canSeekPreviousLine(): boolean {
    return !!(this.sentenceCanNavigate & CanNavigateResult.CAN_NAVIGATE_BACK);
  }

  seekToFurthest() {
    this.player.seek(this.furthestMillis); // @jason, keepPauseAfter defaults to false here. is this safe?
  }

  get canSeekToFurthest(): boolean {
    // i believe this matches the expected behavior
    return this.canSeekNextLine;
  }

  @computed
  get pausingAfterSentenceElement(): Sentence {
    if (this.transportState.pendingPause) {
      return this.lines.getElement(this.pauseAfterSentenceId);
    }
    return null;
  }

  get currentSentenceElement(): Sentence {
    const currentSentenceId = this.currentSentenceId;
    return this.lines.getElement(currentSentenceId);
  }

  get currentLineElement(): Element {
    const currentLineId = this.currentLineId;
    return this.lines.getElement(currentLineId) as any;
  }

  get currentSentenceTimeInterval(): Interval {
    const sentence = this.currentSentenceElement;
    if (!sentence) {
      return null;
    }
    return { begin: sentence.time, end: sentence.endTime };
  }

  get exposeUnredactSentenceId(): ElementId {
    // if (
    //   this.playerMode !== PlayerMode.STUDY ||
    //   this.redactionMode === RedactionMode.SHOW_ALL ||
    //   this.transportState.isPlaying
    // ) {
    //   return null;
    // }
    // const currentSentenceId = this.currentSentenceId;
    // if (this.sentenceRedactionOverride.get(currentSentenceId)) {
    //   return null;
    // }
    // return currentSentenceId;

    // experimental ENG-2464
    return null;
  }

  // todo: refactor the sentence stuff to use a navigator
  seekSentenceStartTime(id: ElementId) {
    if (!id) {
      return;
    }
    const sentence = this.lines.getElement(id);
    if (this.player.transportState.isPlaying) {
      this.player.pauseThenPlayAt(
        NAVIGATION_SOFT_PAUSE_MS,
        sentence.time,
        true /* keepPauseAfter */
      );
    } else {
      this.player.seek(
        sentence.time,
        true /* keepPauseAfter - must be true for non-chaat code */
      );
    }
  }

  sentenceSelect(id: ElementId) {
    // experimental ENG-2464
    if (this.currentSentenceId === id) {
      this.toggleSentenceRedaction(id);
      return;
    }
    if (this.furthestSentenceId === id) {
      this.seekToFurthest();
    } else {
      this.seekSentenceStartTime(id);
    }
    // JE: tentatively removing the automatic pause. i think that's preferable and makes it easier to share
    // code with the button/key setence navigation handlers
    // this.player.pause(true);
  }

  get redactionMode() {
    return this._redactionMode;
  }

  getSentenceRedactionMode(sentenceId: ElementId) {
    if (sentenceId && this.sentenceRedactionOverride.get(sentenceId)) {
      return RedactionMode.SHOW_ALL;
    }
    return this.redactionMode;
  }

  unredactSentenceId(sentenceId: ElementId) {
    this.sentenceRedactionOverride.set(sentenceId, true);
  }

  toggleSentenceRedaction(sentenceId: ElementId) {
    const newRedaction = !this.sentenceRedactionOverride.get(sentenceId);
    this.sentenceRedactionOverride.set(sentenceId, newRedaction);
  }

  toggleCurrentSentenceRedaction() {
    const currentSentenceId = this.currentSentenceId;
    if (!currentSentenceId) {
      return;
    }
    this.toggleSentenceRedaction(currentSentenceId);
  }

  resetRedactionOverrides() {
    this.sentenceRedactionOverride.clear();
  }

  // unredactCurrentSentence() {
  //   const currentSentenceId = this.currentSentenceId;
  //   if (!currentSentenceId) {
  //     return;
  //   }
  //   this.unredactSentenceId(this.currentSentenceId);
  // }

  setRedactionMode(mode: RedactionMode) {
    this._redactionMode = mode;
    this.resetRedactionOverrides();
  }

  toggleRedactionMode() {
    if (this._redactionMode === RedactionMode.SHOW_ALL) {
      this.setRedactionMode(RedactionMode.SHOW_SOME);
    } else if (this._redactionMode === RedactionMode.SHOW_SOME) {
      this.setRedactionMode(RedactionMode.SHOW_NONE);
    } else if (this._redactionMode === RedactionMode.SHOW_NONE) {
      this.setRedactionMode(RedactionMode.SHOW_ALL);
    }
  }

  setOnloadModalNeeded(value: boolean) {
    this.onloadModalNeeded = value;
  }

  get translationsShown() {
    return this._translationsShown;
  }

  setTranslationsShown(value: boolean) {
    this._translationsShown = value;
  }

  toggleTranslations() {
    if (this.translationButtonState === TranslationButtonState.enabled) {
      this._translationsShown = !this._translationsShown;
    }
  }

  abstract get translationButtonState(): TranslationButtonState;

  // controls if clicking on an unvisited sentence should present confirmation prompt
  // or simply be ignored (soundbite)
  abstract get skipForwardAllowed(): boolean;

  toggleDebugMode() {
    this.debugMode = !this.debugMode;
  }

  neverPlayed() {
    return this.wordTracker.furthestTrackedPosition() === 0;
  }

  toggleHelpOverlay() {
    this.helpOverlayShown = !this.helpOverlayShown;
  }

  setHelpOverlayShown(value: boolean) {
    this.helpOverlayShown = value;
  }

  track(subeventName: string, data?: any) {
    track(`${this.metricsPrefix}__${subeventName}`, data);
  }

  abstract get metricsPrefix(): string;

  // debug/internal review feature to reset furthest listened
  // so that karaoke behavior can be repeatedly observed
  abstract resetSession(): void;

  abstract resolveSpeaker(label: string): Speaker;

  // debug reference
  abstract dataSourceUrl: string;

  //
  // debug stuff
  //

  debugReset() {
    if (this.debugMode) {
      this.resetSession();
    }
  }

  toggleDebugOverlay() {
    if (this.debugMode) {
      this.debugOverlayShown = !this.debugOverlayShown;
    }
  }

  debugToggleOnloadModal() {
    this.setOnloadModalNeeded(!this.onloadModalNeeded);
  }

  debugSeekToEnd() {
    if (this.debugMode) {
      const firstStop = this.notionallyCompleteTime - 2000;
      if (this.currentMillis < firstStop) {
        this.player.seek(firstStop);
      } else {
        this.player.seek(this.lastSentenceEndTime - 2000);
      }
    }
  }

  debugNavBack25() {
    if (this.debugMode) {
      this.player.seek(this.currentMillis - 25);
    }
  }

  debugNavBack1() {
    if (this.debugMode) {
      this.player.seek(this.currentMillis - 1);
    }
  }

  debugNavForward1() {
    if (this.debugMode) {
      this.player.seek(this.currentMillis + 1);
    }
  }

  debugNavForward25() {
    if (this.debugMode) {
      this.player.seek(this.currentMillis + 25);
    }
  }
}
