import * as React from 'react';
import { Passage } from '@tikka/client/client-aliases';
import { styled } from '@naan/stitches.config';
import { paletteKey } from '../study-palette';

const Wrapper = styled('div', {
  textStyle: 'small-heading',
  borderTop: '1px solid $colors$black-alpha-06',
  color: paletteKey('$$passageHint__color_unvisited'),
  margin: '$space$8 $space$4 $space$4',
  paddingTop: '$space$2',

  transition: 'transform .3s',
  '&.visited': {
    color: paletteKey('$$passageHint__color_visited'),
  },
  '.showing-translation &': {
    '@playerMedium': {
      transform: 'translateX(-196px)',
    },
  },
});

export const PassageHintPresentation = ({
  element,
  ...props
}: { element: Passage } & React.ComponentProps<typeof Wrapper>) => {
  const passageElement = element;

  return <Wrapper {...props}>{passageElement.hint?.l2}</Wrapper>;
};
