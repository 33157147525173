import * as React from 'react';
import { observer } from 'mobx-react';
import { useNavigate } from 'react-router-dom';
import { ChevronDownIcon } from 'naan/icons/chevron-down-icon';
import { AppFactory } from 'app/app-factory';
import { updateProgress } from 'routes/learn/study-screen';
import { IconButton } from '@naan/primitives/button';

export const CloseControl = observer(() => {
  const storyPath = `./../..`;

  const navigate = useNavigate();
  const onClose = () => {
    // log.debug(`onClose`);
    const model = AppFactory.studyModel;
    model.player.pause();
    updateProgress();
    // navigate(`/app/learn/es/stories/${unitData.storySlug}`, { replace: true });
    navigate(storyPath);
  };

  // TODO: update progress before navigating away
  return (
    <IconButton
      onClick={onClose}
      icon={<ChevronDownIcon />}
      presentation="whiteTransparent"
    />
  );
});
