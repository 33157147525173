import { LinkProps } from 'react-router-dom';

import * as React from 'react';
import { styled } from 'naan/stitches.config';
import { LupaLogo, MyLupaLogo } from 'components/ui/lupa-logo';
import {
  Link as RouterLink,
  useMatch,
  useResolvedPath,
} from 'react-router-dom';
import { AvatarIcon } from 'naan/icons/avatar-icon';
import { GiftIcon } from 'naan/icons/gift-icon';
import { AppFactory } from 'app/app-factory';
import { GlobalMessage } from 'naan/global-messages';
import { observer } from 'mobx-react';
import { GlobalFooter } from 'components/global-footer';
import { ReturnNavState } from 'components/nav/return-nav-state';
import { Responsive } from 'naan/primitives/responsive';
import { ArrowLeftIcon, ArrowLeftSmallIcon } from 'naan/icons/arrow-left-icon';
import { HStack } from 'naan/primitives/stack';
import { HSpacer } from 'naan/primitives/spacer';
import { useGlobalMessages } from 'components/use-global-messages';

import __ from 'core/lib/localization';
import { ScrollToTop } from 'lib/scroll-utils';

const LinkWrapper = styled(RouterLink, {
  $$textColor: '$colors$textSecondary',
  display: 'flex',
  flexDirection: 'row',
  textStyle: 'body-bold',
  color: '$$textColor',
  textDecoration: 'none',

  '&:hover': {
    $$textColor: '$colors$teal-500',
  },
  '& > .icon': {
    lineHeight: 0,
    marginRight: '8px',
  },
  '@medium': {
    textStyle: 'small-heading',
  },
  variants: {
    active: {
      true: {
        $$textColor: '$colors$teal-500',
      },
    },
  },
});

const FooterContainer = styled('div', {
  position: 'sticky',
  top: '100vh',
  px: '$space$4',

  '@large': {
    px: '$space$6',
  },

  '@extraLarge': {
    margin: '0 auto',
    maxWidth: 'auto',
  },
});

function Link({ children, to, ...props }: LinkProps) {
  let resolved = useResolvedPath(to);
  let match = useMatch({ path: resolved.pathname, end: true });

  return (
    <LinkWrapper active={!!match} to={to} {...props}>
      {children}
    </LinkWrapper>
  );
}

const AccountNavWrapper = styled('nav', {
  $$itemSpacing: '16px',
  $$logoHeight: '32px',
  padding: '16px 0',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'flex-end',
  alignItems: 'center',
  [`& > ${LinkWrapper} + ${LinkWrapper}`]: {
    marginLeft: '$$itemSpacing',
  },
  '& > .logo': {
    color: 'inherit',
    textDecoration: 'none',
    marginRight: 'auto',
    '& > .just-the-icon': {
      color: '$colors$gray-400',
    },
    '& > not(:.just-the-icon) svg': {
      height: '$$logoHeight',
      width: 'auto',
    },
  },
  '@medium': {
    $$logoHeight: 'auto',
    $$itemSpacing: '24px',
  },
});

const LegacyLogo = () => {
  const { classroomEnabled } = AppFactory.root.userManager;

  if (!classroomEnabled) {
    return <MyLupaLogo />;
  }

  return (
    <Responsive
      renderDefault={() => (
        <span className="just-the-icon">
          <ArrowLeftIcon />
        </span>
      )}
      renderMediumAndUp={() => (
        <HStack align="center">
          <ArrowLeftSmallIcon />
          <HSpacer size={2} />
          <LupaLogo classrooms />
        </HStack>
      )}
    />
  );
};

const WebModeLogo = () => {
  const appMode = ReturnNavState.appMode;

  return (
    <Responsive
      renderDefault={() => (
        <span className="just-the-icon">
          <ArrowLeftIcon />
        </span>
      )}
      renderMediumAndUp={() => (
        <HStack align="center">
          <ArrowLeftSmallIcon />
          <HSpacer size={2} />
          <LupaLogo classrooms={appMode === 'teach'} />
        </HStack>
      )}
    />
  );
};

const LogoLink = () => {
  const { classroomEnabled, webModeEnabled } = AppFactory.root.userManager;
  const l2 = ReturnNavState.l2; // captured state from previous navigation
  const appMode = ReturnNavState.appMode;

  if (webModeEnabled) {
    return (
      <RouterLink className="logo" to={`/app/${appMode}/${l2}`}>
        <WebModeLogo />
      </RouterLink>
    );
  }

  return (
    <RouterLink
      className="logo"
      to={classroomEnabled ? `/app/teach/${l2}` : '/app/profile/account'}
    >
      <LegacyLogo />
    </RouterLink>
  );
};

const AccountNav = () => {
  // const { classroomEnabled, webModeEnabled } = AppFactory.root.userManager;
  // const l2 = ReturnNavState.l2; // captured state from previous navigation
  // const appMode = ReturnNavState.appMode;

  return (
    <AccountNavWrapper>
      <LogoLink />
      <Link className="nav-item" to="/app/profile/account">
        <span className="icon">
          <AvatarIcon />
        </span>
        <span className="label">{__('Account', 'common.account')}</span>
      </Link>
      <Link to="/app/profile/gift-coupons">
        <span className="icon">
          <GiftIcon />
        </span>
        <span className="label">
          {__('Gift coupons', 'common.giftCoupons')}
        </span>
      </Link>
    </AccountNavWrapper>
  );
};

const Wrapper = styled('div', {
  display: 'grid',
  gridTemplateRows: 'auto 1fr',
  '& .inner': {
    margin: '0 auto',
    width: 'min( 960px, calc( 100% - 32px ) )',
  },
  '& > .nav': {
    backgroundColor: '$colors$gray-25',
    position: 'sticky',
    top: '0',
    zIndex: '9999',
  },
  '& > .content': {},
});

export const ProfileLayout: React.FC = observer(({ children }) => {
  const currentMessage = useGlobalMessages();

  return (
    <>
      <ScrollToTop />
      {currentMessage && (
        <GlobalMessage
          type={currentMessage.type}
          message={currentMessage}
          onDismiss={AppFactory.messageService.clear}
        />
      )}
      <Wrapper>
        <div className="nav">
          <div className="inner">
            <AccountNav />
          </div>
        </div>
        <section className="content">
          <div className="inner">{children}</div>
        </section>
      </Wrapper>
      <FooterContainer>
        <Wrapper>
          <GlobalFooter />
        </Wrapper>
      </FooterContainer>
    </>
  );
});
