import * as React from 'react';
// import { SmTwitterIcon } from 'naan/icons/sm-twitter-icon';
// import { SmInstagramIcon } from 'naan/icons/sm-instagram-icon';
import { PowByJw } from './logos/pow-by-jw';
import { RaLogo } from './logos/ra';
import { styled } from 'naan/stitches.config';
import { HSpacer } from 'naan/primitives/spacer';
//@ts-expect-error
import { version } from '@jw-spa-version';
import { getConfig } from 'app/env';

const Wrapper = styled('footer', {
  // position: 'sticky',
  // bottom: 0,
  display: 'grid',
  gridTemplateColumns: 'repeat(4,1fr)',
  gridTemplateRows: 'repeat(2,auto)',
  color: '$textSecondary',
  a: {
    color: 'inherit',
    textDecoration: 'none',
  },
  '& .logos': {
    // background: 'red',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    gridColumn: '1/-1',
    gridRow: '1/2',
    borderBottom: '1px solid $colors$gray-100',
    py: '$space$5',
  },
  '& .links': {
    // background: 'yellow',
    alignItems: 'center',
    display: 'flex',
    gridColumn: '1/3',
    gridRow: '2/3',
    py: '$space$5',
    textStyle: 'tiny-text',
    a: {
      flexShrink: 0,
      paddingRight: '6px',
      marginRight: '6px',
      borderRight: '1px solid $colors$gray-100',
      '&:last-child': {
        borderRight: 'none',
      },
    },
  },
  '& .support-links': {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'flex-end',
    gridColumn: '3/5',
    gridRow: '2/3',
    py: '$space$5',
    textStyle: 'tiny-text',
    a: {
      flexShrink: 0,
      paddingRight: '6px',
      marginRight: '6px',
      borderRight: '1px solid $colors$gray-100',
      '&:last-child': {
        paddingRight: '0',
        marginRight: '0',
        borderRight: 'none',
      },
    },
    span: {
      color: '$colors$gray-200',
    },
  },
  '& .socials': {
    // background: 'orange',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    gridColumn: '3/5',
    gridRow: '2/3',
    py: '$space$5',
    a: {
      marginLeft: '$space$3',
    },
  },
  '@small': {
    '& .links': {
      textStyle: 'small-text',
      a: {
        paddingRight: '$space$2',
        marginRight: '$space$2',
      },
    },
    '& .support-links': {
      textStyle: 'small-text',
      a: {
        paddingRight: '$space$2',
        marginRight: '$space$2',
      },
    },
  },
  '@extraLarge': {
    gridTemplateColumns: 'repeat(3,1fr)',
    gridTemplateRows: '1',
    borderTop: '1px solid $colors$gray-100',

    '& .logos': {
      gridColumn: '2/3',
      border: 'none',
    },
    '& .links': {
      gridColumn: '1/2',
      gridRow: '1',
    },
    '& .support-links': {
      gridColumn: '3/4',
      gridRow: '1',
    },
    '& .socials': {
      gridColumn: '3/4',
      gridRow: '1',
    },
    margin: '0 auto',
    width: '960px', //maybe this should be a var somewhere
  },
});

export const GlobalFooter = () => {
  return (
    <>
      <Wrapper>
        <div className="logos">
          <a
            href="https://radioambulante.org/"
            target="_blank"
            rel="noreferrer"
          >
            <RaLogo />
          </a>
          <HSpacer size={6} />
          <a href="https://www.jiveworld.com/" target="_blank" rel="noreferrer">
            <PowByJw />
          </a>
        </div>

        <div className="links">
          <a
            href="https://www.jiveworld.com/terms-of-service"
            target="_blank"
            rel="noreferrer"
          >
            Terms of service
          </a>
          <a
            href="https://www.jiveworld.com/privacy"
            target="_blank"
            rel="noreferrer"
          >
            Privacy
          </a>
          <a
            href="https://www.jiveworld.com/eula"
            target="_blank"
            rel="noreferrer"
          >
            EULA
          </a>
        </div>
        <div className="support-links">
          <a
            href="https://www.lupa.app/contact-us"
            target="_blank"
            rel="noreferrer"
          >
            Contact &amp; support
          </a>
          <span title={version}>v{version}</span>
          {getConfig('soundbites.globalFooterLink') ? (
            <span>
              | <a href="/try-soundbites">sb</a>
            </span>
          ) : null}
        </div>
        {/* <div className="socials">
        <a href="https://twitter.com/LupaApp" target="_blank" rel="noreferrer">
          <SmTwitterIcon />
        </a>
        <a
          href="https://www.instagram.com/lupa.app/"
          target="_blank"
          rel="noreferrer"
        >
          <SmInstagramIcon />
        </a>
      </div> */}
      </Wrapper>
    </>
  );
};
