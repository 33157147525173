import React from 'react';
import { observer } from 'mobx-react';
import {
  PauseControlIcon,
  PlayControlIcon,
  RequestPauseControlIcon,
  SimplePauseControlIcon,
} from '../../../study/views/player-icons';
import { ControlButton } from './control-button';
import { usePlayerModel } from '../player-model-context';

const SimplePlayPauseButton = observer(() => {
  const model = usePlayerModel();
  const {
    player: { transportState },
    playActionDisabled,
  } = model;

  const paused = !transportState.isPlaying;

  return (
    <ControlButton
      onClick={() => model.playPauseAction()}
      disabled={playActionDisabled}
    >
      {paused ? <PlayControlIcon /> : <SimplePauseControlIcon />}
    </ControlButton>
  );
});

const ComplexPlayPauseButton = observer(() => {
  const model = usePlayerModel();
  const {
    player: { transportState },
    pauseDurationInMs,
    playActionDisabled,
  } = model;
  // log.debug(`atAudioEnd: ${String(atAudioEnd)}`);

  const paused = !transportState.isPlaying;
  const pendingPause = transportState.pendingPause;

  return (
    <ControlButton
      onClick={() => model.playPauseAction()}
      disabled={playActionDisabled}
    >
      {paused ? (
        <PlayControlIcon />
      ) : pendingPause ? (
        <PauseControlIcon durationInMs={pauseDurationInMs} />
      ) : (
        <RequestPauseControlIcon />
      )}
    </ControlButton>
  );
});

export const PlayPauseButton = observer(({ complex }: { complex: boolean }) => {
  if (complex) {
    return <ComplexPlayPauseButton />;
  }
  return <SimplePlayPauseButton />;
});
