import { objectFromUrlQuery } from 'common/object-from-url-query';
import { createLogger } from 'app/logger';
import { BaseRoot } from 'core/models/base-root';
import { bindery } from 'ts-state-tree/tst-core';
import tstSchema from './tst-schema.json';
import { nanoid } from 'nanoid';
import { soundbitesMode } from 'lib/platform';

const log = createLogger('spa-app-service');

const safelyGetStorage = () => {
  try {
    localStorage.setItem('lupa.ls', '1');
    return localStorage;
  } catch (error) {
    return null;
  }
};

const storage = safelyGetStorage();

/**
 * Wraps `fn` into a promise.
 * @param {Function} fn
 */
// const promisify = fn => (...args) =>
//   new Promise((resolve, reject) => {
//     try {
//       resolve(fn(...args));
//     } catch (error) {
//       reject(error);
//     }
//   });

/**
 * removes the query string from the location bar address
 */
const clearQuery = () => {
  var currentUrl = window.location.href.split('?')[0];
  window.history.replaceState({}, '', currentUrl);
};

const tokenKey = '$jwtoken';
const installationIdKey = '$jwuuid'; // persisted anonymous id
const userDataKey = '$jwdata'; // locally persisted user data - currently only used for anonymous soundbite mode

export class AppRoot extends BaseRoot {
  static CLASS_NAME = 'AppRoot' as const;

  private static bound: boolean = false;

  public static create(
    snapshot: any = {} /*, dependencies: any = {}*/
  ): AppRoot {
    AppRoot.ensureBound();
    const model = super.create(AppRoot, snapshot) as AppRoot;
    return model;
  }

  public static ensureBound(): void {
    if (!AppRoot.bound) {
      AppRoot.bound = true;
      bindery.mergeSchemaDefinitions(tstSchema as any);
      bindery.bind(AppRoot);
      BaseRoot.bindModels(bindery);
      bindery.compileBindings();
    }
  }

  // constructor() {
  //   super();
  // }

  /**
   * automatically called after creation
   */
  async initState(): Promise<void> {
    this.setStatus('initializing');
    this.ensureInstallationId();
    this.checkForTokenInLocation();
    await this.authFromStoredToken();
    if (!this.userManager.authenticated) {
      log.info('initState - anonymous reporting context');
      this.setReportingContext(); // setup anonymous context when not auto-logged in

      // needed to avoid repeatead display of soundbite intro panel
      try {
        this.userManager.loadLocal();
      } catch (error) {
        log.info('error loading local state', error);
      }
    }
    this.setReady();
  }

  async persist(): Promise<void> {
    // console.log('TKN', this.userManager?.token);
    this.storeToken(this.userManager?.token);
  }

  /**
   * if there's a query var in the url like
   * `token=eyJabc123.eyJabc123456`
   * it will grab that and store it locally
   */
  checkForTokenInLocation() {
    const { token = null } = objectFromUrlQuery();
    if (token) {
      log.info(`url token: ${token}`);
      this.storeToken(token);
      clearQuery();
    }
  }

  /**
   * if we have a locally stored token we use that to log the user in
   */
  async authFromStoredToken() {
    if (this.storedToken) {
      try {
        log.info(`auto login with stored token`);
        await this.userManager.autoLogin(this.storedToken);
      } catch (error) {
        // eslint-disable-next-line no-console
        console.log('ERROR', error);
        // todo: report to bugsnag
      }
    }
    this.setStatus('ready');
  }

  /**
   * stores @token locally
   */
  storeToken(token: string) {
    if (!storage) {
      log.debug('storage not available');
      return;
    }
    if (token !== null) {
      log.debug('saving storedToken');
      storage?.setItem(tokenKey, token);
    } else {
      log.debug('removing storedToken');
      this.clearStoredToken();
    }
  }

  /**
   * Clear stored token
   */
  clearStoredToken() {
    if (!storage) {
      log.debug('storage not available');
      return;
    }
    log.debug('removing storedToken');
    storage?.removeItem(tokenKey);
    log.debug('storedToken removed');
  }

  // function setActionAfterAuthentication(type, payload = null) {
  //   // TODO: restrict the actions with an enum or something
  //   self.actionAfterAuthentication = { type, payload };
  // };

  // // --------------------------------------------
  // // Functions MST expect
  // // Since we are using
  // // --------------------------------------------
  // // mst expects this function to be async and return a promise
  // // that's why we use `promisify` on what should be plain sync functions
  // async persist(options: any = {}) {
  //   // this is a flag that is only send when logging out,while resetting the data.
  //   // we may want to refactor the whole persist mechanism at some point
  //   // but this will work for now.
  //   const { resetting = false } = options;

  //   if (resetting) {
  //     // we're logging out
  //     log.info('resetting... clearStoredToken');
  //     await promisify(clearStoredToken)();
  //   } else {
  //     // we're logging in
  //     log.debug('not resetting... storeToken');
  //     const userToken = this.root.userManager?.token ?? null;
  //     await promisify(storeToken)(userToken);
  //   }
  // }

  /**
   * getter to easy access the stored token
   * makes it implementation agnostic
   */
  get storedToken() {
    return storage?.getItem(tokenKey) ?? null;
  }

  // roll our own persisted anonymous id because our analytics stuff is a f!@#$ mess
  ensureInstallationId(): String {
    let uuid = storage?.getItem(installationIdKey) ?? null;
    if (!uuid) {
      try {
        uuid = nanoid();
        log.debug('allocating installationId: ', uuid);
        storage?.setItem(installationIdKey, uuid);
      } catch (error) {
        // eslint-disable-next-line no-console
        console.log('ERROR', error);
      }
    }
    this.userManager.setInstallationId(uuid);
    return uuid;
  }

  // ?? make these async even if they don't need to be?

  storeLocalUserData(payload: string) {
    storage?.setItem(userDataKey, payload);
  }

  loadLocalUserData() {
    const result = storage?.getItem(userDataKey) ?? null;
    return result;
  }
}
