import React from 'react';
import { observer } from 'mobx-react';
import { Button } from '@naan/primitives/button';
import __ from '@core/lib/localization';
import { EyeIcon } from '@naan/icons/eye-icon';
import { PlayerStatus } from 'player/models/player-model';
import { AppFactory } from 'app/app-factory';

export const StudyFromHereButton = observer(() => {
  const model = AppFactory.studyModel;
  const action = React.useCallback(() => {
    if (model.playerStatus === PlayerStatus.PLAYING) {
      model.pause();
    }

    model.togglePlayerMode();
  }, [model]);

  if (model.studyMode) {
    return null;
  }

  return (
    <Button
      onClick={action}
      label={__('Study from here', 'player.studyFromHere')}
      size="medium"
      leftIcon={<EyeIcon />}
      presentation="blue"
    />
  );
});
