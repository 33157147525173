import {
  BasePlayerModel,
  PlayerType,
  TranslationButtonState,
} from 'player/models/base-player-model';
import { ExcerptData } from '@tikka/client/catalog-types';
import { createLogger } from 'app/logger';
import { makeObservable, observable } from 'mobx';
import { LoadingStatus } from 'player/models/player-model';
import { Speaker } from '@core/models/catalog/speaker';
import { Story } from '@core/models/story-manager/story';
import { AppFactory } from 'app/app-factory';

const log = createLogger('soundbite-model');

export enum AnswerState {
  initial,
  disabled,
  unrevealed,
  revealed,
}

export class SoundbiteModel extends BasePlayerModel {
  playerType = PlayerType.SOUNDBITE;

  story: Story;
  @observable.ref answerRevealed = false; // once revealed, then always stays revealed

  constructor() {
    super();
    makeObservable(this);
  }

  async initFromData(data: ExcerptData) {
    log.debug('initFromData');
    this.forceDisablePlayback = true; // enabled once script actions are scrolled into view
    this.answerRevealed = false;
    this.initFromPlayerData(data);

    const { storyManager } = AppFactory.root;
    if (data.volumeDataUrl) {
      log.info('loading volumeDataUrl: ', data.volumeDataUrl);
      this.story = await storyManager.loadVolumeDataUrl(data.volumeDataUrl);
    } else {
      log.info('missing volumeDataUrl - resolving from storyManagaer');
      // fallback until all soundbites reingested
      this.story = storyManager.storyForUnitSlug(this.excerptData.unitSlug); // confirm unit vs volume slug
      if (!this.story) {
        log.info('anonymous mode');
        await AppFactory.root.userManager.skipAuth();
        this.story = storyManager.storyForUnitSlug(this.excerptData.unitSlug); // confirm unit vs volume slug
      }
    }
    log.debug('themeColor: ', this.story?.themeColor);

    // don't set ready here. need to let the parent screen complete the initial state
    // this.setReady();

    // this.speakerMap = {};
    // for (const speaker of this.studyData.speakers) {
    //   this.speakerMap[speaker.label.l2] = speaker;
    // }

    log.debug('initFromData complete');
  }

  resetSession() {
    this.setStatus(LoadingStatus.UNINITIALIZED);
    this.initFromData(this.excerptData);
  }

  get excerptData(): ExcerptData {
    return this.data as ExcerptData;
  }

  get dataSourceUrl(): string {
    return this.excerptData.dataUrl;
  }

  // latest decision is to completely omit speaker labels for the soundbite player
  resolveSpeaker(label: string): Speaker {
    return null;
  }

  // resolveSpeaker(label: string): Speaker {
  //   if (!label) {
  //     return null;
  //   }
  //   if (this.story) {
  //     return this.story.resolveSpeaker(label);
  //   } else {
  //     log.error(`missing speaker data for label: ${label}`);
  //     return null;
  //   }
  // }

  dismissIntro() {
    this.setOnloadModalNeeded(false);

    const { userManager } = AppFactory.root;
    userManager.userData.playerSettings.setIntroShown(true);
    // still need to suppress network animation for some operations, but this should only affected signed in users testing try-soundbites
    userManager.persistUserData();
  }

  revealAnswer() {
    this.answerRevealed = true;
    this.track('answer_revealed');
  }

  get answerState(): AnswerState {
    // need to touch all the source state so that mobx reactes
    // eslint-disable-next-line no-unused-vars
    const touch = [
      this.answerRevealed,
      this.afterNotionalCompletion,
      this.hasBeenPlayed,
    ];
    // log.info(`answer state: `, touch);
    if (this.answerRevealed) {
      return AnswerState.revealed;
    }
    if (this.completionReached) {
      return AnswerState.unrevealed;
    }
    if (this.hasBeenPlayed) {
      return AnswerState.disabled;
    }
    return AnswerState.initial;
  }

  // no-op needed to satisfy currently shared keyboard controls
  togglePlayerMode() {}

  get fluentListenMode(): boolean {
    return false;
  }

  get complexPlayActionEnabled(): boolean {
    return false;
  }

  get playActionDisabled(): boolean {
    return this.forceDisablePlayback;
  }

  playPauseAction() {
    this.simplePlayPauseAction();
  }

  // control never shown for soundbite player
  get backToFurthestUI(): boolean {
    return false;
  }

  // not needed for soundbite player
  get progressBarUI(): boolean {
    return false;
  }

  get translationButtonState(): TranslationButtonState {
    if (this.answerState === AnswerState.revealed) {
      return TranslationButtonState.enabled;
    } else {
      return TranslationButtonState.disabled;
    }
  }

  // disables ability to click on an unvisited sentence
  get skipForwardAllowed(): boolean {
    return false;
  }

  get metricsPrefix(): string {
    return 'soundbite';
  }
}
