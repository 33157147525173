import { ModelTreeNode, identifier } from 'ts-state-tree/tst-core';
import { Speaker } from './speaker';

export class VolumeCatalogData extends ModelTreeNode {
  static CLASS_NAME = 'VolumeCatalogData' as const;

  @identifier
  slug: string = '';

  volumeDataUrl: string = null;
  title: string = '';
  unitSlugs: string[] = [];
  totalDurationMinutes: number = 0;
  // todo: figure out sorting
  speakers: Speaker[] = []; // jw.next format
  themeColor: string;

  static create(snapshot: any) {
    return super.create(VolumeCatalogData, snapshot) as VolumeCatalogData;
  }

  speaker(label: string): Speaker {
    return this.speakers.find(speaker => speaker.labelKey === label);
  }
}
