/* eslint-disable jsx-a11y/accessible-emoji */
import React, { useState } from 'react';
import { useSwitch } from '@naan/hooks/use-switch';
import { Tooltip, UncontrolledTooltip } from '@naan/primitives/tooltip';
import { Heading } from 'components/ds/common/heading';
import { Button } from 'components/ds/buttons';

import { includeTextStyles, getColor } from 'legacylib/theme/utils';
import styled from 'styled-components';

const Wrapper = styled.div`
  .tip-container {
    margin-top: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: ${getColor('red200')};
    height: 200px;
    width: 100%;
  }

  label {
    ${includeTextStyles('body')};
  }

  select {
    margin-left: 12px;
    ${includeTextStyles('body')};
    padding: 1rem;
    border: 1px solid ${getColor('gray100')};
    border-radius: 4px;
  }

  .tip-thing {
    ${includeTextStyles('body-bold')};
    font-size: 18px;
    cursor: pointer;
  }

  .emoji-container {
    padding: 40px;
  }
`;

export const pathname = '/tooltips';
export const navlabel = 'Tooltips';

export const Screen = () => {
  const [showTip, { toggle }] = useSwitch(false);
  const [placement, setPlacemenet] = useState('top');

  return (
    <>
      <Heading>Tooltips</Heading>
      <Wrapper>
        <label>
          Placement:
          <select
            defaultValue="top"
            onChange={e => {
              setPlacemenet(e.target.value);
            }}
          >
            <option value="left">Left</option>
            <option value="right">Right</option>
            <option value="bottom">Bottom</option>
            <option value="top">Top</option>
          </select>
        </label>
        <div className="tip-container">
          <Tooltip
            showTip={showTip}
            placement={placement}
            tipContent={
              'Some assigned stories in this class require full access'
            }
          >
            <Button onClick={toggle} label={'Toggle tip'}></Button>
          </Tooltip>
        </div>
        <div className="tip-container">
          <Tooltip
            presentation={'white'}
            showTip={showTip}
            placement={placement}
            tipContent={"Inti Pacheco's aunt."}
          >
            <Button onClick={toggle} label={'Toggle white tip'}></Button>
          </Tooltip>
        </div>
        <div className="tip-container">
          <UncontrolledTooltip
            placement={placement}
            tipContent={<div className="emoji-container">🔥🤩🎤💡</div>}
          >
            <div className="tip-thing">Hello, click me</div>
          </UncontrolledTooltip>
        </div>
        <div className="tip-container">
          <UncontrolledTooltip
            event="hover"
            placement={placement}
            tipContent={<div className="emoji-container">🤟🏽</div>}
          >
            <div className="tip-thing">Hi, hover over me</div>
          </UncontrolledTooltip>
        </div>
      </Wrapper>
    </>
  );
};
