import * as React from 'react';
import { styled } from '@naan/stitches.config';

const Badge = styled('div', {
  position: 'relative',
  backgroundColor: '$colors$white',
  height: 'fit-content',
  minWidth: '20px',
  margin: '10px',
  borderRadius: '50%',
  textStyle: 'tiny-text',
  textAlign: 'center',
  px: '6px',
  '&::after': {
    $$size: '8px',
    position: 'absolute',
    right: '-2px',
    bottom: '-2px',
    borderTop: '$$size solid black',
    borderTopColor: 'inherit',
    borderLeft: 'calc($$size/2) solid transparent',
    borderRight: 'calc($$size/2) solid transparent',
    transform: 'rotate(-50deg)',
    content: '',
    color: '$colors$white',
  },
});

type VocabListButtonProps = {
  textColor?: string;
  vocabCount: number;
};

export const VocabListButton = ({
  textColor = 'currentColor',
  vocabCount,
}: VocabListButtonProps) => {
  return (
    <Badge onClick={() => {}} css={{ color: textColor }}>
      {vocabCount}
    </Badge>
  );
};
