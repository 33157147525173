import { getParentOfType, ModelTreeNode } from 'ts-state-tree/tst-core';
import {
  BEGINNING_OF_CHAPTER,
  END_OF_CHAPTER,
  END_OF_STORY_CHAPTER,
  END_OF_UNIT_CHAPTER,
} from 'core/lib/constants/vars';
import invariant from 'core/lib/invariant';
import { FluentListenStatus, PlayerMode } from 'common/misc-types';
import { StoryProgress } from './story-progress';
import { AppFactory } from 'app/app-factory';
import { createLogger } from 'app/logger';

const log = createLogger('location-pointer');

const pad = (n: number) => String(n).padStart(3, '0');

export type ChapterRef = { unit: number; chapter: number };

/**
 * LocationPointer
 *
 * study position within an story.
 * two instances for each story: currentPoint, furthestPoint
 */

export class LocationPointer extends ModelTreeNode {
  static CLASS_NAME = 'LocationPointer' as const;

  static create(snapshot: any) {
    return super.create(LocationPointer, snapshot) as LocationPointer;
  }

  address: string = BEGINNING_OF_CHAPTER;

  unit: number = 1;
  chapter: number = 1;
  // for now 1 = first listen in progress, 2 = first listen complete, 3 = fluent listen complete
  iteration: number = 1;
  millisPlayed: number = 0; //reflects address, not actual play counter. - Perhaps rename to just "millis"?

  playerMode: PlayerMode;
  fluentListenStatus: FluentListenStatus;

  // // when at end of chapter will increment iteration if not already fully progressed
  // // and reset position to start
  // // returns true if modified
  // advanceIteration(): boolean {
  //   if (this.atEndOfChapter) {
  //     this.iteration = Math.min(this.iteration + 1, 3);
  //     this.millisPlayed = 0;
  //     this.address = BEGINNING_OF_CHAPTER;
  //     console.log(`iteration advanced - new state: ${this.sortableString}`);
  //     return true;
  //   } else {
  //     return false;
  //   }
  // }

  // this should probably be false when completed (atEndOfStory)
  // deprecated
  get inProgress(): boolean {
    return this.played;
  }

  get played(): boolean {
    return this.chapter > 1 || this.iteration > 1 || this.millisPlayed > 0;
  }

  // drives the "continue" CTA label
  get listening(): boolean {
    return this.inProgress && !this.atEndOfStory;
  }

  get sortableString(): string {
    return `${pad(this.unit)}-${pad(this.chapter)}-${pad(this.iteration)}:${
      this.address
    }`;
  }

  isLessThan(other: LocationPointer): boolean {
    return this.sortableString < other.sortableString;
  }

  matchesChapter(chapterRef: ChapterRef): boolean {
    return this.unit === chapterRef.unit && this.chapter === chapterRef.chapter;
  }

  beforeChapter(chapterRef: ChapterRef): boolean {
    return (
      this.unit < chapterRef.unit ||
      (this.unit === chapterRef.unit && this.chapter < chapterRef.chapter)
    );
  }

  // get stage(): PlayerMode {
  //   return this.iteration === 1 ? PlayerMode.STUDY : PlayerMode.FLUENT_LISTEN;
  // }
  get stage(): string {
    return this.iteration === 1 ? 'STUDY' : 'FLUENT_LISTEN';
  }

  get atEndOfChapter(): boolean {
    return this.address === END_OF_CHAPTER;
  }

  get atEndOfUnit(): boolean {
    return this.chapter === END_OF_UNIT_CHAPTER;

    // if (this.address === END_OF_CHAPTER) {
    //   return true;
    // }
    // const chapter = this.chapterData;
    // if (chapter) {
    //   return this.millisPlayed >= chapter.durationMillis;
    // }
    // // assert failure
    // return false;
  }

  get atEndOfStory(): boolean {
    return this.atEndOfUnit && this.unit === this.unitCount;
  }

  // todo: revisit
  get completedChapters(): number {
    if (this.atEndOfStory) {
      return END_OF_STORY_CHAPTER;
    } else {
      return this.chapter - 1;
    }
  }

  // todo: we can hopefully remove all this.

  get carouselIndex(): number {
    if (this.atEndOfStory) {
      return this.chapterCount; // summary card
    } else {
      return this.chapter - 1;
    }
  }

  // not sure if factoring up carouselIndex is worth the getParent dependency
  get chapterCount() {
    const story = this.story;
    if (story) {
      // being paranoid
      return story.chapterCount;
    } else {
      invariant(false, 'chapterCount - null story');
      return this.chapter;
    }
  }

  get unitCount() {
    return this.story?.unitCount || 0;
  }

  get story() {
    // this fails because our getters are getting prematurely called during applySnapshot
    // return getParentOfType(this, Story)

    const storyProgress = getParentOfType(this, StoryProgress);
    if (!storyProgress) {
      log.error(`missing storyProgress parent`);
      return null;
    }
    const result = AppFactory.root.storyManager.story(storyProgress.slug);
    if (!result) {
      log.error(`missing story for progress slug: ${storyProgress.slug}`);
    }
    return result;
  }

  // can't count on having access to the parent context

  // get chapterData(): ChapterCatalogData {
  //   const story = this.story;
  //   let result = undefined;
  //   if (story) {
  //     result = story.chapterForPoint(this);
  //   }
  //   if (!result) {
  //     // assert failure
  //     console.error(
  //       `couldn't resolve chapter data for point: ${this.sortableString}`
  //     );
  //   }
  //   return result;
  // }
}
