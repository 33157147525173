import * as React from 'react';
import * as Dialog from '@naan/primitives/modals';

import { /*Chapter,*/ ChapterNote } from '@tikka/client/client-aliases';
import { PlainMarkdown } from '@naan/primitives/text';
import { styled } from '@naan/stitches.config';
import { VSpacer } from '@naan/primitives/spacer';
import { paletteKey } from '../study-palette';
import { Button } from '@naan/primitives/button';
import { useSwitch2 } from '@naan/hooks/use-switch-2';
import { Divider } from '@naan/primitives/divider';
import { observer } from 'mobx-react';
import { keyboardService } from 'lib/services/keyboard-service';

import __ from '@core/lib/localization';
import { GlobalRedactionButton } from '../global-redaction-button';
import { AppFactory } from 'app/app-factory';
// import { Button } from '@naan/primitives/button';

const ScriptHeaderContainer = styled('div', {
  textStyle: 'medium-heading',
  textAlign: 'center',
  padding: '152px 24px 80px',
  color: paletteKey('$$chapterTitle__color'),
  position: 'relative',

  '& > .position': {
    color: paletteKey('$$chapterTitle__positionColor'),
  },

  '& > .redaction-btn': {
    position: 'absolute',
    right: 0,
    bottom: -28,
  },

  '@medium': {
    padding: '192px 24px 120px',
  },
});

const ChapterNotesWrapper = styled('div', {});

const ChapterNotes = observer(({ notes }: { notes: ChapterNote[] }) => {
  const model = AppFactory.studyModel;
  const notesSwitch = useSwitch2(false);

  // React.useEffect(() => {
  //   if (!model.fluentListenMode && model.currentMillis === 0) {
  //     notesSwitch.on();
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);

  if (!notes.length) {
    return null;
  }

  const defaultOpen = model.onloadModalNeeded;

  return (
    <>
      <ChapterNotesWrapper>
        <ChapterNotesDialog
          onDismiss={notesSwitch.off}
          defaultOpen={defaultOpen}
          trigger={
            <Button
              label={__('Notes', 'common.notes')}
              presentation={model.fluentListenMode ? 'gray' : 'grayLight'}
              onClick={notesSwitch.toggle}
            />
          }
        >
          {notes.map(note => (
            <PlainMarkdown
              source={note.text.l1 || note.text.l2}
              key={note.id}
            />
          ))}
        </ChapterNotesDialog>
      </ChapterNotesWrapper>
    </>
  );
});

const ChapterNotesDialog: React.FC<{
  onDismiss: () => void;
  trigger: React.ReactNode;
  defaultOpen?: boolean;
}> = ({ children, onDismiss, trigger, defaultOpen }) => {
  /// @joseph this momentarily stops the current keyboard shortcuts set while the dialog is mounted.
  React.useEffect(() => {
    return keyboardService.stopCurrentShortcutSet();
  }, []);

  React.useEffect(() => {
    return () => {
      // console.log();
      setTimeout(() => {
        document
          .querySelectorAll('[data-radix-focus-guard]')
          .forEach(node => node.remove());
      }, 1000);
    };
  }, []);

  return (
    <Dialog.PureContainer
      onDismiss={onDismiss}
      trigger={trigger}
      defaultOpen={defaultOpen}
    >
      <Dialog.Heading>{__('Notes', 'common.notes')}</Dialog.Heading>
      <Dialog.Body>
        <Divider />
        <VSpacer size={4} />
        {children}
      </Dialog.Body>
      <Dialog.ButtonsContainer>
        <Dialog.Close asChild>
          <Dialog.Button
            presentation="teal"
            label={__('Got it', 'common.gotIt')}
            onClick={onDismiss}
            fullWidth
          />
        </Dialog.Close>
      </Dialog.ButtonsContainer>
    </Dialog.PureContainer>
  );
};

// export const ChapterTitlePresentation = ({
//   element: chapterTitle,
// }: {
//   element: Chapter;
// }) => {
//   return (
//     <ScriptHeaderContainer>
//       <span className="position">{chapterTitle.position}</span>
//       <PlainMarkdown source={chapterTitle.title.l2} />
//       <VSpacer size={'6'} />
//       <ChapterNotes notes={chapterTitle.notes} />
//       <div className="redaction-btn">
//         <GlobalRedactionButton />
//       </div>
//     </ScriptHeaderContainer>
//   );
// };

export const StartOfChapter = () => {
  const model = AppFactory.studyModel;
  return (
    <ScriptHeaderContainer>
      <span className="position">{model.chapter.position}</span>
      <PlainMarkdown source={model.chapter.title} />
      <VSpacer size={'6'} />
      <ChapterNotes notes={model.chapterNotes} />
      {model.studyMode ? (
        <div className="redaction-btn">
          <GlobalRedactionButton />
        </div>
      ) : null}
    </ScriptHeaderContainer>
  );
};
