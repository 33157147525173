import * as React from 'react';
import { observer } from 'mobx-react';
import { VStack } from 'naan/primitives/stack';
import { Story } from 'core/models/story-manager';
import { VSpacer } from 'naan/primitives/spacer';
import { A } from '@common/ui/action-link';

// the lower section of story metadata (needs a better name)
export const StoryVoicesCredits = observer(({ story }: { story: Story }) => {
  return (
    <VStack>
      Lupa release on: {story.releaseDate}
      <br />
      Originally broadcast on: {story.originalBroadcastDate}
      <br />
      {story.weblink ? (
        <>
          <A href={story.weblink}>View on radioambulante.org</A>
          <br />
        </>
      ) : null}
      <VSpacer size={5} />
      <h3>Voices</h3>
      {story.voicesList.map(speaker => {
        return (
          <div key={speaker.label}>
            <strong>
              {speaker.label}
              {speaker.accent ? ` (${speaker.accent})` : null}
            </strong>
            <br />
            {speaker.bio}
            <VSpacer size={2} />
          </div>
        );
      })}
      <VSpacer size={5} />
      <h3>Credits</h3>
      {story.creditsList.map(credit => {
        return (
          <div key={credit.roleKey}>
            <strong>{credit.role}</strong>
            <br />
            {credit.name}
            <VSpacer size={2} />
          </div>
        );
      })}
    </VStack>
  );
});
