import _ from 'lodash';

export function isNumber(o: any) {
  return typeof o === 'number';
}

export function isEmpty(o: any) {
  return _.isEmpty(o);
}

export function notEmpty(o: any) {
  return !_.isEmpty(o);
}

export function isNil(o: any) {
  return _.isNil(o);
}

export function notNil(o: any) {
  return !_.isNil(o);
}

export function isNull(o: any) {
  return _.isNull(o);
}

export function notNull(o: any) {
  return !_.isNull(o);
}
