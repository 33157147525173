import { getKindFromId } from './elements/element-id-utils';
import {
  CreateElementList,
  ElementListSelfReferential,
  ElementListType,
} from './elements/element-list';
import { WideAndEmptyElementList } from './elements/element-list-impl';
import { Intervals } from './intervals/intervals';
import {
  ElementListSelfReferentialKeys,
  IsAny,
  Simplify,
  StripElementList,
  Unionize,
  IsNotUnion,
  LoosenConstraints,
} from './type-utils';

// util-types - TODO: migrate remaining usage
export type StringToString = {
  [index: string]: string;
};

export type ElementIdToString = {
  [index in ElementId]: string;
};

export type StringToElementId = {
  [index: string]: ElementId;
};

export type ElementIdToAny = {
  [index in ElementId]: any;
};

export type StringToElement<ET> = {
  [index: string]: ET;
};

export type ElementIdToElement<ET> = {
  [index in ElementId]: ET;
};

// TODO deal with KINDT is any which yields ${any}:${string} not ${string}:{string}
export type IDTOfKT<KINDT extends string> = `${KINDT}:${string}`;
export type ElementKind = string;
export type ElementId = IDTOfKT<ElementKind>;

export type IElement = {
  kind: string;
  subKind?: string;
  id: ElementId;
};

export type BaseETForKT<KINDT extends string> = {
  kind: `${KINDT}`;
  id: IDTOfKT<KINDT>;
};

export function createSingletonElement<KT extends string, DT extends Object>(
  kind: KT,
  data: DT
): BaseETForKT<KT> & DT {
  return Object.assign(
    {
      kind: `${kind}`,
      id: `${kind}:`,
    },
    data
  ) as any;
}

export type WordElement = BaseETForKT<'WORD'> & {
  text: string;
  time: number;
  endTime: number;
};

// JRW TODO remove this synonym
export type TimedWordElement = WordElement;

export type WordId = WordElement['id'];
export type SentenceId = IDTOfKT<'SENTENCE'>;

export type IWord = {
  id: WordId;
  text: string;
};

export type Indexed = { address: number };
export type IntervalIndexed = Indexed & { endAddress: number };
export type IndexedElement = IElement & Indexed;
export type IntervalIndexedElement = IElement & IntervalIndexed;
export type Timed = { time: number; endTime?: number };
export type TimedElement = IElement & Timed;
export type IntervalTimed = Timed & { endTime: number };
export type IntervalIndexedTimed = IntervalIndexed & IntervalTimed;
export type IntervalTimedElement = IElement & IntervalTimed;
export type ExtractTimed<ET extends any> = ET extends Timed ? ET : never;
export type ExtractIndexed<ET extends any> = ET extends Indexed ? ET : never;
export type SingleKT<KT extends string> = IsNotUnion<KT>;
export type OneTimedKind<ET extends IElement> = SingleKT<
  KTOfET<ET>
> extends true
  ? ExtractTimed<ET>
  : never;

export type OneIndexedKind<ET extends IElement> = SingleKT<
  KTOfET<ET>
> extends true
  ? ExtractIndexed<ET>
  : never;

export function isIntervalTimed(obj: object): obj is IntervalTimed {
  return (
    (obj as IntervalTimed).time !== undefined &&
    (obj as IntervalTimed).endTime !== undefined
  );
}

export function SimplestElementList<ET extends IElement>(elements: ET[]) {
  return CreateElementList({ elements: elements });
}

export type IndexRange = {
  begin: number;
  end: number;
};

export type IdRange<IDT> = {
  begin: IDT;
  end: IDT;
};

export type ElementIdRange = IdRange<ElementId>;

export type WordIdRange = IdRange<IDTOfKT<'WORD'>>;

export type IDTOfET<ET extends IElement> = IsAny<ET> extends true
  ? ElementId
  : ET['id'];

export type KTOfET<ET extends IElement> = IsAny<ET> extends true
  ? string
  : ET['kind'];

export type KTOfIDT<IDT extends IDTOfKT<string>> =
  IDT extends `${infer KT}:${string}` ? KT : never;
export type SKTOfET<ET extends IElement> = IsAny<ET> extends true
  ? string
  : ET['subKind'];

export type ExtractETForIDT<
  ET extends IElement,
  IDT extends IDTOfET<ET>
> = ET extends { id: IDT } ? ET : never;

export type ExtractETForKT<
  ET extends IElement,
  KT extends KTOfET<ET>
> = ET extends { kind: KT } ? ET : never;

export type ExtractETForSKT<
  ET extends IElement & { subKind?: string },
  SKT extends SKTOfET<ET>
> = ET extends { subKind: SKT } ? ET : never;

// TODO deal with T is any currently seems take union of both ternary paths
export type universalIDTOf<
  ET extends IElement,
  T extends ET | KTOfET<ET>
> = T extends string ? IDTOfKT<T> : T extends ET ? IDTOfET<T> : never;

export type universalETOf<
  ET extends IElement,
  T extends IDTOfET<ET> | KTOfET<ET>
> = T extends IDTOfET<ET>
  ? ExtractETForIDT<ET, T>
  : T extends KTOfET<ET>
  ? ExtractETForKT<ET, T>
  : never;

export type ExtractIndexedET<ET extends IElement> = ET extends IndexedElement
  ? ET
  : never;

export type ExtractIntervalIndexedET<ET extends IElement> =
  ET extends IntervalIndexedElement ? ET : never;

export type ExtractTimedET<ET extends IElement> = ET extends TimedElement
  ? ET
  : never;

export type ElListConstraints = {
  values: IElement[];
};

export type IDTOfELT<ELT> = ELT extends ElListConstraints
  ? IDTOfET<ELT['values'][number]>
  : never;

export type KTOfELT<ELT extends ElListConstraints> =
  ELT extends ElListConstraints ? ELT['values'][number]['kind'] : never;

export type ETOfELT<ELT> = ELT extends ElListConstraints
  ? ELT['values'][number]
  : never;

export type ElementList<ET extends IElement> = StripElementList<
  ElementListType<ET>
>;

export type WordElementList = ElementList<WordElement>;

export const SystemBasicKindsList = [
  'WORD',
  'SENTENCE',
  'PARAGRAPH',
  'PASSAGE',
  'CHAPTER',
  'CHAPTER_NOTE',
  'CHAPTER_COMPLETE',
  'WORD_GROUP',
  'ADHOC_RANGE',
] as const;

export type SystemBasicKinds = Unionize<typeof SystemBasicKindsList>;

export type CreateBase<K extends SystemBasicKinds> = BaseETForKT<K>;

export type ELTOf<ET extends IElement> = {
  values: ET[];
  getId: (...args: any[]) => ElementId;
  getIndex: (...args: any[]) => number;
  getElement: (...args: any[]) => IElement;
};

export type ELTPartial<K extends keyof LooseElementList> = Record<K, any>;

export type IndexedELTOf<ET extends IElement> = {
  values: ET[];
  words: any;
};

export interface AdhocRangeElement
  extends CreateBase<'ADHOC_RANGE'>,
    IntervalIndexed {
  range: WordIdRange;
}

export type StringToNumber = {
  [index: string]: number;
};

export type WideElement = IElement &
  IntervalIndexed &
  IntervalTimed & {
    subKind: string;
  };

export const NO_INDEX = -1;

export const EmptyElementList = WideAndEmptyElementList as any;

// TODO examine the need for different empty list types
export const EmptyWordElementList: ElementList<WordElement> = SimpleElementList(
  []
) as any;

export type ElementListParam<ET extends IElement> =
  | ElementList<ET>
  | ElementList<ET>;

type LooseElementListBase = LoosenConstraints<
  Omit<
    ElementListType<WideElement, Intervals, Intervals>,
    ElementListSelfReferentialKeys
  >
>;

export interface ILooseElementList
  extends LooseElementListBase,
    ElementListSelfReferential<ILooseElementList, WordElementList> {}

export type LooseElementList = Simplify<ILooseElementList>;

export type WideElementList = ElementList<WideElement>;

export function SimpleElementList<ET extends IElement>(
  elements: ET[]
): ElementList<ET> {
  const result = CreateElementList({ elements, words: null });
  return result as any;
}

export const useTimeIntervals = (els: ELTOf<any>) =>
  (els as any).timeIntervals as Intervals;
export const useWordIntervals = (els: ELTOf<any>) =>
  (els as any).wordIntervals as Intervals;

// duplicated now in utils/util-types
// todo: migrate usage
// export enum LocaleCode { // 'Locale' or 'LocaleCode'?
//   en = 'en', // 'en-US': 'english',
//   es = 'es', // 'es-US': 'spanish',
//   ja = 'ja',
//   de = 'de', // 'de-DE': 'german',
//   pt = 'pt', // 'pt-BR': 'portuguese',
//   da = 'da', // 'da': 'danish',
// }

export function idIsOfKind<IDT extends ElementId, KT extends ElementKind>(
  id: IDT,
  kind: KT
): id is IDTOfKT<KT> & IDT {
  return getKindFromId(id) === (kind as any);
}

export function castElements<
  ETOUT extends IElement,
  ET extends IElement = IElement
>(et: ET[]): ETOUT[];
export function castElements<
  ETOUT extends IElement,
  ET extends IElement = IElement
>(et: ET): ETOUT;
export function castElements<
  ETOUT extends IElement,
  ET extends IElement = IElement
>(et: ElementList<ET>): ElementList<ETOUT>;
export function castElements(a: any) {
  return a;
}
