import { isEmpty } from 'lodash';
import { StringToString } from './util-types';

export const emdash = '\u2014';
export const elipsis = '\u2026';
export const disdElipsis = '\u22ef';

// used to strip away the italics markdown chars, with the assumption that
// the original text was already scanned style data captured into word ranges
export const stripUnderscores = (str: string): string => {
  if (!str) {
    return str;
  }
  return str.replace(/_/g, '');
};

// used for structural content and translation player data generation
export const transformPlayerText = (str: string): string => {
  if (isEmpty(str)) {
    return '';
  }
  return stripLinefeeds(toUnicodePunctuation(str));
};

// deprecated, should not be used by client-side code
// used by the player data generation to transform em-dash and ellipsis markdown chars and strip
// the underscores (italics assumed to be handled separately)
export function transformTranscriptText(str: string): string {
  return stripUnderscores(toUnicodePunctuation(str));
}

export const stripLinefeeds = (str: string) => {
  if (!str) {
    return str;
  }
  return str.replace(/\n/g, '').replace(/\r/g, '');
};

export const toUnicodePunctuation = <T extends string | StringToString>(
  obj: T
): T => {
  if (!obj) {
    return '' as T; // treat missing translations as an empty string
  }
  if (typeof obj === 'string') {
    return toUnicodePunctuationStr(obj) as T; // assume we're not dealing with subclassed arg
  } else {
    return toUnicodePunctuationObj(obj as StringToString) as T;
  }
};

export const toUnicodePunctuationObj = (
  obj: StringToString
): StringToString => {
  const result: StringToString = {};
  for (const key in obj) {
    result[key] = toUnicodePunctuationStr(obj[key]);
  }
  return result;
};

export const toUnicodePunctuationStr = (str: string): string => {
  // avoid barfage from word group content map which might include boolean props
  if (!str || typeof str !== 'string') {
    return str;
  }
  // return normalizeSpecialChars(str) // not really expecting any special chars at this stage any more, but being paranoid for old, dirty data
  return str.replace(/--/g, emdash).replace(/\.\.\./g, elipsis);
};
