import React from 'react';
import { observer } from 'mobx-react';
import { SectionBreak, SectionEnds } from 'components/ds/common/spacers';
import { Table } from 'components/ds/tables';
import { ClassLabelCell } from '../components/classrooms/table-cells/class-label-cell';
import { CodeCell } from '../components/classrooms/table-cells/code-cell';
import { ClassStudentsCell } from '../components/classrooms/table-cells/class-students-cell';
import { MobileOnlyTableHeader } from '../components/classrooms/mobile-only-table-header';
import { Text } from 'components/ds/common/text';
import { VSpacer, HSpacer } from 'naan/primitives/spacer';
import { HStack } from 'naan/primitives/stack';
import { CreateClassButton } from '../components/classrooms/classroom-list/create-class-button';
import { LicenseBadge } from 'components/classrooms/license-badge';
import { getConfig } from 'app/env';
import { AppFactory } from 'app/app-factory';
import { PageHeading } from 'components/page-heading';
import { ContentGridInnerContainer } from 'components/global-layout';
import { InlineNotice } from 'naan/inline-notice';

import __ from 'core/lib/localization';
import { DeepScrollRestoration } from 'lib/scroll-utils/deep-scroll-restoration';
import { useLocation } from 'react-router-dom';

export const ClassroomsScreen = observer(() => {
  const { managedClassrooms, showClassroomLicenseNag } =
    AppFactory.root.userManager.accountData;

  const location = useLocation();

  // React.useLayoutEffect(() => {
  //   const scrollingContainer = getFirstScrollingParent(
  //     document.getElementById('scroll-beacon')
  //   );
  //   if (scrollingContainer) {
  //     scrollingContainer.scrollTo({ top: 0, behavior: 'instant' });
  //   }
  // }, []);

  return (
    <>
      <DeepScrollRestoration pageKey={location.pathname} />
      <ContentGridInnerContainer>
        <PageHeading
          title={__('My classes', 'classroom.myClasses')}
          size="large"
        />
        <HStack css={{ marginTop: '-48px' }}>
          <HSpacer expand />
          <CreateClassButton />
        </HStack>
        <VSpacer size={4} />
        {showClassroomLicenseNag ? (
          <>
            <InlineNotice
              type="warning"
              message={__(
                'Please remove some students or get in touch to add additional seats.',
                'nags.classLicenseQuotaExceeded'
              )}
              renderLink={() => (
                <a
                  href={getConfig('website.urls.contactUs')}
                  target="_blank"
                  rel="noreferrer"
                >
                  {__('Contact sales', 'common.contactSales')}
                </a>
              )}
            />
            <VSpacer size={3} />
          </>
        ) : null}
        <MobileOnlyTableHeader>
          {__(
            {
              one: '%{count} class',
              other: '%{count} classes',
            },
            'classroom.countClasses',
            {
              count: managedClassrooms.length,
            }
          )}
        </MobileOnlyTableHeader>
        {managedClassrooms.length > 0 ? (
          <Table
            data={managedClassrooms}
            // overflowMenuItems={getMenuItems}
            cells={[
              {
                fieldName: 'label',
                headerLabel: __('Label', 'classroom.label'),
                component: ClassLabelCell,
              },
              {
                headerLabel: __('Class code', 'classroom.classCode'),
                component: CodeCell,
              },
              {
                headerLabel: __('Students', 'classroom.students'),
                component: ClassStudentsCell,
              },
              {
                headerLabel: __('License', 'classroom.license'),
                renderCell: (classroom: any) => (
                  <LicenseBadge classroom={classroom} />
                ),
              },
            ]}
          />
        ) : (
          <>
            <VSpacer
              size={4}
              css={{ borderTop: '1px solid $colors$gray-100' }}
            />
            <Text>
              {__(
                'No active classes. New classes will appear here when you create them.',
                'classroom.classListZeroState'
              )}
            </Text>
          </>
        )}
        <SectionBreak />
        <SectionEnds />
      </ContentGridInnerContainer>
    </>
  );
});
