import * as React from 'react';
import { SnailIcon } from '@naan/icons/snail-icon';
import { Button } from '@naan/primitives/button';
import { styled } from '@naan/stitches.config';
import __ from '@core/lib/localization';
import { ReplayIcon } from '@naan/icons/replay-icon';

const Wrapper = styled('div', {
  margin: '16px 8px',
  display: 'flex',
  gap: '16px',
  backgroundColor: '$colors$white-alpha-70',
  backdropFilter: 'blur(4px)',
  '@small': {
    maxWidth: '308px',
    margin: '16px auto',
  },
});

export const NotationControls = ({
  replay,
  snailReplay,
}: {
  replay: () => void;
  snailReplay: () => void;
}) => {
  return (
    <Wrapper>
      <Button
        size={'large'}
        presentation={'grayLight'}
        fullWidth
        leftIcon={<SnailIcon />}
        label={__('Slow', 'player.slow')}
        onClick={snailReplay}
      />
      <Button
        size={'large'}
        presentation={'grayLight'}
        fullWidth
        leftIcon={<ReplayIcon />}
        label={__('Replay', 'player.replay')}
        onClick={replay}
      />
    </Wrapper>
  );
};
