import { globalCss } from 'naan/stitches.config';
//@ts-expect-error
import { normalize } from 'normalize-stitches';

const boxSizing = {
  '*, *:before, *:after': {
    boxSizing: 'border-box',
  },
};
const globalStyles = globalCss({
  ...normalize,
  ...boxSizing,
  '@font-face': [
    {
      fontFamily: 'larsseit-medium',
      src: `
      url("/assets/fonts/5454002/7a60ab9e-f836-4a67-893d-81a589724598.eot?#iefix") format("eot"),
      url("/assets/fonts/5454002/4cb7676d-9352-4367-9e09-4643861168a8.woff2") format("woff2"),
      url("/assets/fonts/5454002/da0e4157-a84a-4e59-bf79-85090e987cd9.woff") format("woff"),
      url("/assets/fonts/5454002/39916207-e1d1-4b02-ad96-3947023333fb.ttf") format("truetype")
      `,
    },
    {
      fontFamily: 'larsseit-bold',
      src: `
      url("/assets/fonts/5454014/5ebbda2e-6878-4494-ab4d-112eeea23ac0.eot?#iefix") format("eot"),
      url("/assets/fonts/5454014/38f62b25-9bb5-4b8f-ab6d-8a5d73286ec1.woff2") format("woff2"),
      url("/assets/fonts/5454014/5fb439fe-d913-4b7d-9727-20ba5bfe48ff.woff") format("woff"),
      url("/assets/fonts/5454014/7b8b3e80-242b-4f48-9ed6-d0dc4175a61c.ttf") format("truetype")`,
    },
  ],

  '.__no-scroll': {
    overflow: 'hidden',
  },

  '.__with-nag .site-footer': {
    paddingBottom: '56px',
  },

  html: {
    height: '100%',
    $$tabbarHeight: '60px',
    $$sidebarWidth: '240px',
    $$maxWidth: '960px',

    '@large': {
      $$sidebarWidth: '248px',
    },
  },
  body: {
    height: '100%',
    margin: 0,
    textStyle: 'body',
    color: '$textPrimary',
    textSizeAdjust: '100%',
    // overflow: 'hidden', /// removing this temprarily
  },
  '#root': {
    height: '100%',
  },
  '*': {
    margin: 0,
    padding: 0,
    border: 'none',
  },
  table: {
    borderCollapse: 'collapse',
    borderSpacing: '0',
  },
  svg: {
    display: 'block',
  },
});

export const GlobalStyles: React.FC = () => {
  globalStyles();
  return null;
};
