import React from 'react';
import { observer } from 'mobx-react';
import { Button } from '@naan/primitives/button';
import { CrosshairsIcon } from '@naan/icons/crosshairs-icon';
import { scrollToCurrentLine } from 'study/views/fx/scrolling';
import __ from '@core/lib/localization';

export const RecenterButton = observer(() => {
  const action = React.useCallback(() => {
    scrollToCurrentLine();
  }, []);

  return (
    <Button
      onClick={action}
      label={__('Recenter', 'player.recenter')}
      size="medium"
      leftIcon={<CrosshairsIcon />}
      presentation="blue"
    />
  );
});
