import React from 'react';
import { observer } from 'mobx-react';
import { Button } from '@naan/primitives/button';
import { usePlayerModel } from '../player-model-context';
import __ from '@core/lib/localization';

export const CancelPendingPauseButton = observer(() => {
  const model = usePlayerModel();
  const { player } = model;

  if (player.transportState.pendingPause) {
    return (
      <Button
        onClick={() => model.cancelPendingPause()}
        label={__('Cancel auto pause', 'player.cancelAutoPause')}
        size="small"
      />
    );
  }

  return null;
});
