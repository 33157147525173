export const breakpoints = {
  extraSmall: '(min-width: 0px)',
  small: '(min-width: 550px)',
  medium: '(min-width: 736px)',
  large: '(min-width: 992px)',
  extraLarge: '(min-width: 1264px)',
  playerSmall: '(max-width: 1023px)',
  playerSmallAndUp: '(min-width: 617px)',
  playerMediumAndUp: '(min-width: 1024px)',
  playerMedium: '(min-width: 1024px) and (max-width: 1399px)',
  playerLarge: '(min-width: 1400px)',
  playerShort: '(max-height: 799px)',
  widerThanScript: '(min-width: 616px)',
} as const;
