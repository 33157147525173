import * as React from 'react';
import * as Dialog from '@naan/primitives/modals/dialog';
import { AppFactory } from 'app/app-factory';
import { elementIdToDomId } from '@tikka/elements/element-id-utils';
import { ElementNode } from '@tikka/client/client-aliases';
import { observer } from 'mobx-react';
import { Sentence as SentenceElement } from '@tikka/client/client-aliases';
import { StyledSentence } from 'player/views/elements-presentations/sentence-presentation';
import { Text } from '@naan/primitives/text';
import { useMembershipContext } from '../membership-context';
import { usePlayerModel } from 'player/views/player-model-context';
import { VSpacer } from '@naan/primitives/spacer';
import { VStack } from '@naan/primitives/stack';
import { Word } from './word-element';
import __ from '@core/lib/localization';
import { scrollToCurrentLine } from 'study/views/fx/scrolling';

const SkipToSentenceConfirmationDialog = ({
  onDismiss,
  okAction,
}: {
  onDismiss: () => void;
  okAction: () => void;
}) => {
  const handleOkAndDismiss = React.useCallback(() => {
    onDismiss();
    okAction();
  }, [okAction, onDismiss]);

  return (
    <Dialog.Container open={true} onDismiss={onDismiss}>
      <Dialog.Heading>
        {__('Skip to sentence', 'player.skipToSentence')}
      </Dialog.Heading>
      <Dialog.Body>
        <VStack>
          <Text>
            {__(
              'This will mark all previous sentences as played',
              'player.thisWillMarkAllPrevious'
            )}
          </Text>
          <VSpacer size={1} />
        </VStack>
      </Dialog.Body>
      <Dialog.ButtonsContainer>
        <Dialog.Button
          presentation={'cancel'}
          label={__('Cancel', 'common.cancel')}
          data-test-id="cancelButton"
          onClick={onDismiss}
          tabIndex={-1}
        />
        <Dialog.Button
          presentation={'teal'}
          label={__('Skip forward', 'player.skipForward')}
          data-test-id="okButton"
          onClick={handleOkAndDismiss}
          autoFocus
        />
      </Dialog.ButtonsContainer>
    </Dialog.Container>
  );
};

export const Sentence = observer(({ node }: { node: ElementNode }) => {
  const model = usePlayerModel();
  const { lineMembershipReconciler: reconciler } = useMembershipContext();

  const { children } = node;
  const sentenceId = node.element.id;
  const sentenceElement = node.element as SentenceElement;
  const redactionMode = () => model.getSentenceRedactionMode(sentenceId);

  const handleSentenceClick = React.useCallback(() => {
    const isBeyond = model.sentenceIsBeyondFurthest(sentenceId);
    if (isBeyond) {
      if (!model.skipForwardAllowed) {
        return;
      }
      AppFactory.dialogPresenter.present(onDismiss => {
        return (
          <SkipToSentenceConfirmationDialog
            onDismiss={() => {
              onDismiss();
              /// @elliottjf, @frank this is not in the spec, but felt good to me as a cancel action
              scrollToCurrentLine();
            }}
            okAction={() => {
              model.sentenceSelect(sentenceId);
            }}
          />
        );
      });
    } else {
      model.sentenceSelect(sentenceId);
    }
  }, [model, sentenceId]);

  // const handleUnredaction = React.useCallback(() => {
  //   model.unredactCurrentSentence();
  // }, [model]);

  const childComponents = children.map((node: ElementNode) => (
    <Word key={node.element.id} node={node} redaction={redactionMode} />
  ));

  // let className = reconciler.getJoinedMembershipStringForElement(sentenceId);
  // const memberships = reconciler.getMembershipForElement(sentenceId);
  // if (model.translationsShown) {
  //   memberships.push('show-translations');
  // }
  // const className = memberships.join(' ');

  let className = reconciler.getJoinedMembershipStringForElement(sentenceId);
  if (model.translationsShown) {
    className += ' show-translations'; // beware leading space
  }

  const elementDomId = elementIdToDomId(null, sentenceId);

  return (
    <StyledSentence
      children={childComponents}
      id={elementDomId}
      className={className}
      onClick={handleSentenceClick}
      // onUnredact={handleUnredaction}
      translation={sentenceElement.translation}
    />
  );
});
