import * as React from 'react';
import { styled } from '@naan/stitches.config';
import { Button } from '@naan/primitives/button';
import { ClockIcon } from '@naan/icons/clock-icon';
import { Story } from '@core/models/story-manager';
import { StoryStatus } from '@core/models/user-manager/story-progress';
import { CheckmarkIcon } from '@naan/icons/checkmark-icon';
import { HSpacer } from '@naan/primitives/spacer';
import { Responsive } from '@naan/primitives/responsive';
import { observer } from 'mobx-react';

const StoryActionStyles = styled('div', {
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
  padding: '$space$4',
  '@medium': {
    justifyContent: 'flex-start',
  },
});

type ButtonProps = React.ComponentProps<typeof Button>;

export const StoryAction = observer(({ story }: { story: Story }) => {
  const { progress } = story;

  const ActionButton = (props: ButtonProps) => (
    <Responsive
      renderDefault={() => <Button css={{ flex: 1 }} {...props} />}
      renderMediumAndUp={() => <Button {...props} />}
    />
  );
  if (progress.unstarted) {
    return (
      <StoryActionStyles>
        <ActionButton
          label="Begin studying"
          presentation={'teal'}
          size="large"
          onClick={() => progress.setStoryStatus(StoryStatus.STARTED)}
        />
        <HSpacer size={2} />
        <ActionButton
          label="Study later"
          presentation={'secondary'}
          size="large"
          leftIcon={progress.unqueued ? <ClockIcon /> : <CheckmarkIcon />}
          onClick={() =>
            progress.setStoryStatus(
              progress.unqueued ? StoryStatus.QUEUED : StoryStatus.UNQUEUED
            )
          }
        />
      </StoryActionStyles>
    );
  }
  return null;
});
