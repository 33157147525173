import * as React from 'react';

// import { Button } from '@naan/primitives/button';
// import { VocabIcon } from '@naan/icons/vocab-icon';
import { styled } from '@naan/stitches.config';
import { observer } from 'mobx-react';
import { AppFactory } from 'app/app-factory';
import { track } from 'app/track';
import { AnswerState } from 'soundbite/models/soundbite-model';
import { getConfig } from 'config';
// import { PlainMarkdown } from '@naan/primitives/text';
// import __ from '@core/lib/localization';

export const FINAL_CARD_ID = 'final-card;';

const Wrapper = styled('div', {
  padding: '40px 16px 48px 16px',
  maxWidth: 600,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  margin: '0 auto',
  '& h2': {
    textStyle: 'body-bold',
    color: '$textPrimary',
    textAlign: 'center',
  },
  '& p': {
    color: '$textSecondary',
    textAlign: 'center',
    '& a': {
      color: '$teal-500',
    },
  },
});

const trackFormUrlClicked = () => {
  track('soundbite__form_url_clicked');
};

export const SbFinalCard = observer(() => {
  const { answerState: state } = AppFactory.soundbiteModel;
  const formUrl = getConfig('soundbites.formUrl');

  if (state !== AnswerState.revealed) {
    return null;
  }

  /// This is the actual design, uncomment it when we release
  // return (
  //   <Wrapper>
  //     <div>
  //       <Button
  //         leftIcon={<VocabIcon />}
  //         presentation="teal"
  //         label={__('Give feedback', 'soundbite.giveFeedback')}
  //         size="large"
  //         onClick={() => window.open('https://forms.gle/8Q5Z7Z1Z1Z1Z1Z1Z9')}
  //       />
  //     </div>
  //     <p>Thanks for trying Soundbites</p>
  //   </Wrapper>
  // );
  return (
    <Wrapper id={FINAL_CARD_ID}>
      <h2>Want more Lupa Soundbites?</h2>
      <p>
        <a
          target="_blank"
          href={formUrl}
          onClick={trackFormUrlClicked}
          rel="noreferrer"
        >
          Apply to join our beta test
        </a>{' '}
        starting soon
      </p>
      {/* <p>Thanks for trying Soundbites</p> */}
    </Wrapper>
  );
});
