import { searchSorted } from './search-sorted';
// import { createLogger } from 'app/logger';

// const log = createLogger('intervals');

// TODO move to separate module for Interval stuff
export type Interval = {
  begin: number;
  end: number;
};

// TODO consider using 'Index' as an alias for 'number' for the interfaces here
// type Index = number;

// TODO move NO_INDEX to some generic constants module?
export const NO_INDEX = -1;

export function size(interval: Interval): number {
  return interval.end - interval.begin + 1;
}

export function midPoint(interval: Interval): number {
  return (interval.begin + interval.end) >> 1;
}

export function isPoint(interval: Interval): boolean {
  return interval.end === NO_INDEX;
}

export function intervalsToStartEndPoints(intervals: Interval[]) {
  const startPoints = intervals.map(interval => interval.begin);
  const endPoints = intervals.map(interval => interval.end);
  return { startPoints, endPoints };
}

export class Intervals {
  startPoints: number[];
  endPoints: number[];
  domainStart: number;
  domainEnd: number;
  _midPoints: number[] = null;

  constructor(
    startPoints: number[] = [],
    endPoints: number[] = [],
    domainStart = 0,
    domainEnd = 0
  ) {
    this.startPoints = startPoints;
    this.endPoints = endPoints;
    this.domainStart = domainStart;
    this.domainEnd = domainEnd;
  }

  checkValidIndex(idx: number): boolean {
    return idx >= 0 && idx < this.startPoints.length;
  }

  getValidIdx(idx: number): number {
    if (this.checkValidIndex(idx)) {
      return idx;
    } else {
      return NO_INDEX;
    }
  }

  getValidInterval(startIdx: number, endIdx: number): Interval | null {
    if (startIdx < 0 || endIdx < 0) {
      return null;
    } else if (startIdx > endIdx) {
      return null;
    } else {
      return { begin: startIdx, end: endIdx };
    }
  }

  get length(): number {
    return this.startPoints.length;
  }

  intervalAt(idx: number): Interval {
    if (this.endPoints) {
      return { begin: this.startPoints[idx], end: this.endPoints[idx] };
    } else {
      return { begin: this.startPoints[idx], end: NO_INDEX };
    }
  }

  pointAt(idx: number): Interval {
    // TODO force to a point style interval?
    return this.intervalAt(idx);
  }

  asIntervals(): Interval[] {
    // seq { for i in 0 .. (startPoints.Length - 1) -> intervalAt(i) }
    return this.startPoints.map((_point, index) => this.intervalAt(index));
  }

  get midPoints(): number[] {
    if (this._midPoints) {
      return this._midPoints;
    } else {
      const intervals = this.asIntervals();
      this._midPoints = intervals.map(midPoint);
      return this._midPoints;
    }
  }

  fromStartPoints(): Intervals {
    return new Intervals(
      this.startPoints,
      null,
      this.domainStart,
      this.domainEnd
    );
  }

  fromEndPoints(): Intervals {
    return new Intervals(
      this.endPoints,
      null,
      this.domainStart,
      this.domainEnd
    );
  }

  fromMidPoints(): Intervals {
    return new Intervals(
      this.midPoints,
      null,
      this.domainStart,
      this.domainEnd
    );
  }

  fromConvertToIntervals(domainStart: number, domainEnd: number) {
    const startPoints = [];
    const endPoints = [];
    startPoints.push(domainStart);
    for (const val of this.startPoints) {
      endPoints.push(val);
      startPoints.push(val);
    }
    endPoints.push(domainEnd);
    return new Intervals(startPoints, endPoints);
  }

  lastStartsBeforeOrAt(value: number): number {
    const idx = searchSorted(this.startPoints, value);
    // idx will be the index of the first interval with start value after input value
    // change to preceding interval which must have start value less than or equal input value
    return this.getValidIdx(idx - 1);
  }

  lastBeforeOrAt(value: number): number {
    return this.lastStartsBeforeOrAt(value);
  }

  doesStartBeforeOrAt(idx: number, value: number): boolean {
    return this.startPoints[idx] <= value;
  }

  firstStartsAfterOrAt(value: number): number {
    const idx = searchSorted(this.startPoints, value, false);
    return this.getValidIdx(idx);
  }

  firstStartsAfter(value: number): number {
    const idx = searchSorted(this.startPoints, value);
    return this.getValidIdx(idx);
  }

  firstAfter(value: number): number {
    return this.firstStartsAfter(value);
  }

  lastEndsBeforeOrAt(value: number): number {
    if (!this.endPoints) {
      return this.lastStartsBeforeOrAt(value);
    }
    const idx = searchSorted(this.endPoints, value);
    // idx will be the index of the first interval with end value after input value
    // change to preceding interval which must have end value less than or equal input value
    return this.getValidIdx(idx - 1);
  }

  firstEndsAfter(value: number): number {
    const idx = searchSorted(this.endPoints, value);
    // idx will be the index of the first interval with end value after input value
    return this.getValidIdx(idx);
  }

  firstEndsAfterOrAt(value: number): number {
    const idx = searchSorted(this.endPoints, value, false);
    return this.getValidIdx(idx);
  }

  startsAt(value: number): number {
    const idx = this.lastStartsBeforeOrAt(value);
    if (idx !== NO_INDEX && this.startPoints[idx] === value) {
      return idx;
    } else {
      return NO_INDEX;
    }
  }

  endsAt(value: number): number {
    const idx = this.lastEndsBeforeOrAt(value);
    if (idx !== NO_INDEX && this.endPoints[idx] === value) {
      return idx;
    } else {
      return NO_INDEX;
    }
  }

  containing(value: number): number {
    const idx = this.lastStartsBeforeOrAt(value);
    if (!this.endPoints) {
      return idx;
    } else if (idx >= 0 && value <= this.endPoints[idx]) {
      return idx;
    } else {
      return NO_INDEX;
    }
  }

  doesContain(idx: number, value: number): boolean {
    if (idx === NO_INDEX) {
      if (this.containing(value) === NO_INDEX) {
        return true;
      }
      return false;
    } else {
      if (this.endPoints) {
        return this.startPoints[idx] <= value && this.endPoints[idx] >= value;
      } else {
        return (
          this.startPoints[idx] <= value && this.startPoints[idx + 1] > value
        );
      }
    }
  }

  rangeContained(startValue: number, endValue: number): Interval {
    const startIdx = this.firstStartsAfterOrAt(startValue);
    const endIdx = this.lastEndsBeforeOrAt(endValue);
    return this.getValidInterval(startIdx, endIdx);
  }

  hasContained(startValue: number, endValue: number): boolean {
    return !!this.rangeContained(startValue, endValue);
  }

  hasInterval(startValue: number, endValue: number) {
    const idx = this.startsAt(startValue);
    if (idx !== NO_INDEX) {
      return this.endPoints[idx] === endValue;
    } else {
      return false;
    }
  }

  rangeIntersecting(startValue: number, endValue: number): Interval {
    const startIdx = this.firstEndsAfterOrAt(startValue);
    const endIdx = this.lastStartsBeforeOrAt(endValue);
    return this.getValidInterval(startIdx, endIdx);
  }

  hasIntersecting(startValue: number, endValue: number): boolean {
    return !!this.rangeIntersecting(startValue, endValue);
  }

  rangeStartsWithin(startValue: number, endValue: number): Interval {
    const startIdx = this.firstStartsAfterOrAt(startValue);
    const endIdx = this.lastStartsBeforeOrAt(endValue);
    return this.getValidInterval(startIdx, endIdx);
  }

  hasStartsWithin(startValue: number, endValue: number): boolean {
    return !!this.rangeStartsWithin(startValue, endValue);
  }

  retrieveRange(startIdx: number, endIdx: number): Interval[] {
    let result: Interval[] = [];
    for (let i = startIdx; i <= endIdx; i++) {
      result.push(this.intervalAt(i));
    }
    return result;
  }

  valueBounds(startIdx: number, endIdx: number) {
    return { begin: this.startPoints[startIdx], end: this.endPoints[endIdx] };
  }

  translateStartPointsToValues(startIndexes: number[]): number[] {
    if (!startIndexes) {
      return [];
    }
    return startIndexes.map(idx => this.startPoints[idx]);
  }

  translateEndPointsToValues(endIndexes: number[]): number[] {
    // TODO think end point handling through
    // TODO really [|for idx in endIndexes -> endPoints.[idx - 1]|]??
    return endIndexes.map(idx => this.endPoints[idx]);
  }

  translate(startIndexes: number[], endIndexes: number[]): Intervals {
    return null; // TODO
    // IntervalsFactory.Make(translateStartPointsToValues(startIndexes),
    //                  translateEndPointsToValues(endIndexes))
  }

  // TODO rename
  mapRangesContained(intervals: Interval[]): Interval[] {
    let result: Interval[] = [];
    for (const interval of intervals) {
      let range = this.rangeContained(interval.begin, interval.end);
      if (range) {
        // TODO is this is correct (inclusive) then don't need if statement at all
        result.push({ begin: range.begin, end: range.end });
        // TODO really think about exclusive/inclusive  { begin = result.begin; end = result.end + 1 }
      } else {
        result.push(null);
      }
    }
    return result;
  }

  getPriorGapInterval(idx: number) {
    // JE: 22.09.30 adjusting begin/end inward by 1ms to avoid simultanenous highlight of gap and adjacent words
    // the gap interval needs to be disjoint from the surrounding words using inclusive boundaries
    const begin = !idx ? 0 : this.endPoints[idx - 1] + 1;
    let end = this.startPoints[idx] - 1;
    if (end < begin) {
      // eslint-disable-next-line no-console
      console.warn(`negative length gap (${begin}, ${end}) detected`);
      end = begin;
    }
    return { begin, end };
  }

  getGapIntervalIndexPairs(minSize: number): [Interval, number][] {
    const gapStarts = this.endPoints;
    const gapEnds = this.startPoints;
    const gapIntervals: [Interval, number][] = [];
    const firstInterval = { begin: this.domainStart, end: this.startPoints[0] };
    if (size(firstInterval) > minSize) {
      gapIntervals.push([firstInterval, 0]);
    }

    for (const [i, begin] of gapStarts.entries()) {
      const interval = { begin: begin + 1, end: gapEnds[i + 1] - 1 };
      if (size(interval) > minSize) {
        gapIntervals.push([interval, i + 1]);
      }
    }
    return gapIntervals;
  }

  fromGapIntervals(minSize: number): Intervals {
    const gapStarts = this.endPoints;
    const gapEnds = this.startPoints;
    const gapIntervals: Interval[] = [];
    const firstInterval = { begin: this.domainStart, end: this.startPoints[0] };
    if (size(firstInterval) > minSize) {
      gapIntervals.push(firstInterval);
    }

    for (const [i, begin] of gapStarts.entries()) {
      const interval = { begin: begin + 1, end: gapEnds[i + 1] - 1 };
      if (size(interval) > minSize) {
        gapIntervals.push(interval);
      }
    }
    // TODO add last interval
    return fromIntervals(gapIntervals);
  }

  // yields a projection of the interval data with a minimum size suitable for the chaat UI
  fromExpandedIntervals(expandSize: number, direction: number): Intervals {
    const intervals = this.asIntervals();
    const resultIntervals: Interval[] = [];
    for (const interval of intervals) {
      if (size(interval) >= expandSize) {
        resultIntervals.push(interval);
      } else {
        // TODO use direction, create separate func which operates on intervals takes direction and size
        const end = interval.end;
        resultIntervals.push({ begin: end - expandSize, end });
      }
    }
    return fromIntervals(intervals);
  }

  openTransitions() {
    return this.startPoints;
  }

  // used by ClientPlayer to correctly handle end of audio state
  get finalStartPoint(): number {
    return this.startPoints[this.startPoints.length - 1];
  }
}

// TODO make static on the class? but class has different name?
export function fromIntervals(intervals: Interval[]): Intervals {
  const points = intervalsToStartEndPoints(intervals);
  return new Intervals(points.startPoints, points.endPoints, 0, 0);
}

export const EmptyIntervals = new Intervals();
