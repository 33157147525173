import React from 'react';
import { SoundbiteUI } from '../../soundbite/views/soundbite-ui';
import { ExcerptData } from '@tikka/client/catalog-types';
import { AppFactory } from 'app/app-factory';
import { getConfig } from 'app/env';
import { LoadingStatus } from '../../player/models/player-model';
import { createLogger } from 'app/logger';
import { track } from 'app/track';
import { reaction } from 'mobx';
import { FullScreenLoader } from 'components/ds/modals';
import { useLocation } from 'react-router-dom';
import { getQueryParamsFromLocation } from '@common/object-from-url-query';

const log = createLogger('soundbite-screen');

export const updateSettings = () => {
  const model = AppFactory.soundbiteModel;

  const { userManager } = AppFactory.root;
  const { playerSettings } = userManager.userData;

  const newSettings = {
    playbackRate: model.player.playbackRate,
    redactionMode: model.redactionMode,
    introShown: playerSettings.introShown, // confirm best way to leave this untouched
  };

  if (playerSettings.isDifferent(newSettings)) {
    log.info('updating playerSettings');
    playerSettings.setAll(newSettings);
    userManager.persistUserData();
  } else {
    log.info('playerSettings unchanged');
  }
};

export const SoundbiteScreen = () => {
  // url param used for admin preview screen
  const location = useLocation();
  let { url } = getQueryParamsFromLocation(location);

  if (!url) {
    // default URL used for demo deployment
    url = getConfig('soundbites.demoDataUrl');
  }
  log.info(`url: ${url}`);

  const [dataLoaded, setDataLoaded] = React.useState<boolean>(false);

  // eslint-disable-next-line
  React.useEffect(() => {
    track('soundbite__page_opened'); // redundant with generic page tracking, but looks easier to explore event data vs page view date

    const model = AppFactory.playerModelHandle.newSoundbiteModel();
    model.setStatus(LoadingStatus.LOADING);
    fetch(url)
      .then(response => response.json() as Promise<ExcerptData>)
      .then(data => model.initFromData(data))
      .then(() => {
        // todo: better factor this
        const { playerSettings } = AppFactory.root.userManager.userData;
        model.player.setPlaybackRate(playerSettings.playbackRate);
        model.setRedactionMode(playerSettings.redactionMode);
        model.setOnloadModalNeeded(!playerSettings.introShown);
        model.setReady(); // shouldn't be needed now
        setDataLoaded(true);
      });

    // automatically persist settings if changed when end reached
    reaction(
      () => model.afterNotionalCompletion,
      atEnd => {
        log.debug(`notional completion reaction - atEnd: ${atEnd}`);
        if (atEnd) {
          updateSettings();
        }
      }
    );
    // return () => model.close();
  }, [url]);

  if (!dataLoaded) {
    return <FullScreenLoader />;
  }

  const model = AppFactory.soundbiteModel;
  if (!model.story) {
    return <h2>missing story data</h2>;
  }
  return <SoundbiteUI />;
};
