function keynameForEvent(event: KeyboardEvent) {
  const keynameParts = [];

  if (event.altKey) {
    keynameParts.push('alt');
  }

  if (event.shiftKey) {
    keynameParts.push('shift');
  }

  if (event.ctrlKey) {
    keynameParts.push('ctrl');
  }

  if (event.metaKey) {
    keynameParts.push('meta');
  }

  keynameParts.push(
    event.code
      .replace(/^Key/, '')
      .replace(/^Arrow/, '')
      .replace(/^Digit/, '')
  );

  const keyname = keynameParts.join('+').toLowerCase();

  return keyname;
}

type KeyboardShortcutsSet = Record<string, Function>;

class KeyboardService {
  private shortcutSets: Map<string, KeyboardShortcutsSet> = new Map();
  private currentShortcutSetKey: string | null = null;
  private listening = false;

  constructor() {
    this.startListening();
  }

  startListening() {
    document.addEventListener('keydown', this.handleKeyDown);
    this.listening = true;
  }

  stopListening() {
    document.removeEventListener('keydown', this.handleKeyDown);
    this.listening = false;
  }

  handleKeyDown = (event: KeyboardEvent) => {
    const keyname = keynameForEvent(event);
    if (this.currentShortcutSetKey) {
      const shortcutSet = this.shortcutSets.get(this.currentShortcutSetKey);
      const shortcut = shortcutSet[keyname];

      if (typeof shortcut === 'function') {
        event.preventDefault();
        shortcut();
      }
    }
  };

  addShortcutSet(key: string, shortcuts: KeyboardShortcutsSet) {
    this.shortcutSets.set(key, shortcuts);
  }

  removeShortcutSet(key: string) {
    this.shortcutSets.delete(key);
    if (this.currentShortcutSetKey === key) {
      this.currentShortcutSetKey = null;
    }
  }

  setCurrentShortcutSet(key: string | null) {
    this.currentShortcutSetKey = key;
  }

  stopCurrentShortcutSet() {
    const oldSetKey = this.currentShortcutSetKey;
    this.currentShortcutSetKey = null;
    const reset = () => {
      this.currentShortcutSetKey = oldSetKey;
    };
    return reset;
  }
}

export const keyboardService = new KeyboardService();

(window as any).ks = keyboardService;
