import * as React from 'react';
import { observer } from 'mobx-react';
import { ElementNode } from '@tikka/client/client-aliases';
import { SpeakerLabelPresentation } from 'player/views/elements-presentations/speaker-label-presentation';
import { Paragraph } from '@tikka/client/client-aliases';
import { usePlayerModel } from 'player/views/player-model-context';
import { useMembershipContext } from '../membership-context';
import { elementIdToDomId } from '@tikka/elements/element-id-utils';
import { isEmptyOrNA } from '@utils/string-utils';

export const SpeakerLabel = observer(({ node }: { node: ElementNode }) => {
  const model = usePlayerModel();
  const { lineMembershipReconciler: reconciler } = useMembershipContext();

  const element = node.element as Paragraph;
  const id = element.id;

  const className = reconciler.getJoinedMembershipStringForElement(id);
  const elementDomId = elementIdToDomId(null, id);

  const label = element.speakerLabel;
  if (!label) {
    return null;
  }

  let speaker = model.resolveSpeaker(label);

  // not sure if we need client-side awareness of 'n/a' bios, but checking here out of paranoia
  if (isEmptyOrNA(speaker?.bio)) {
    speaker = null;
  }

  return (
    <SpeakerLabelPresentation
      id={elementDomId}
      label={label}
      speaker={speaker}
      className={className}
    />
  );
});
