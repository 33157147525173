import React from 'react';
import { observer } from 'mobx-react';
import { NavLink } from 'react-router-dom';
import useSWR from 'swr';
import { ExcerptData } from '@tikka/client/catalog-types';
// import { createLogger } from 'app/logger';
import { getConfig } from 'app/env';
import { A } from '@common/ui/action-link';

// const log = createLogger('list-screen');

const listEndpoint = getConfig('soundbites.listEndpoint');
// log.info(`listEndpoint: ${listEndpoint}`);

const fetcher = (url: string): any => fetch(url).then(res => res.json());

export const ExcerptListScreen = observer(() => {
  let { data, error } = useSWR(listEndpoint, fetcher);

  if (data === undefined && !error) {
    return <div className="App">loading...</div>;
  }

  if (error) {
    return <div className="App">{String(error)}</div>;
  }

  const list = data as ExcerptData[];

  return (
    <div className="App">
      <header className="App-header">
        <h1 className="App-title">soundbites</h1>
      </header>
      <div className="content">
        <ul>
          {list.map(excerpt => (
            <li key={excerpt.slug}>
              <NavLink to={`../preview?url=${excerpt.dataUrl}`}>
                {excerpt.title}
              </NavLink>
              {' - '}
              <A href={excerpt.dataUrl}>data</A>
              {' - '}
              {excerpt.ingestedAt}
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
});
