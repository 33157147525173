import * as React from 'react';

import { styled } from '@naan/stitches.config';
import { ArrowLeftIcon } from '@naan/icons/arrow-left-icon';
import { IconButton } from '@naan/primitives/button';
import { Responsive } from '@naan/primitives/responsive';
import { StoryStatusBar } from 'components/story-status/story-status-bar';
import { Story } from '@core/models/story-manager/story';
import { observer } from 'mobx-react';

const BackButton = ({ backAction }: { backAction: () => void }) => (
  <IconButton
    presentation={'whiteTransparent'}
    icon={<ArrowLeftIcon />}
    size={'large'}
    onClick={backAction}
  />
);

const HeaderContainer = styled('div', {
  display: 'flex',
  flex: 1,
  flexDirection: 'row',
  height: '56px',
  alignItems: 'center',
  width: '100%',
  justifyContent: 'space-between',
  '& .title': {
    flex: 1,
    color: '$white',
    textAlign: 'center',
    textStyle: 'body-bold',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    '@medium': {
      textStyle: 'small-heading',
      textAlign: 'left',
      marginLeft: '22px',
    },
  },
  '& .right': {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
  },
  '& .overflowMenu': {
    alignSelf: 'center',
    marginRight: '$space$2',
  },
});

type StoryHeaderSimpleProps = {
  backAction: () => void;
  backgroundColor: string;
  title: React.ReactNode;
  showTitle: boolean;
  statusBar?: React.ReactNode;
  overflowMenu: React.ReactNode;
};

export const StoryHeaderSimple = ({
  backAction,
  backgroundColor = 'currentColor',
  title,
  showTitle,
  statusBar,
  overflowMenu,
}: StoryHeaderSimpleProps) => (
  <HeaderContainer css={{ backgroundColor }}>
    <BackButton backAction={backAction} />
    {showTitle ? <div className="title">{title}</div> : null}
    <div className="right">
      {statusBar ? <Responsive renderMediumAndUp={() => statusBar} /> : null}
      <div className="overflowMenu">{overflowMenu}</div>
    </div>
  </HeaderContainer>
);

type StoryHeaderProps = {
  story: Story;
  backAction: () => void;
  showTitle: boolean;
  overflowMenu: React.ReactNode;
};

export const StoryHeader = observer(
  ({ story, backAction, showTitle, overflowMenu }: StoryHeaderProps) => {
    const { themeColor, title, progress } = story;
    return (
      <StoryHeaderSimple
        backAction={backAction}
        backgroundColor={themeColor}
        title={title}
        showTitle={showTitle}
        statusBar={progress.started ? <StoryStatusBar story={story} /> : null}
        overflowMenu={overflowMenu}
      />
    );
  }
);
