import * as React from 'react';
import { observer } from 'mobx-react';
import { useParams } from 'react-router-dom';
import { AppFactory } from 'app/app-factory';
import { VStack } from 'naan/primitives/stack';
import { VSpacer } from 'naan/primitives/spacer';
import { IconButton } from 'naan/primitives/button';
import { ChapterListItem } from '../../story/chapter-list-item';
import { StoryVoicesCredits } from 'components/story-detail/story-voices-credits';
import { SelectedVocabs } from 'components/story-detail/selected-vocabs';
import { Story } from '@core/models/story-manager';
import { CenterColumnLayout } from 'components/layouts/center-column-layout';
import { styled } from '@naan/stitches.config';
import { StoryHeader } from 'story/story-header';
import { Menu, MenuItem } from '@naan/primitives/menus';
import { OverflowIcon } from '@naan/icons/overflow-icon';
import { StoryAction } from 'story/story-actions';
import { UnitCatalogData } from '@core/models/catalog';
import { VolumeTagLinks } from 'components/volume-layouts/volume-tag-links';

const StoryDetailCardWrapper = styled('div', {
  $$thumbSize: '192px',
  $padding: '16px',
  $$paddingTop: '56px',
  $$paddingBottom: '40px',

  padding: '$$padding',
  paddingTop: '$$paddingTop',
  paddingBottom: '$$paddingBottom',
  color: '$white',
  minHeight: 'calc($$thumbSize + $$paddingTop + $$paddingBottom)',

  '& .thumbnail': {
    display: 'flex',
    justifyContent: 'center',
    paddingTop: 8,
    paddingBottom: 12,
    // background: 'red',

    '& img': {
      width: '$$thumbSize',
      height: '$$thumbSize',
      aspectRatio: 1,
      borderRadius: 8,
      filter: 'drop-shadow(0px 1px 4px rgba(0, 0, 0, 0.2))',
    },
  },

  '& .text': {
    '& > header': {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      paddingBottom: '40px',
      '& > h1': {
        margin: 0,
        textStyle: 'medium-heading',
      },
      '& > h2': {
        margin: 0,
        textStyle: 'small-text',
      },
    },
    '& > .tagline': {
      textStyle: 'small-heading',
      marginBottom: '$space$3',
    },
  },

  '@small': {
    $$padding: '24px',
    position: 'relative',
    paddingRight: 'calc($$thumbSize + calc( $$padding * 2 ))',

    '& > .thumbnail': {
      position: 'absolute',
      right: '$$padding',
      top: '$$paddingTop',
    },

    '& > .text': {
      '& > header': {
        alignItems: 'flex-start',
      },
    },
  },

  '@extraLarge': {
    $$thumbSize: '304px',
  },
  '& .tags': {
    marginTop: '20px',
  },
});

const StoryDetailCard = ({ story }: { story: Story }) => {
  (window as any)._story = story;

  return (
    <CenterColumnLayout backgroundColor={story.themeColor}>
      <StoryDetailCardWrapper>
        <div className="thumbnail">
          <img src={story.listImageUrl} alt="" />
        </div>
        <div className="text">
          <header>
            <h1>
              <span className="todo story-accessory" />
              {story.baseTitle}
            </h1>
            <h2>{story.durationDescription}</h2>
          </header>
          <p className="tagline">{story.tagline}</p>
          {story.description}
        </div>
        <div className="tags">
          <VolumeTagLinks
            tags={story.allTags}
            linkPrefix="/stories"
            mode={'dark'}
          />
        </div>
      </StoryDetailCardWrapper>
    </CenterColumnLayout>
  );
};

const StoryDetailScreenStyles = styled('div', {
  position: 'relative',
  '& .storyHeader': {
    position: 'fixed',
    top: 0,
    right: 0,
    left: 0,
    '@large': {
      left: '$$sidebarWidth',
    },
    zIndex: 99,
  },
  '& .storyActions': {
    position: 'sticky',
    backgroundColor: '$white',
    bottom: 0,
  },
  '& .unitHeader': {
    textStyle: 'small-heading',
    marginBottom: '$space$4',
    marginTop: '$space$10',
  },
  '& .unitDuration': {
    textStyle: 'small-text',
    marginLeft: '$space$2',
  },
});

export const StoryDetailScreen = observer(() => {
  const { storyManager } = AppFactory.root;
  const { slug } = useParams<{ slug: string }>();
  const story = storyManager.story(slug);

  // loads async
  const volumeDataStory = storyManager.loadVolumeDataUrl(story.volumeDataUrl);

  const handleReset = () => {
    story.progress.resetStory();
  };

  if (!story) {
    return <>loading…</>;
  }

  const { progress } = story;

  const OverflowMenu = () => (
    <Menu
      trigger={
        <IconButton presentation="whiteTransparent" icon={<OverflowIcon />} />
      }
    >
      {progress.unstarted ? null : (
        <MenuItem label="Reset story" action={() => progress.resetStory()} />
      )}
      {progress.completed ? null : (
        <MenuItem
          label="Mark story complete."
          action={() => progress.markStoryComplete()}
        />
      )}
    </Menu>
  );

  const UnitHeader = ({ unit }: { unit: UnitCatalogData }) => (
    <div className="unitHeader">
      {unit.titleSuffix}
      <span className="unitDuration">{unit.durationDescription}</span>{' '}
    </div>
  );

  return (
    <StoryDetailScreenStyles>
      <div className="storyHeader">
        <StoryHeader
          story={story}
          backAction={() => {}}
          overflowMenu={<OverflowMenu />}
          showTitle={false}
        />
      </div>
      <StoryDetailCard story={story} />
      <VSpacer size={4} />
      <div className="storyActions">
        <StoryAction story={story} />
      </div>
      <VStack>
        {story.units.map(unit => (
          <>
            {story.multiUnit ? <UnitHeader unit={unit} /> : null}
            <div key={unit.slug}>
              {unit.chapters.map(chapter => (
                <ChapterListItem chapter={chapter} />
              ))}
            </div>
          </>
        ))}
        <VSpacer size={4} />
        <VSpacer size={10} />
        <StoryVoicesCredits story={story} />
        <VSpacer size={10} />
        <SelectedVocabs story={story} />
      </VStack>
    </StoryDetailScreenStyles>
  );
});
