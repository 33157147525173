import React, { useState } from 'react';
import { useSwitch } from '@naan/hooks/use-switch';
import { Text } from 'components/ds/common';
import { Button } from 'naan/primitives/button';
// import { Prompt } from 'components/ds/modals';
import { MarkdownContainer } from 'components/ds/common/markdown-container';
import { AlertDialog, Alert, Prompt } from 'naan/primitives/modals';

import styled from 'styled-components';

const Gap = styled.div`
  width: 100%;
  height: 40px;
`;

const Demo = ({ label, children }) => {
  const [showing, { toggle }] = useSwitch(false);

  return (
    <>
      <Button label={label} onClick={toggle} />
      {showing ? children(toggle) : null}
    </>
  );
};

const PromptDemo = ({ type = 'text' }) => {
  const [value, setValue] = useState(null);
  return (
    <>
      <Demo label={'Prompt demo'}>
        {toggle => (
          <Prompt
            label="What's your name?"
            initialValue={'initial value'}
            placeholder={'e.g. Alerto McDialog'}
            okButtonLabel={'Set name'}
            onDismiss={toggle}
            okButtonAction={setValue}
            type={type}
          />
        )}
      </Demo>
      {!!value && <Text>You entered: {value}</Text>}
    </>
  );
};

const markdown = `Most stories aren't accessible in the Lupa app with a trial account.
Students can purchase their own subscription by visiting my.lupa.app on the web,
or from inside the app.

Alternatively you may purchase a multi-seat license for your class.
For pricing email [edu-sales@jiveworld.com](mailto:edu-sales@jiveworld.com)`;

export const pathname = '/modals';
export const navlabel = 'Modals';
export const naanReady = true;

export const Screen = () => {
  return (
    <>
      <Demo label={'Dialog Alert'}>
        {toggle => (
          <AlertDialog
            title="Demo alert"
            body={<MarkdownContainer source={markdown} />}
            onDismiss={toggle}
            okButtonAction={toggle}
            okButtonLabel={'OK'}
            showCancelButton={false}
            cancelButtonLabel={'Cancel'}
          />
        )}
      </Demo>

      {/* <Demo label={'Modal'}>
        {toggle => <Modal onDismiss={toggle}>Press ESC</Modal>}
      </Demo>
      <Gap /> */}

      <Demo label={'Alert'}>
        {toggle => (
          <Alert
            title="Demo alert"
            text={<MarkdownContainer source={markdown} />}
            action={toggle}
            onDismiss={toggle}
          />
        )}
      </Demo>
      <Gap />
      <PromptDemo />
      <Gap />
      <PromptDemo type={'textarea'} />
      {/* <Window></Window> */}
    </>
  );
};
