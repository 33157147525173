import { runInAction } from 'mobx';
import fetch from 'cross-fetch';
import { groupBy, uniq, flatten } from 'lodash';
import moment from 'moment';

import {
  applySnapshot,
  Bindery,
  ModelTreeNode,
  snap,
  volatile,
} from 'ts-state-tree/tst-core';
import { createLogger } from 'app/logger';

import { Story } from './story';
import { Catalog, UnitCatalogData } from '../catalog';
import {
  TFilter,
  TSortingCallback,
  TSortingDescription,
  filterList,
  filterTypes,
} from 'common/filtering-core';
import { FeaturedRelease } from '../catalog/featured-release';
import { Root } from '../root';
import { sortBy, SORT_ORDER } from 'utils/util';
import {
  CLASSROOM_FILTER_KEY_PREFIX,
  storySortKeys,
} from 'core/lib/constants/vars';
import invariant from 'core/lib/invariant';
import filterNewStories from 'core/lib/filter-new-stories';
import { getBaseRoot } from '../base-root';
// import { computedFn } from 'mobx-utils';

const log = createLogger('story-manager');

const sortByTitle = sortBy('title');
const sortByDuration = sortBy('durationMinutes');
// switch to millis once schema update propagated to local store
// const sortByDuration = sortBy('catalogData.durationMillis');

// const sortByDownloadedAt = sortBy('download.downloadedAt'); // date sort

// beware this below are probably doing a string sorts, not date sorts
const sortByMostRecent = sortBy('releaseDate', SORT_ORDER.DESC);
const sortByOriginalBroadcastDate = sortBy('originalBroadcastDate');
const sortByAssignmentDueDate = sortBy('assignment.dueDate');
const sortByLastListened = sortBy('progress.lastListened', SORT_ORDER.DESC);

// use to sort lupa feature release
const sortByReleaseDate = sortBy('releaseDate', SORT_ORDER.DESC);

const STORY_SHORT_LIST_LIMIT = 2;

type SectionKey = keyof UnitCatalogData | 'all';

/**
 * ApplyMetadata + create and object with the key provide
 *
 * @param {array} collection
 * @param {object} metadata
 * @param {function} sortFn
 * @param {string} key
 * @returns {object}
 */
const sectionize = (
  collection: Story[],
  sortFn: (collection: Story[]) => any,
  sectionKey: SectionKey
) => {
  if ((sectionKey as string) === 'all') {
    return [{ title: 'all', data: sortFn(collection) }];
  }

  const key = sectionKey as keyof UnitCatalogData;

  const sectionized = collection.reduce((acc: any, current) => {
    current.catalogData[key].forEach((item: string | number) => {
      if (Array.isArray(acc[item])) {
        acc[item].push(current);
      } else {
        acc[item] = [current];
      }
    });

    return acc;
  }, {});
  return Object.keys(sectionized)
    .sort()
    .map(_key => ({
      title: _key,
      data: sortFn(sectionized[_key]),
    }));
};

// Note, this map is a peer of storySortLabels, using the same storySortKeys
export const storySortParams = {
  assignmentDueDate: { sortFn: sortByAssignmentDueDate, sectionKey: 'all' },
  mostRecent: { sortFn: sortByMostRecent, sectionKey: 'all' },
  title: { sortFn: sortByTitle, sectionKey: 'all' },
  duration: { sortFn: sortByDuration, sectionKey: 'all' },
  country: { sortFn: sortByMostRecent, sectionKey: 'countries' },
  topic: { sortFn: sortByMostRecent, sectionKey: 'topics' },
  originalBroadcast: { sortFn: sortByOriginalBroadcastDate, sectionKey: 'all' },
  lastListened: { sortFn: sortByLastListened, sectionKey: 'all' },
};

const applySort = (
  collection: Story[],
  sortKey: keyof typeof storySortParams
) => {
  const params = storySortParams[sortKey];
  return sectionize(collection, params.sortFn, params.sectionKey as SectionKey);
};

const __currentDate = moment().format('YYYY-MM-DD');

// todo: rename to classroomStoryGroups
export const unitGroups = {
  FREE: 'FREE',
  WITH_GUIDES: 'WITH_GUIDES',
  PREMIUM: 'PREMIUM',
};

const unitGroupBy = (unit: Story) => {
  if (unit.trial === true) {
    return unitGroups.FREE;
  }
  if (unit.activityGuideUrl) {
    return unitGroups.WITH_GUIDES;
  }

  return unitGroups.PREMIUM;
};

// todo: rename to classroomStoryGrouped
const grouped = (collection: Story[]) => {
  return groupBy(collection, unitGroupBy);
};

// end-user story groups
export const storyGroups = {
  FREE: 'FREE',
  PREMIUM: 'PREMIUM',
};

const storyGroupBy = (story: Story) => {
  if (story.trial === true) {
    return unitGroups.FREE;
  }
  return unitGroups.PREMIUM;
};

const storyGrouped = (collection: Story[]) => {
  return groupBy(collection, storyGroupBy);
};

export class StoryManager extends ModelTreeNode {
  static CLASS_NAME = 'StoryManager' as const;

  static create(snapshot: any) {
    return super.create(StoryManager, snapshot) as StoryManager;
  }

  static bindModels(bindery: Bindery): void {
    bindery.bind(StoryManager);
    bindery.bind(Story);
  }

  catalogUrl: string = null;
  version: number = 0;
  catalog: Catalog = snap({});
  stories: Story[] = [];

  // @volatile
  // currentStory: Story = null;

  @volatile
  currentFilterKey: string = null; // null implies index view

  @volatile
  currentTextSearchValue: string = null;

  @volatile
  currentSortKey: string = storySortKeys.MOST_RECENT;

  @volatile
  currentDate: string = __currentDate;

  get root(): Root {
    return getBaseRoot(this);
  }

  get featuredReleases(): FeaturedRelease[] {
    return this.catalog.featuredReleases || [];
  }

  story(slug: string): Story {
    return this.stories.find(story => {
      return story.slug === slug;
    });
  }

  storyForUnitSlug(slug: string): Story {
    return this.stories.find(story => {
      return story.includesUnit(slug);
    });
  }

  // makes sure progress data instances exist within the UserData for all stories
  // in the current catalog
  ensureStoryProgresses() {
    log.debug(`ensureStoryProgresses`);
    const startMillis = Date.now();
    const { userData } = this.root.userManager;
    if (!userData) {
      log.info(`ensureStoryProgresses skipped - no userData`);
      return;
    }
    for (const story of this.stories) {
      userData.ensureStoryProgress(story.slug);
    }
    log.info(
      `ensureStoryProgresses complete - duration: ${Date.now() - startMillis}ms`
    );
  }

  async ensureCatalogUrl(url: string) {
    log.info(
      `entering ensureCatalogUrl(${url}) - current url: ${this.catalogUrl}`
    );
    if (url === this.catalogUrl) return;
    // try {
    // this.currentStory = null;
    const response = await fetch(url);
    const data = await response.json();
    // log.info('catalog data', JSON.stringify(data));
    runInAction(() => {
      this.mapInCatalogData(data);
      this.ensureStoryProgresses();
      this.catalogUrl = url;
    });
    // this.root.downloadManager.ensureAllCatalogAssets();
    // log.debug(
    //   `ensureCatalogUrl - success - story count: ${this.stories?.length}`
    // );
    // } catch (error) {
    //   console.log(
    //     `ensureCatalogUrl error: ${error}, stack: ${(error as Error).stack}`
    //   );
    //   // notifications.alertWarning(
    //   //   `Unable to load catalog data: ${url}, ${error}`
    //   // );
    //   log.error(`Unable to load catalog data: ${url}, ${error}`);
    // }
  }

  mapInCatalogData(data: Catalog) {
    applySnapshot(this.catalog, data);
    // todo: support desired api:
    //   mergeSnapshot(this, { catalog: data });
    this.resolveStories();
  }

  resolveStories() {
    const stories = this.root?.userManager?.webModeEnabled
      ? this.resolveVolumeStories()
      : this.resolveUnitStories();

    applySnapshot(this.stories, stories);
  }

  // loads a standalone volume data url
  // provides story level data for a soundbite and vocab lookup data needed for story detail screen
  async loadVolumeDataUrl(url: string): Promise<Story> {
    if (!url) {
      return null;
    }
    const response = await fetch(url);
    const data = await response.json();
    const { volume: volumeData, units } = data;
    return Story.create({ slug: volumeData.slug, units, volumeData });
  }

  get unitDataList(): UnitCatalogData[] {
    return this.catalog?.stories;
  }

  unitData(slug: string): UnitCatalogData {
    return this.unitDataList?.find(data => {
      return data.slug === slug;
    });
  }

  // volume focused catalog (Lupa web UI)
  resolveVolumeStories(): Story[] {
    const result = this.catalog.volumeList
      .map(volumeData => {
        const units = volumeData.unitSlugs.map(slug => this.unitData(slug));
        if (units.length === 0) {
          return null; // ignore empty volumes
        } else {
          // use the first unit's slug to reduce story progress churn/migration
          return Story.create({ slug: units[0].slug, units, volumeData });
        }
      })
      .filter(story => !!story);
    return result;
  }

  // unit focused catalog (Lupa legacy UI)
  resolveUnitStories(): Story[] {
    const result = this.catalog.stories.map(catalogData =>
      Story.create({ slug: catalogData.slug, units: [catalogData] })
    );
    return result;
  }

  get availableStories() {
    const { showFutureStories } = this.root.userManager.accountData;

    if (showFutureStories) {
      // for users with `showFutureStories` flag enabled, show everything
      return this.stories;
    } else {
      // for everybody else, suppress stories with a future release date
      return this.stories.filter(story => story.isReleased);
    }
  }

  filteredList(
    filter: TFilter,
    sorting?: TSortingDescription | TSortingCallback<Story>
  ) {
    const result = filterList(this.availableStories, filter, sorting);
    log.info(`filteredList - count: ${result?.length}`);
    return result;
  }

  get availableFeaturedReleases() {
    if (!this.featuredReleases) return null;

    // for users with `showFutureStories` flag enabled, show latest FR
    if (this.root?.userManager?.accountData?.showFutureStories) {
      return sortByReleaseDate(this.featuredReleases);
    } else {
      // for everybody else, suppress FRs with a future release date
      const releasedFeaturedReleases = this.featuredReleases.filter(
        entry => entry.isReleased
      );
      return sortByReleaseDate(releasedFeaturedReleases);
    }
  }

  get latestFeaturedRelease() {
    if (!this.availableFeaturedReleases) return null;
    return this.availableFeaturedReleases[0];
  }

  featuredRelease(slug: string) {
    return this.availableFeaturedReleases.find(fr => fr.slug === slug);
  }

  get countryList() {
    return uniq(flatten(this.all.map(story => story.countries))).sort();
  }

  get topicsList() {
    return uniq(flatten(this.all.map(story => story.topics))).sort();
  }

  get apTagsList() {
    return uniq(flatten(this.all.map(story => story.apTags))).sort();
  }

  get ibTagsList() {
    return uniq(flatten(this.all.map(story => story.ibTags))).sort();
  }

  // setCurrentStory(story: Story) {
  //   this.currentStory = story;
  // }

  setCurrentDate(currentDate: string = null) {
    if (currentDate === null) {
      currentDate = moment().format('YYYY-MM-DD');
    }
    this.currentDate = currentDate;
  }

  setCurrentFilterKey(filterKey: string) {
    this.setCurrentFilterParams({ filterKey });
  }

  setCurrentSortKey(sortKey: string) {
    this.setCurrentFilterParams({ sortKey });
  }

  setCurrentTextSearchValue(value: string) {
    this.currentTextSearchValue = value;
  }

  /**
   * Set filterKey and/or sortKey in a single operation
   * @param filterKey
   * @param sortKey
   */
  setCurrentFilterParams({ filterKey = undefined, sortKey = undefined }) {
    if (filterKey !== undefined) {
      this.currentFilterKey = filterKey;
    }
    if (sortKey !== undefined) {
      this.currentSortKey = sortKey;
    }
  }

  get all() {
    return this.availableStories;
  }

  get unplayed() {
    return this.availableStories.filter(story => {
      return story.progress.unplayed;
    });
  }

  get started() {
    const filtered = this.availableStories.filter(story => {
      // return story.progress?.inProgress;
      return story.progress.started;
    });
    return sortByLastListened(filtered);
  }

  get startedShortList() {
    return this.started.slice(0, STORY_SHORT_LIST_LIMIT);
  }

  get completed() {
    return this.availableStories.filter(story => {
      return story.progress.completed;
    });
  }

  get newThisWeek(): Story[] {
    const { storyManager } = this.root;
    if (!storyManager) return [];
    return filterNewStories(this.stories /* todo, storyManager.currentDate*/);
  }

  // todo: is this even needed?

  // get unreleased() {
  //   return this.filteredList([
  //     {
  //       key: 'isReleased',
  //       value: false,
  //     },
  //   ]);
  //   // return this.stories.filter(story => !story.isReleased);
  // }

  get availableToSubscribers() {
    const sortFn = (a: Story, b: Story) => {
      return a.releaseDate > b.releaseDate ? -1 : 1;
    };

    const rec = this.availableStories
      .filter(story => {
        return (
          // todo: better factor this with 'recommended'
          story.catalogData.headPart && // excludes part >= 2
          story.progress.unplayed &&
          !story.isNew
        );
      })
      .sort(sortFn);

    if (rec) {
      return rec.slice(0, 4);
    }

    return this.stories.sort(sortFn).slice(0, 6);
  }

  get trial() {
    return this.stories.filter(story => {
      return story.trial;
    });
  }

  get notTrial() {
    return this.stories.filter(story => {
      return !story.trial;
    });
  }

  get filterCounts() {
    return {
      all: this.all.length,
      unplayed: this.unplayed.length,
      inProgress: this.started.length,
      completed: this.completed.length,
    };
  }

  get dashboardTrialData() {
    return {
      showSampleStoryMessage: true, // todo: proper logic
      sampleStories: this.trial,
      // todo: break these out to their own view getters. i think consolidating here
      // might break some of the responsiveness of the dashboard view
      availableToSubscribers: this.availableToSubscribers,
    };
  }

  get dashboardFullAccessData() {
    return {
      newThisWeek: this.newThisWeek,
      inProgress: this.startedShortList,
      showInProgressOverflow: this.started.length > STORY_SHORT_LIST_LIMIT,
      // readyToPlay: this.readyToPlayShortList,
      // showReadyToPlayOverflow: this.readyToPlay.length > STORY_SHORT_LIST_LIMIT,
      // recommended: this.recommended, // todo: adapt to in progress count
    };
  }

  get currentSectionedStories() {
    const filtered = this.filter(this.currentFilterKey);
    return applySort(filtered, this.currentSortKey as any);
  }

  /**
   * number of stories matching the current filter
   */
  get currentFilterCount() {
    return this.filter(this.currentFilterKey).length;
  }

  get favoritedUnits() {
    return this.stories.filter(unit => unit.isClassroomFavorited);
  }

  filter(name: string) {
    name = name || 'all';
    if (name.startsWith(CLASSROOM_FILTER_KEY_PREFIX)) {
      const classroom =
        this.root.userManager.accountData.joinedClassroomForFilterKey(name);
      invariant(!!classroom, `classroom not found for filter key: ${name}`);
      if (classroom) {
        return classroom.stories;
      } else {
        return [];
      }
    }

    //Text search is not being applied to classroom.stories by design

    const list = (this as any)[name] ?? this.all;

    if (this.currentTextSearchValue) {
      const filter = {
        key: 'searchableText',
        value: [this.currentTextSearchValue.toLowerCase()],
        method: filterTypes.TEXT_SEARCH,
      };

      return filterList(list, filter as TFilter);
    }

    return list;
  }

  // todo: rename to classroomGroupedStories
  groupedUnits(filter: TFilter, sorting?: TSortingDescription) {
    return grouped(filterList(this.availableStories, filter, sorting));
  }

  groupedStories(filter: TFilter, sorting?: TSortingDescription) {
    return storyGrouped(filterList(this.availableStories, filter, sorting));
  }
}
