/* eslint-disable no-console */
import { getConfig } from 'app/env';
import { AnalyticsAdapter } from '../analytics-adapter';

export class SimulatedAnalytics extends AnalyticsAdapter {
  serviceName = 'SimulatedAnalytics';
  isEnabled = getConfig('analytics.enableSimulatedAnalytics', false);

  identify(userId: string) {
    console.group('analytics:identify');
    console.table({ userId });
    console.groupEnd();
  }

  track(eventName: string, data?: any) {
    console.group('analytics:trackEvent');
    console.log('event', eventName);
    console.table({ data });
    console.groupEnd();
  }

  page(pageName: string, data?: any) {
    console.group('analytics:page');
    console.log('page', pageName);
    console.table({ data });
    console.groupEnd();
  }
}
