import * as React from 'react';

import cx from 'classnames';
import { RedactionMode } from 'player/models/redaction-modes';
import { WordMembership } from '../../../player/models/word-membership';
import { WordElementProps } from './element-props';
import { Spacer } from './spacer';
import { styled } from 'naan/stitches.config';
import { stripTrailingPunctuation } from '@tikka/misc/string-utils';
import { paletteKey } from '../../../study/views/study-palette';

const WordWrapper = styled('div', {
  display: 'inline',
  lineHeight: '1.5em',
  backgroundColor: 'transparent',
  '&.selected': {
    backgroundColor: '#ffed8f',
  },
});

WordWrapper.displayName = 'WordWrapper';

const WordText = styled('span', {
  $$borderColor: 'transparent',
  display: 'inline-block',
  // color: '#b4d455',
  // borderBottom: '1.5px solid $$border-color)',
  '&.italic': {
    fontStyle: 'italic',
  },
});

WordText.displayName = 'WordText';

const RedactedOuter = styled('span', {
  $$redactedBg: paletteKey('$$redacted__backgroundColor_unvisited'),
  $$textColor: paletteKey('$$word__color_default'),
  display: 'inline-block',
  color: 'transparent',
  position: 'relative',
  background: 'transparent',

  '& > .word': {
    display: 'inline-block',
    color: '$$textColor',
    opacity: 0,
    userSelect: 'none',
    // transition: 'opacity .15s, color .2s',
    // borderBottom: '1.5px solid $$border-color)',
    '&.italic': {
      fontStyle: 'italic',
    },
    '&.sic': {
      fontStyle: 'italic',
      color: '$colors$textSecondary',
    },
  },

  '& > .redact-bar': {
    // transition: 'opacity 1s, background .2s,',
    opacity: 1,
    background: '$$redactedBg',
    position: 'absolute',
    height: '9px',
    width: '100%',
    left: '0px',
    borderRadius: '3px',
    top: '10px',
  },

  '&.show-word': {
    '& > .word': {
      opacity: 1,
    },
    '& > .redact-bar': {
      opacity: 0,
    },
  },

  '&.visited': {
    $$redactedBg: paletteKey('$$redacted__backgroundColor_visited'),
    $$textColor: '$colors$textPrimary',
  },

  '&.current': {
    $$redactedBg: paletteKey('$$redacted__backgroundColor_current'),
    $$textColor: '$colors$teal-500',
  },
});

export const WordPresentation = ({
  text,
  membership,
  redaction,
  sicStart = false,
  sicIntended = null,
}: WordElementProps) => {
  // let showWord = !!(membership & WordMembership.VISITED);

  // eng-2432: experimentally unredact unvisited words
  let showWord = true;

  if (sicStart) {
    text = '*' + text;
  }
  const withPunctuation = text;
  if (sicIntended) {
    text = stripTrailingPunctuation(text);
  }
  const trailing =
    text !== withPunctuation
      ? withPunctuation.slice(text.length, withPunctuation.length)
      : '';

  if (!(membership & WordMembership.SIC)) {
    if (redaction === RedactionMode.SHOW_NONE) {
      showWord = false;
    } else if (redaction === RedactionMode.SHOW_ALL) {
      // showWord already always set correctly
    } else if (redaction === RedactionMode.SHOW_SOME) {
      if (
        !(
          membership & WordMembership.NOTATION ||
          membership & WordMembership.TRICKY
        ) &&
        showWord
      ) {
        showWord = false;
      }
    }
  }
  if (membership & WordMembership.SELECTED_NOTATION) {
    showWord = true;
  }

  /// I don't like this pattern, but I don't want to refactor it right now.
  let SicContent = null;
  if (sicIntended) {
    SicContent = () => {
      const sicWords = sicIntended.split(/\s+/);
      sicWords[0] = '[' + sicWords[0];
      const sicWordsLast = sicWords.length - 1;
      sicWords[sicWordsLast] = sicWords[sicWordsLast] + ']' + trailing;
      // const sicFlags =
      //   (membership & WordMembership.VISITED) | WordMembership.SIC;
      return (
        <span>
          {sicWords.map((word: string) => {
            return (
              <WordWrapper>
                <RedactedOuter
                  className={cx({
                    visited: membership & WordMembership.VISITED,
                    current: membership & WordMembership.CURRENT,
                    'show-word': showWord,
                  })}
                >
                  <span className="redact-bar" />
                  <span className={'word sic'}>{word}</span>
                </RedactedOuter>
                <Spacer membership={0} />
              </WordWrapper>
            );
          })}
        </span>
      );
    };
  }

  return (
    <>
      <WordWrapper
        className={cx({
          selected: membership & WordMembership.SELECTED_NOTATION,
        })}
      >
        <RedactedOuter
          className={cx({
            visited: membership & WordMembership.VISITED,
            current: membership & WordMembership.CURRENT,
            'show-word': showWord,
          })}
        >
          <span className="redact-bar" />
          <span
            className={cx('word', {
              italic:
                membership & WordMembership.SIC ||
                membership & WordMembership.ITALIC,
            })}
            // style={{ '--border-color': resolveWordTheme(membership) }}
          >
            {text}
          </span>
        </RedactedOuter>
        <Spacer membership={membership} />
      </WordWrapper>
      {SicContent ? SicContent() : null}
    </>
  );
};
