import React from 'react';
import { observer } from 'mobx-react';
import { useTitle } from 'common/hooks/use-title';
import __ from 'core/lib/localization';

import { PointsProgressChart } from 'components/ui/points-progress-chart';

export const ProgressScreen = observer(() => {
  useTitle(__('My Progress', 'progress.my-progress'));

  return <PointsProgressChart />;
});
