import {
  ElementId,
  IdRange,
  IndexRange,
  NO_INDEX,
  IElement,
  IntervalIndexed,
  WordElementList,
  WordIdRange,
  WordId,
  ElListConstraints,
  WideElement,
  ELTOf,
  LooseElementList,
} from '../basic-types';
import { Interval, Intervals } from '../intervals/intervals';
import {
  isEmpty,
  isNil,
  isNumber,
  notEmpty,
  notNil,
} from '@utils/conditionals';
import { isKindOfId } from './element-id-utils';
import { internedKindLists } from './element-list';

export type IdToIndexMap = {
  [index in ElementId]: number;
};

type WordRecordsData = { getIndexActive: (id: ElementId) => number };

export interface ElementListDictionary {
  [index: string]: LooseElementList;
}

function _CreateList(elements: WideElement[], words: WordElementList) {
  return new GenericElementListImpl(
    elements,
    words
  ) as unknown as LooseElementList;
}

class GenericElementListImpl implements LooseElementList {
  values: WideElement[];
  words: WordElementList;
  wordRecordsData: WordRecordsData = null;
  _kindsSublists: Map<string | readonly any[], LooseElementList>;
  _idToIndexF: ((id: ElementId) => number) | null; // supersedes the idToIndexMap if provided
  _idToIndexMap: IdToIndexMap; // lazily populated from 'elements'
  _wordIntervals: Intervals; // array of word address start/end pairs
  _timeIntervals: Intervals; // array of word millis start/end pairs
  _startWordIntervals: Intervals;
  _startTimeIntervals: Intervals;

  constructor(values: WideElement[], words: WordElementList) {
    this.values = values;
    this.words = words;
    this._kindsSublists = new Map();
    this._wordIntervals = null;
    this._timeIntervals = null;
    this._startWordIntervals = null;
    this._startTimeIntervals = null;
  }

  setWordRecordsData(
    wordRecordData: WordRecordsData, // TODO decide if really need any type?
    idToIndexF?: (id: ElementId) => number
  ): void {
    this.wordRecordsData = wordRecordData;
    if (typeof idToIndexF !== 'undefined') {
      this._idToIndexF = idToIndexF;
    } else {
      this._idToIndexF = wordRecordData.getIndexActive;
    }
  }

  get idToIndexMap(): IdToIndexMap {
    if (this._idToIndexMap) {
      return this._idToIndexMap;
    } else {
      const result: IdToIndexMap = {};
      for (const [i, element] of this.values.entries()) {
        result[element.id] = i;
      }
      this._idToIndexMap = result;
      return this._idToIndexMap;
    }
  }

  getIndex(id: ElementId): number {
    if (this._idToIndexF) {
      return this._idToIndexF(id);
    } else {
      const result = this.idToIndexMap[id];
      if (isNumber(result)) {
        return result;
      } else {
        return NO_INDEX;
      }
    }
  }

  getId(index: number): ElementId {
    return this.values[index].id;
  }

  getElement(id: ElementId) {
    if (!id) {
      return null;
    }

    const index = this.getIndex(id);
    //JE: isNumber check here is unneeded, correct?
    if (index !== NO_INDEX && isNumber(index)) {
      return this.values[this.getIndex(id)];
    } else {
      return null;
    }
  }

  lookupElement(id: ElementId) {
    return this.getElement(id);
  }

  hasElement(id: ElementId): boolean {
    return !!this.getElement(id);
  }

  isEmpty() {
    return isEmpty(this.values);
  }

  notEmpty() {
    return notEmpty(this.values);
  }

  get wordIntervals(): Intervals {
    if (!this._wordIntervals) {
      const startPoints = [];
      const endPoints = [];
      let hasEndPoints = false;
      if (this.values.length > 0) {
        if (notNil(this.values[0].endAddress)) {
          hasEndPoints = true;
        }
      }
      for (const elem of this.values) {
        // TODO handle case of only start Word
        startPoints.push(elem.address);
        if (hasEndPoints) {
          endPoints.push(elem.endAddress);
        }
      }
      if (hasEndPoints) {
        this._wordIntervals = new Intervals(startPoints, endPoints);
      } else {
        // JE: could simplify by checking the length at the start
        if (startPoints.length > 0) {
          this._wordIntervals = new Intervals(startPoints, null);
        } else {
          this._wordIntervals = new Intervals(startPoints, []);
        }
      }
    }
    return this._wordIntervals;
  }

  get timeIntervals(): Intervals {
    if (isNil(this._timeIntervals)) {
      const startPoints = [];
      const endPoints = [];
      for (const elem of this.values) {
        startPoints.push(elem.time);
        endPoints.push(elem.endTime);
      }
      this._timeIntervals = new Intervals(startPoints, endPoints);
    }
    return this._timeIntervals;
  }

  get startWordIntervals(): Intervals {
    if (isNil(this._startWordIntervals)) {
      if (this._wordIntervals) {
        this._startWordIntervals = this._wordIntervals.fromStartPoints();
      } else {
        const startPoints = [];
        for (const elem of this.values) {
          startPoints.push(elem.address);
        }
        this._startWordIntervals = new Intervals(startPoints, null);
      }
    }
    return this._startWordIntervals;
  }

  get startTimeIntervals(): Intervals {
    if (isNil(this._startTimeIntervals)) {
      if (this._timeIntervals) {
        this._startTimeIntervals = this._timeIntervals.fromStartPoints();
      } else {
        const startPoints = [];
        for (const elem of this.values) {
          startPoints.push(elem.time);
        }
        this._startTimeIntervals = new Intervals(startPoints, null);
      }
    }
    return this._startTimeIntervals;
  }

  idRangeToIndexRange(range: IdRange<ElementId>): IndexRange {
    let startIndex = this.getIndex(range.begin);
    if (startIndex === NO_INDEX) {
      startIndex = 0;
    }
    return { begin: startIndex, end: this.getIndex(range.end) };
  }

  indexRangeToIdRange(range: IndexRange): IdRange<ElementId> {
    return { begin: this.getId(range.begin), end: this.getId(range.end) };
  }

  // useAddresses=true mode is probably not currently used
  stepId(
    id: ElementId,
    direction: number,
    domain: GenericElementListImpl = null,
    useAddresses = false
  ): ElementId | null {
    // const isIdRange = el => false; // TODO really implement, hmm is actually needed here?

    // TODO needs to consider a multiple current positional states? - focused line, focused element, selected word
    // TODO given that is the cursoring algorithm specific to the state modeling of specific app, belong on app layer?
    // or could consolidate input output state model to single dimension (one element)?
    const index = this.getIndex(id);
    if (index !== NO_INDEX) {
      const testIndex = index + direction;
      if (testIndex >= 0 && testIndex < this.values.length) {
        return this.values[testIndex].id;
      } else {
        return null;
      } // TODO: assert that domain is assigned
    } else if (domain && domain.hasElement(id)) {
      const nextElement = domain.findStep(
        id,
        (el: WideElement) => this.hasElement(el.id),
        direction
      );
      return nextElement ? nextElement.id : null;
    } else if (useAddresses) {
      if (isKindOfId(id, 'WORD')) {
        // TODO or word id test here????
        const index = this.words.getIndex(id);
        const elementIndex =
          direction === 1
            ? this.wordIntervals.firstStartsAfter(index)
            : this.wordIntervals.lastEndsBeforeOrAt(index);
        if (elementIndex !== NO_INDEX) {
          return this.values[elementIndex].id;
        } else {
          return null;
        }
      } else {
        return null;
      }
    } else {
      return null;
    }
  }

  nextId(
    id: ElementId,
    domain: GenericElementListImpl = null,
    useAddresses = false
  ): ElementId {
    return this.stepId(id, 1, domain, useAddresses);
  }

  prevId(
    id: ElementId,
    domain: GenericElementListImpl = null,
    useAddresses = false
  ): ElementId {
    return this.stepId(id, -1, domain, useAddresses);
  }

  idRangeAsIds(range: IdRange<ElementId>): ElementId[] {
    return this.idRangeAsElements(range).map((el: WideElement) => el.id);
  }

  rangeAsElements(indexRange: IndexRange): WideElement[] {
    const result: WideElement[] = [];
    for (let idx = indexRange.begin; idx <= indexRange.end; idx++) {
      result.push(this.values[idx]);
    }
    return result;
  }

  idRangeAsElements(range: IdRange<ElementId>): WideElement[] {
    return this.rangeAsElements(this.idRangeToIndexRange(range));
  }

  findNext(id: ElementId, f: (el: WideElement) => boolean): WideElement {
    // TODO if id is null start at end?
    const start = this.getIndex(id);
    const len = this.values.length;
    for (let i = start; i < len; i++) {
      const element = this.values[i];
      if (f(element)) {
        return element;
      }
    }
    return null;
  }

  findPrevious(id: ElementId, f: (el: WideElement) => boolean): WideElement {
    // TODO if id is null start at end?
    const start = this.getIndex(id);
    for (let i = start; i >= 0; i--) {
      const element = this.values[i];
      if (f(element)) {
        return element;
      }
    }
    return null;
  }

  findStep(
    id: ElementId,
    f: (el: WideElement) => boolean,
    direction: number
  ): WideElement {
    // TODO rethink this
    if (direction === -1) {
      return this.findPrevious(id, f);
    } else {
      return this.findNext(id, f);
    }
  }

  findNextOfKinds(id: ElementId, kinds: readonly string[]) {
    return this.findNext(id, (element: WideElement) =>
      kinds.includes(element.kind)
    );
  }

  findPreviousOfKinds(id: ElementId, kinds: readonly string[]) {
    return this.findPrevious(id, (element: WideElement) =>
      kinds.includes(element.kind)
    );
  }

  filter(f: (el: WideElement) => boolean) {
    const filtered: WideElement[] = this.values.filter(f);
    // const filterDomain = this.domain ?? this;
    return _CreateList(filtered, this.words);
  }

  fromIds(ids: ElementId[]) {
    const filtered: WideElement[] = [];
    for (const id of ids) {
      const element = this.getElement(id);
      if (element) {
        filtered.push(element);
      }
    }
    return _CreateList(filtered, this.words);
  }

  fromIndexes(indexes: number[]) {
    const filtered: WideElement[] = [];
    for (const index of indexes) {
      const element = this.values[index];
      if (element) {
        filtered.push(element);
      }
    }
    return _CreateList(filtered, this.words);
  }

  filterByKind(kind: string) {
    // TODO should ElementList0 have known kind restrictions then if
    // only one kind inside and kind param is same return self?
    const result = this._kindsSublists.get(kind);
    if (result) {
      return result;
    } else {
      const filtered = this.values.filter(element => element.kind === kind);
      const list = _CreateList(filtered, this.words);
      this._kindsSublists.set(kind, list);
      return list;
    }
  }

  filterByKinds(kinds: readonly string[]) {
    const internedKinds = internedKindLists.includes(kinds);
    if (internedKinds) {
      const result = this._kindsSublists.get(kinds);
      if (result) {
        return result;
      }
    }
    const filtered = this.values.filter(element =>
      kinds.includes(element.kind)
    );
    const list = _CreateList(filtered, this.words);
    if (internedKinds) {
      this._kindsSublists.set(kinds, list);
    }
    return list;
  }

  filterBySubKind(kind: string) {
    // TODO should ElementList0 have known kind restrictions then if
    // only one kind inside and kind param is same return self?
    const result = this._kindsSublists.get(kind);
    if (result) {
      return result;
    } else {
      const filtered = this.values.filter(element => element.subKind === kind);
      const list = _CreateList(filtered, this.words);
      this._kindsSublists.set(kind, list);
      return list;
    }
  }

  getKindListsAsDict(kinds: readonly string[]) {
    const result: ElementListDictionary = {};
    for (const kind of kinds) {
      result[kind] = this.filterByKind(kind);
    }
    return result; // TODO typing at implementation level
  }

  address(id: ElementId): number {
    return this.getElement(id).address;
  }

  endAddress(id: ElementId): number {
    return this.getElement(id).endAddress;
  }

  getWordInterval(id: ElementId): Interval {
    const element = this.getElement(id);
    return { begin: element.address, end: element.endAddress };
  }

  getElementsIntersectWordIndexRange(range: IndexRange): WideElement[] {
    const elementRange = this.wordIntervals.rangeIntersecting(
      range.begin,
      range.end
    );
    if (elementRange) {
      return this.rangeAsElements(elementRange);
    } else {
      return null; // TODO or []?
    }
  }

  getElementsIntersectWordIdRange(range: WordIdRange): WideElement[] {
    const wordIndexRange = this.words.idRangeToIndexRange(range);
    return this.getElementsIntersectWordIndexRange(wordIndexRange);
  }

  hasElementsIntersectWordIndexRange(range: IndexRange): boolean {
    return notNil(this.wordIntervals.rangeIntersecting(range.begin, range.end));
  }

  hasElementsIntersectWordIdRange(range: WordIdRange): boolean {
    const wordIndexRange = this.words.idRangeToIndexRange(range);
    return this.hasElementsIntersectWordIndexRange(wordIndexRange);
  }

  getElementsIntersectRangeOf(element: IntervalIndexed) {
    const range = { begin: element.address, end: element.endAddress };
    return this.getElementsIntersectWordIndexRange(range);
  }

  hasElementsIntersectRangeOf(element: IntervalIndexed) {
    const range = { begin: element.address, end: element.endAddress };
    return this.hasElementsIntersectWordIndexRange(range);
  }

  getElementsStartWithinWordIndexRange(range: IndexRange) {
    const elementRange = this.startWordIntervals.rangeStartsWithin(
      range.begin,
      range.end
    );
    if (elementRange) {
      return this.rangeAsElements(elementRange);
    } else {
      return null; // TODO or []?
    }
  }

  getElementStartWithinWordIdRange(range: WordIdRange) {
    const wordIndexRange = this.words.idRangeToIndexRange(range);
    return this.getElementsStartWithinWordIndexRange(wordIndexRange);
  }

  hasElementsStartWithinWordIndexRange(range: IndexRange) {
    return this.startWordIntervals.hasStartsWithin(range.begin, range.end);
  }

  hasElementsStartWithinWordIdRange(range: WordIdRange) {
    const wordIndexRange = this.words.idRangeToIndexRange(range);
    return this.startWordIntervals.hasStartsWithin(
      wordIndexRange.begin,
      wordIndexRange.end
    );
  }

  getElementsStartWithinRangeOf(element: IntervalIndexed) {
    const range = { begin: element.address, end: element.endAddress };
    return this.getElementsStartWithinWordIndexRange(range);
  }

  hasElementsStartWithinRangeOf(element: IntervalIndexed) {
    const range = { begin: element.address, end: element.endAddress };
    return this.startWordIntervals.hasStartsWithin(range.begin, range.end);
  }

  // start time millis
  time(id: ElementId): number {
    return this.getElement(id).time;
  }

  endTime(id: ElementId): number {
    return this.getElement(id).endTime;
  }

  getTimeInterval(id: ElementId): Interval {
    const element = this.getElement(id);
    return { begin: element.time, end: element.endTime };
  }

  getElementContainingWordAddress(address: number): WideElement {
    const wordIntervals = this.wordIntervals;
    const elementIndex = wordIntervals.containing(address);
    if (elementIndex !== NO_INDEX) {
      return this.values[elementIndex];
    } else {
      return null;
    }
  }

  getElementContainingWordId(id: WordId): WideElement {
    const wordIndex = this.words.getIndex(id);
    return this.getElementContainingWordAddress(wordIndex);
  }

  getElementContainingTime(time: number): WideElement {
    const timeIntervals = this.timeIntervals;
    const elementIndex = timeIntervals.containing(time);
    if (elementIndex !== NO_INDEX) {
      return this.values[elementIndex];
    } else {
      return null;
    }
  }

  difference(elementList: ElListConstraints & { hasElement: any }) {
    if (elementList.values === this.values) {
      return WideAndEmptyElementList;
    }
    return this.filter(
      (element: WideElement) => !elementList.hasElement(element.id)
    );
  }

  // used by Tracker
  remapContentDimensionedArray<T>(remap: T[], newList: ELTOf<IElement>): T[] {
    if (isNil(remap)) {
      return null;
    } else {
      // const len = newList.elements.length;
      const result = new Array(newList.values.length);
      for (const [i, value] of remap.entries()) {
        const id = this.getId(i);
        const newIndex = newList.getIndex(id);
        result[newIndex] = value;
      }
      return result;
    }
  }

  // // mutates CHAPTER elements into CHAPTER_SPAN elements, etc
  // transformHeadsToSpans(
  //   headKind: EKind,
  //   spanKind: EKind,
  //   {
  //     expandIntoGaps = false,
  //     breakWordMap = null,
  //     audioEndTime = 0, // full track duration
  //   }: {
  //     expandIntoGaps: boolean;
  //     breakWordMap: BreakWordMap;
  //     audioEndTime: number;
  //   }
  // ): void {
  //   const headList = this.getKindSubList(headKind); // as ElementList<Structural>;

  //   // const wordEList: ElementList<Word> = masterEList.words;

  //   for (let i = 0; i < headList.values.length; i++) {
  //     const isLast = i + 1 === headList.values.length;
  //     const headElement = headList.values[i];
  //     const nextHeadElement = isLast ? null : headList.values[i + 1];
  //     const firstWordIndex = headElement.address;
  //     const lastWordIndex = isLast
  //       ? this.words.elements.length - 1
  //       : nextHeadElement.address - 1;
  //     var time: number;
  //     var endTime: number;
  //     if (expandIntoGaps) {
  //       time = this.priorBreakpointTime(firstWordIndex, breakWordMap);
  //       endTime = this.followingBreakpointTime(
  //         lastWordIndex,
  //         breakWordMap,
  //         audioEndTime
  //       );
  //     } else {
  //       time = this.words.elements[firstWordIndex]?.time;
  //       endTime = this.words.elements[lastWordIndex]?.time;
  //       if (!time || !endTime) {
  //         console.error(
  //           `transformHeadsToSpans - failed to find word data for indexes[${firstWordIndex},${lastWordIndex}] - head: ${JSON.stringify(
  //             headElement
  //           )}}]`
  //         );
  //       }
  //     }

  //     // collect the properties which need changing
  //     const spanData = {
  //       // id: headElement.id, // stays the same, important for translation lookups
  //       kind: spanKind,
  //       // address: headElement.address, // stays the same
  //       endAddress: lastWordIndex,
  //       time,
  //       endTime,
  //     };
  //     // transform the 'head' into a 'span', but retain id, so that the translation lookup still works
  //     Object.assign(headElement, spanData);
  //   }
  // }

  // priorBreakpointTime(wordIndex: number, breakWordMap: BreakWordMap) {
  //   const wordElements = this.words.elements;
  //   if (wordIndex === 0) {
  //     return 0;
  //   } else {
  //     const matchedBreakTime = breakWordMap.get(wordIndex);
  //     if (matchedBreakTime) {
  //       console.log(
  //         `matched prior breaktime: ${matchedBreakTime} for wordIndex: ${wordIndex}`
  //       );
  //       return matchedBreakTime;
  //     } else {
  //       return Math.floor(
  //         (wordElements[wordIndex].time + wordElements[wordIndex - 1].endTime) /
  //           2
  //       );
  //     }
  //   }
  // }

  // followingBreakpointTime(
  //   wordIndex: number,
  //   breakWordMap: BreakWordMap,
  //   endAudioTime: number
  // ) {
  //   const wordElements = this.words.elements;
  //   if (wordIndex >= wordElements.length - 1) {
  //     // return wordElements[wordIndex].endTime; // TODO: don't cut end of audio
  //     return endAudioTime;
  //   } else {
  //     const matchedBreakTime = breakWordMap.get(wordIndex + 1);
  //     if (matchedBreakTime) {
  //       console.log(
  //         `matched following breaktime: ${matchedBreakTime} for wordIndex: ${wordIndex}`
  //       );
  //       return matchedBreakTime;
  //     } else {
  //       return Math.floor(
  //         (wordElements[wordIndex].endTime + wordElements[wordIndex + 1].time) /
  //           2
  //       );
  //     }
  //   }
  // }
}

export const WideAndEmptyWordElementList = GenericElementListFactory(
  [],
  null
) as unknown as WordElementList;

export const WideAndEmptyElementList = GenericElementListFactory(
  [],
  WideAndEmptyWordElementList
);

export function GenericElementListFactory(
  elements: IElement[],
  words: WordElementList
) {
  return new GenericElementListImpl(elements as WideElement[], words) as any;
}
