import { renderStylesForDomId } from '@tikka/membership-reconciliation/dom-styles';
import { createMembershipEnumFuncs } from '@tikka/membership-reconciliation/membership-enum-funcs';
import {
  CreateMembershipReconciler,
  MembershipReconciler,
} from '@tikka/membership-reconciliation/membership-reconciler';
import { createLogger } from 'app/logger';
import { WordMembership } from 'player/models/word-membership';

const log = createLogger('membership-context');

type MembershipContext = {
  wordMembershipReconciler: MembershipReconciler;
  lineMembershipReconciler: MembershipReconciler;
  speakerMembershipReconciler: MembershipReconciler;
  passageMembershipReconciler: MembershipReconciler;
  getMemberships: (id: string) => number;
};

const membershipContext = {
  wordMembershipReconciler: null,
  lineMembershipReconciler: null,
  getMemberships: null,
} as MembershipContext;

export const initializeStandardMembershipContext = (): MembershipContext => {
  log.debug('initializeStandardMembershipContext');
  const membershipFuncs = createMembershipEnumFuncs(WordMembership);

  membershipContext.wordMembershipReconciler = CreateMembershipReconciler(
    membershipFuncs.setMemberships
  );

  membershipContext.lineMembershipReconciler =
    CreateMembershipReconciler(renderStylesForDomId);

  membershipContext.speakerMembershipReconciler =
    CreateMembershipReconciler(renderStylesForDomId);

  membershipContext.passageMembershipReconciler =
    CreateMembershipReconciler(renderStylesForDomId);

  membershipContext.getMemberships = membershipFuncs.getMemberships;

  return membershipContext;
};

export const useMembershipContext = (): MembershipContext => {
  return membershipContext;
};
