import { keyboardService } from 'lib/services/keyboard-service';
import { isStudyModel } from '../../models/player-model-handle';
import { PlayerModel } from 'player/models/player-model';
import React from 'react';

export function usePlayerKeyboardControls(model: PlayerModel) {
  React.useEffect(() => {
    keyboardService.addShortcutSet('player', {
      space: () => model.playPauseAction(),
      escape: () => model.cancelPendingPause(),
      left: () => model.rewind(),
      right: () => model.forward(),
      up: () => model.seekPreviousLine(),
      down: () => model.seekNextLine(),

      r: () => model.replayCurrentSentence(),
      s: () => model.snailReplayCurrentSentence(),
      equal: () => model.player.adjustPlaybackRateAction(true), // @armando: can we just ignore the shift?
      minus: () => model.player.adjustPlaybackRateAction(false),
      enter: () => model.toggleCurrentSentenceRedaction(),

      tab: () => {
        if (isStudyModel(model)) {
          model.togglePlayerMode();
        }
      },
      t: () => model.toggleTranslations(),
      slash: () => model.toggleHelpOverlay(),

      'shift+backquote': () => model.toggleDebugMode(),
      o: () => model.toggleDebugOverlay(),
      q: () => model.debugReset(),
      i: () => model.debugToggleOnloadModal(),
      z: () => model.debugSeekToEnd(),
      h: () => model.debugNavBack1(),
      l: () => model.debugNavForward1(),
      k: () => model.debugNavBack25(),
      j: () => model.debugNavForward25(),
    });
    keyboardService.setCurrentShortcutSet('player');

    return () => {
      keyboardService.removeShortcutSet('player');
    };
  });
}
