import * as React from 'react';
import { styled } from '@naan/stitches.config';
import { CheckmarkHeavySmallIcon } from '@naan/icons/checkmark-heavy-icon';

const MAX_BREADCRUMBS = 3;

const BreadcrumbContainer = styled('div', {
  display: 'flex',
  flexDirection: 'row',
  '& > * + *': {
    marginLeft: '$space$2',
  },
});

const Breadcrumb = styled('div', {
  display: 'flex',
  borderRadius: '50%',
  borderWidth: '1px',
  borderStyle: 'solid',
  width: '20px',
  height: '20px',
  textAlign: 'center',
  textStyle: 'tiny-text',
  justifyContent: 'center',
  alignItems: 'center',
  alignSelf: 'center',
});

const ChapterBreadcrumb = ({
  location,
  breadcrumbState,
}: {
  location: number;
  breadcrumbState: number;
}) => {
  let color = '$colors$gray-100';
  if (location === breadcrumbState) {
    color = '$colors$teal-500';
  } else if (location < breadcrumbState) {
    color = '$colors$gray-400';
  }
  return (
    <Breadcrumb css={{ borderColor: color, color: color }}>
      {location === MAX_BREADCRUMBS ? (
        <CheckmarkHeavySmallIcon width={12} height={12} />
      ) : (
        location
      )}
    </Breadcrumb>
  );
};

export const ChapterBreadcrumbs = ({
  breadcrumbState,
}: {
  breadcrumbState: number;
}) => (
  <BreadcrumbContainer>
    <ChapterBreadcrumb location={1} breadcrumbState={breadcrumbState} />
    <ChapterBreadcrumb location={2} breadcrumbState={breadcrumbState} />
    <ChapterBreadcrumb location={3} breadcrumbState={breadcrumbState} />
  </BreadcrumbContainer>
);
