import { reaction, runInAction } from 'mobx';
import {
  TimedElement,
  ElementId,
  NO_INDEX,
  EmptyElementList,
  IndexedElement,
  ELTOf,
  WideElementList,
  IndexRange,
} from '../basic-types';
import { EmptyIntervals, Intervals } from '../intervals/intervals';
import { Signal, useTickleWithSignal } from './signal';
import {
  advanceVIdxBecauseStateChange,
  clearChangeRecords,
  currentIsUnder,
  isBefore,
  isUnder,
  isVisited,
  recordChanged,
  recordChangesForNewPosition,
  recordIsVisitedChanges,
  refreshIntervals,
  TrackingState,
} from './tracking-engine';
import { isNull } from '@utils/conditionals';
import { Tracker, TrackerOptions } from './tracker';

class SignalArr {
  signals: Signal[] = null;
}

export class TrackerImpl<
  IDT extends string | number,
  ET extends TimedElement | IndexedElement
> implements Tracker<IDT, ET>
{
  triggerFunction: () => any;
  triggerDisposer: () => void;
  positionFunction: () => number;
  elementList: WideElementList;
  elementIdArray: IDT[];
  elementIdToIndexMap: { [index in IDT]: number };
  getIntervals: (els: WideElementList) => Intervals;
  intervals: Intervals;
  trackingState: TrackingState;
  beforeRangeSignal: Signal;
  visitedRangeSignal: Signal;
  isUnderListeners: Set<(el: IDT) => void>;
  isBeforeListeners: Set<(el: IDT) => void>;
  isVisitedListeners: Set<(el: IDT) => void>;
  isUnderSignals: SignalArr;
  isBeforeSignals: SignalArr;
  isVisitedSignals: SignalArr;
  changeSignals: SignalArr;
  anyIsChanged0: Signal;
  options: TrackerOptions = 0;

  constructor() {
    this.triggerFunction = null;
    this.positionFunction = null;
    this.getIntervals = null;
    this.elementList = EmptyElementList;
    this.elementIdArray = null;
    this.elementIdToIndexMap = null;
    this.intervals = EmptyIntervals;
    this.trackingState = new TrackingState();
    this.beforeRangeSignal = new Signal();
    this.beforeRangeSignal.set(NO_INDEX);
    this.visitedRangeSignal = new Signal();
    this.visitedRangeSignal.set(NO_INDEX);
    this.isUnderListeners = null;
    this.isBeforeListeners = null;
    this.isVisitedListeners = null;
    this.isUnderSignals = new SignalArr();
    this.isBeforeSignals = new SignalArr();
    this.isVisitedSignals = new SignalArr();
    this.changeSignals = new SignalArr();
    this.anyIsChanged0 = null;
  }

  configure({
    elements,
    triggerFunction,
    positionFunction,
    intervals,
    options,
  }: {
    elements?: ELTOf<ET> | IDT[];
    triggerFunction?: () => number;
    positionFunction?: () => number;
    intervals?: Intervals | ((arg: any) => Intervals);
    options?: TrackerOptions;
  }): void {
    if (options) {
      this.options = options;
    }
    if (positionFunction) {
      if (!this.positionFunction) {
        this.positionFunction = positionFunction;
      } else {
        throw new Error('altering existing positionFunction not allowed.');
      }
    }
    if (intervals) {
      if (typeof intervals === 'function') {
        if (this.intervals !== EmptyIntervals && !this.getIntervals) {
          throw new Error(
            'cannot reconfigure from value to function type for intervals.'
          );
        }
        this.getIntervals = intervals;
      } else {
        if (this.getIntervals) {
          throw new Error(
            'cannot reconfigure from function to value type for intervals.'
          );
        }
        // TODO possibly error on non empty existing intervals?
        this.elementList = null; // forces alternate path in some methods
        this.intervals = intervals;
        refreshIntervals(this.trackingState, this.intervals);
      }
    }
    if (elements) {
      if (elements instanceof Array) {
        this.elementList = null;
        if (this.intervals.length !== elements.length) {
          throw new Error(
            'cannot configure id array with length not matching configured intervals'
          );
        }
        this.elementIdArray = elements;
        const indexMap: { [index in IDT]: number } = {} as any;
        for (let idx = 0; idx < elements.length; idx++) {
          indexMap[elements[idx]] = idx;
        }
        this.elementIdToIndexMap = indexMap;
      } else {
        if ('values' in elements) {
          const elementList = elements as unknown as WideElementList;
          if (elementList !== this.elementList) {
            this.isUnderSignals.signals =
              this.elementList.remapContentDimensionedArray(
                this.isUnderSignals.signals,
                elementList
              );
            this.isBeforeSignals.signals =
              this.elementList.remapContentDimensionedArray(
                this.isBeforeSignals.signals,
                elementList
              );
            this.isVisitedSignals.signals =
              this.elementList.remapContentDimensionedArray(
                this.isVisitedSignals.signals,
                elementList
              );
            this.elementList = elementList;
            if (!this.getIntervals) {
              throw new Error(
                'cannot use ElementList for elements without configured getIntervals function'
              );
            }
            this.intervals = this.getIntervals(this.elementList);
            refreshIntervals(this.trackingState, this.intervals);
          }
        } else {
          // TODO throw?
        }
      }
    }
    if (this.triggerFunction !== triggerFunction && triggerFunction) {
      if (!this.positionFunction) {
        throw new Error(
          'cannot configure triggerFunction without positionFunction already configured'
        );
      }
      this.triggerFunction = triggerFunction;
      if (this.triggerDisposer) {
        this.triggerDisposer();
        this.triggerDisposer = null;
      }
      if (this.triggerFunction) {
        this.triggerDisposer = reaction(this.triggerFunction, () =>
          this.processPositionChange()
        );
      }
    }
  }

  dispose() {
    if (this.triggerDisposer) {
      this.triggerDisposer();
      this.triggerDisposer = null;
    }
  }

  indexToElementId(index: number): IDT {
    if (this.elementList) {
      if (index !== NO_INDEX) {
        return this.elementList.getId(index) as any;
      } else {
        return null;
      }
    } else {
      if (this.elementIdArray) {
        return this.elementIdArray[index];
      }
    }
    return index as any;
  }

  elementIdToIndex(elementId: IDT): number {
    if (!this.triggerFunction) {
      throw new Error(
        'ERROR: trying to use tracker before setting triggerFunction sets up reaction'
      );
    }
    return this.elementList
      ? this.elementList.getIndex(elementId as ElementId)
      : this.elementIdToIndexMap
      ? this.elementIdToIndexMap[elementId]
      : (elementId as number);
  }

  newListeners() {
    return new Set<(el: IDT) => void>();
  }

  makeUnsubscribe(listeners: Set<(el: IDT) => void>, f: (el: IDT) => void) {
    return () => {
      listeners.delete(f);
    };
  }

  addListener(listeners: Set<(el: IDT) => void>, f: (el: IDT) => void) {
    listeners.add(f);
    return this.makeUnsubscribe(listeners, f);
  }

  notifyListenersForRange(
    listeners: Set<(el: IDT) => void>,
    begin: number,
    end: number
  ) {
    if (listeners && begin >= 0) {
      for (const callback of listeners) {
        for (let i = begin; i <= end; i++) {
          const id = this.indexToElementId(i);
          callback(id);
        }
      }
    }
  }

  notifyListeners(listeners: Set<(el: IDT) => void>, index: number) {
    if (listeners && index !== NO_INDEX) {
      for (const callback of listeners) {
        const id = this.indexToElementId(index);
        callback(id);
      }
    }
  }

  makeSignal() {
    return new Signal();
  }

  createSignalsArray(): Signal[] {
    return new Array(this.intervals.length).fill(null);
  }

  getOrCreateSignals(signalsArr: SignalArr) {
    if (isNull(signalsArr.signals)) {
      signalsArr.signals = this.createSignalsArray();
    }
    return signalsArr.signals;
  }

  getSignal(signalsArr: SignalArr, idx: number) {
    const signals = this.getOrCreateSignals(signalsArr);
    let signal = signals[idx];
    if (isNull(signal)) {
      signal = this.makeSignal();
      signals[idx] = signal;
    }
    return signal;
  }

  needNotifyAnyChange() {
    return !!this.changeSignals.signals;
  }

  notifySignal(signal: Signal) {
    if (signal) {
      signal.set(this.trackingState.vIdx);
    }
  }

  notifySignalIndex(signalsArr: SignalArr, index: number) {
    const signals = signalsArr.signals;
    if (signals && index !== NO_INDEX) {
      this.notifySignal(signals[index]);
    }
  }

  notifySignalRange(signalsArr: SignalArr, begin: number, end: number) {
    // TODO is length check ever needed?
    const signals = signalsArr.signals;
    if (signals && begin >= 0 && end < signals.length) {
      for (let i = begin; i <= end; i++) {
        this.notifySignal(signals[i]);
      }
    }
  }

  // could return a disposer
  subscribeIsUnder(f: (el: IDT) => void) {
    if (!this.isUnderListeners) {
      this.isUnderListeners = this.newListeners();
    }
    return this.addListener(this.isUnderListeners, f);
  }

  subscribeIsBefore(f: (el: IDT) => void) {
    if (!this.isBeforeListeners) {
      this.isBeforeListeners = this.newListeners();
    }
    return this.addListener(this.isBeforeListeners, f);
  }

  subscribeIsVisited(f: (el: IDT) => void) {
    if (!this.isVisitedListeners) {
      this.isVisitedListeners = this.newListeners();
    }
    return this.addListener(this.isVisitedListeners, f);
  }

  notifyChange() {
    if (this.anyIsChanged0) {
      this.anyIsChanged0.set(this.trackingState.vIdx);
    }
  }

  notifyBeforeRange(begin: number, end: number) {
    this.beforeRangeSignal.set(end);
  }

  notifyVisitedRange(begin: number, end: number) {
    this.visitedRangeSignal.set(end);
  }

  notifyIsBefore(begin: number, end: number) {
    this.notifyListenersForRange(this.isBeforeListeners, begin, end);
    this.notifySignalRange(this.isBeforeSignals, begin, end);
    this.notifyBeforeRange(begin, end);
    if (this.needNotifyAnyChange()) {
      this.notifySignalRange(this.changeSignals, begin, end);
    }
  }

  notifyIsVisited(begin: number, end: number) {
    this.notifyListenersForRange(this.isVisitedListeners, begin, end);
    this.notifySignalRange(this.isVisitedSignals, begin, end);
    this.notifyVisitedRange(begin, end);
  }

  notifyIsUnder(index: number) {
    this.notifyListeners(this.isUnderListeners, index);
    this.notifySignalIndex(this.isUnderSignals, index);
  }

  notifyAllChanges() {
    runInAction(() => {
      this.notifyIsUnder(this.trackingState.isUnderOldChangeIndex);
      this.notifyIsUnder(this.trackingState.isUnderNewChangeIndex);
      let isBeforeChangeRangeStart =
        this.trackingState.isBeforeChangeRangeStart;
      if (isBeforeChangeRangeStart === NO_INDEX) {
        isBeforeChangeRangeStart = 0;
      }
      this.notifyIsBefore(
        isBeforeChangeRangeStart,
        this.trackingState.isBeforeChangeRangeEnd
      );
      let isVisitedChangeRangeStart =
        this.trackingState.isVisitedChangeRangeStart;
      if (isVisitedChangeRangeStart === NO_INDEX) {
        isVisitedChangeRangeStart = 0;
      }
      this.notifyIsVisited(
        isVisitedChangeRangeStart,
        this.trackingState.isVisitedChangeRangeEnd
      );
      this.notifyChange();
    });
  }

  processPositionChange() {
    const position = this.positionFunction();
    recordChangesForNewPosition(this.trackingState, position);
    if (this.trackingState.anyChangeRecord) {
      this.notifyAllChanges();
      if (this.options & TrackerOptions.LOG_CHANGES) {
        console.log('NOTIFIED CHANGES');
      }
      clearChangeRecords(this.trackingState);
    }
  }

  get anyIsChangedSignal() {
    if (!this.anyIsChanged0) {
      this.anyIsChanged0 = this.makeSignal();
    }
    return this.anyIsChanged0;
  }

  isUnderSignal(elementId: IDT) {
    return this.getSignal(
      this.isUnderSignals,
      this.elementIdToIndex(elementId)
    );
  }

  isBeforeSignal(elementId: IDT) {
    return this.getSignal(
      this.isBeforeSignals,
      this.elementIdToIndex(elementId)
    );
  }

  isVisitedSignal(elementId: IDT) {
    return this.getSignal(
      this.isVisitedSignals,
      this.elementIdToIndex(elementId)
    );
  }

  changedSignal(elementId: IDT) {
    return this.getSignal(this.changeSignals, this.elementIdToIndex(elementId));
  }

  currentIsUnder() {
    return this.indexToElementId(currentIsUnder(this.trackingState));
  }

  isUnder(elementId: IDT) {
    return isUnder(this.trackingState, this.elementIdToIndex(elementId));
  }

  isBefore(elementId: IDT) {
    return isBefore(this.trackingState, this.elementIdToIndex(elementId));
  }

  isVisited(elementId: IDT) {
    return isVisited(this.trackingState, this.elementIdToIndex(elementId));
  }

  watchIsUnder(elementId: IDT) {
    this.isUnderSignal(elementId).watch();
    return this.isUnder(elementId);
  }

  watchIsBefore(elementId: IDT) {
    this.isBeforeSignal(elementId).watch();
    return this.isBefore(elementId);
  }

  watchIsVisited(elementId: IDT) {
    this.isVisitedSignal(elementId).watch();
    return this.isVisited(elementId);
  }

  useWatchIsUnder(elementId: IDT, tickler?: () => void) {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    useTickleWithSignal(tickler, this.isUnderSignal(elementId));
    return this.isUnder(elementId);
  }

  useWatchIsBefore(elementId: IDT, tickler?: () => void) {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    useTickleWithSignal(tickler, this.isBeforeSignal(elementId));
    return this.isBefore(elementId);
  }

  useWatchIsVisited(elementId: IDT, tickler?: () => void) {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    useTickleWithSignal(tickler, this.isVisitedSignal(elementId));
    return this.isVisited(elementId);
  }

  elementInterval(elementId: IDT) {
    return this.intervals.intervalAt(this.elementIdToIndex(elementId));
  }

  observableIsUnder() {
    // TODO optimize when implement anyIsUnderChanged signal (instead of anyIsChanged)
    if (!this.triggerFunction) {
      throw new Error(
        'ERROR: trying to use tracker before setting triggerFunction sets up reaction'
      );
    }
    this.anyIsChangedSignal.watch();
    return this.currentIsUnder();
  }

  observableBeforeRange(): IndexRange {
    return {
      begin: NO_INDEX,
      end: this.beforeRangeSignal.watch(),
    };
  }

  observableVisitedRange(): IndexRange {
    return {
      begin: NO_INDEX,
      end: this.visitedRangeSignal.watch(),
    };
  }

  furthestTrackedPosition() {
    return this.trackingState.furthestPosition;
  }

  forceFurthestVisitedTime(pos: number) {
    const newFurthestIndex = this.intervals.lastStartsBeforeOrAt(pos);
    if (newFurthestIndex > this.trackingState.lastFurthestIndex) {
      const state = this.trackingState;
      advanceVIdxBecauseStateChange(state);
      recordIsVisitedChanges(
        state,
        state.lastFurthestIndex + 1,
        newFurthestIndex
      );
      recordChanged(state);
      state.lastFurthestIndex = newFurthestIndex;
      state.furthestPosition = pos;
      this.notifyAllChanges();
      clearChangeRecords(this.trackingState);
    }
  }
}
