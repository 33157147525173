import { styled } from 'naan/stitches.config';
import React, { ComponentProps } from 'react';
import ReactMarkdown from 'react-markdown';

/**
 * We have to redefine styles here because there are very annoying global styles
 */
const Wrapper = styled('div', {
  textStyle: 'body',

  '& p': {
    marginBottom: '16px',
    '&:last-child': {
      marginBottom: '0',
    },
  },
  '& a': {
    color: '$teal-500',
    textDecoration: 'underline',
  },

  '& li': {
    textIndent: '0',
    marginLeft: '1em',
    marginBottom: '16px',
  },

  '& ul': {
    listStyleType: 'disc',
    li: {
      listStyleType: 'disc',
    },
  },

  '& ol': {
    listStyleType: 'decimal',
    li: {
      listStyleType: 'decimal',
    },
  },
  '& strong': {
    textStyle: 'body-bold',
  },
});

type WrapperProps = React.ComponentProps<typeof Wrapper>;

export const Markdown = ({
  source,
  ...props
}: WrapperProps & { source: string }) => {
  return (
    <Wrapper {...props}>
      <ReactMarkdown children={source} />
    </Wrapper>
  );
};

export const PlainMarkdown = ({
  source,
  ...props
}: {
  source: string;
} & Omit<ComponentProps<typeof ReactMarkdown>, 'children'>) => {
  return <ReactMarkdown children={source} {...props} />;
};
