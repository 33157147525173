import React from 'react';
import { observer } from 'mobx-react';
import { AppFactory } from 'app/app-factory';
import { stripTrailingPunctuation } from '@tikka/misc/string-utils';
import { ClientNotation } from '@tikka/client/client-types';
import { styled } from '@naan/stitches.config';
import { InVocabLargeIcon } from '@naan/icons/in-vocab-icon';
import { IconButton } from '@naan/primitives/button/icon-button';
import { AddToVocabLargeIcon } from '@naan/icons/add-to-vocab-icon';
import { PlainMarkdown } from '@naan/primitives/text';

const Wrapper = styled('div', {
  display: 'grid',
  gridTemplateColumns: '1fr 28px',
  paddingTop: 8,
  paddingBottom: 12,
  '& .head': {
    cursor: 'default',
    '& dt': {
      textStyle: 'script--notes',
    },
    '& dd': {
      textStyle: 'small-text',
      color: '$colors$textSecondary',
    },
  },
  '& .actions': {
    display: 'flex',
    alignItems: 'center',
  },
});

export const NotationsListItem = observer(
  ({
    notation,
    addVocab,
    removeVocab,
    vocabExists,
  }: {
    notation: ClientNotation;
    addVocab: (slug: string) => void;
    removeVocab: (slug: string) => void;
    vocabExists: (slug: string) => boolean;
  }) => {
    const model = AppFactory.studyModel;

    return (
      <Wrapper>
        <div
          className="head"
          onClick={() => model.selectNotationId(notation.id)}
        >
          <dl>
            <dt>{stripTrailingPunctuation(notation.headword)}</dt>
            {notation.note ? (
              <dd>
                <PlainMarkdown source={notation.note} />
              </dd>
            ) : null}
          </dl>
        </div>
        <div className="actions">
          {vocabExists(notation.id) ? (
            <IconButton
              presentation={'grayTransparent'}
              onClick={() => removeVocab(notation.id)}
              icon={<InVocabLargeIcon />}
            />
          ) : (
            <IconButton
              presentation={'grayTransparent'}
              onClick={() => addVocab(notation.id)}
              icon={<AddToVocabLargeIcon />}
            />
          )}
        </div>
      </Wrapper>
    );
  }
);
