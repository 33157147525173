import { getConfig } from 'app/env';
import { createLogger } from 'app/logger';

import segment from '../segment-script';
import { AnalyticsAdapter } from '../analytics-adapter';

const log = createLogger('analytics:segment');
const segmentWriteKey = getConfig('analytics.segmentWriteKey', null);

const format = (eventName: string) => {
  const prefix = getConfig('analytics.eventPrefix');
  return `${prefix}:${eventName}`;
};

export class SegmentAnalytics extends AnalyticsAdapter {
  serviceName = 'SegmentAnalytics';
  isEnabled = segmentWriteKey !== null;

  constructor() {
    super();
    if (this.isEnabled) {
      segment().load(segmentWriteKey);
    }
  }

  identify(userId: string) {
    log.info('identify', { userId });
    segment().identify({ userId });
  }

  track(eventName: string, data?: any) {
    log.info('track', { eventName, data });
    segment().track(format(eventName), data);
  }

  page(pageName: string, data?: any) {
    log.info('page', { pageName, data });
    segment().page(pageName, data);
  }
}
