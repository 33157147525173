import * as React from 'react';
import { observer } from 'mobx-react';

import { ElementNode } from '@tikka/client/client-aliases';

import { WordMembership } from 'player/models/word-membership';
import { WordPresentation } from 'player/views/elements-presentations/word-presentation';
import { untracked } from 'mobx';
import { elementIdToDomId } from '@tikka/elements/element-id-utils';
import { RedactionMode } from 'player/models/redaction-modes';
import { Word as WordElement } from '@tikka/client/client-aliases';
import { PlayerStatus } from 'player/models/player-model';
import { usePlayerModel } from 'player/views/player-model-context';
import { useMembershipContext } from '../membership-context';

export const Word = observer(
  ({
    node,
    redaction,
  }: {
    node: ElementNode;
    redaction?: () => RedactionMode;
  }) => {
    const model = usePlayerModel();
    const { getMemberships } = useMembershipContext();

    const wordElement = node.element as WordElement;
    const wordId = wordElement.id;
    const redactionMode = redaction();
    let membership = getMemberships(elementIdToDomId(null, wordId));

    const wordTracker = model.wordTracker;
    if (wordTracker.watchIsUnder(wordId)) {
      membership |= WordMembership.CURRENT;
    }
    if (wordTracker.watchIsVisited(wordId)) {
      membership |= WordMembership.VISITED;
    }
    if (
      membership & WordMembership.CURRENT &&
      !(membership & WordMembership.VISITED)
    ) {
      untracked(() => {
        if (model.playerStatus !== PlayerStatus.PAUSED) {
          membership = membership & ~WordMembership.CURRENT;
        }
      });
    }
    // if (model.neverPlayed()) {
    //   if (membership & WordMembership.VISITED) {
    //     wordTracker.anyIsChangedSignal.watch();
    //   }
    //   membership =
    //     membership &
    //     ~(
    //       WordMembership.BEFORE |
    //       WordMembership.VISITED |
    //       WordMembership.CURRENT
    //     );
    // }

    const sicStart = model.sicStarts.has(wordId);
    const sicIntended = model.sicIntended.get(wordId);

    return (
      <WordPresentation
        text={wordElement.text}
        membership={membership}
        redaction={redactionMode}
        sicStart={sicStart}
        sicIntended={sicIntended}
      />
    );
  }
);
