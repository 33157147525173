import React from 'react';
import { observer } from 'mobx-react';
import { Table } from 'components/ds/tables';
import { ProgressCell } from './progress-cell';
import { VocabCountCell } from './vocab-count-cell';
import { StudentNameCell } from '../table-cells/student-name-cell';
import { StudentEmailCell } from '../table-cells/student-email-cell';
import { StudentTimeStudiedCell } from '../table-cells/student-time-studied-cell';
import { Text } from 'naan/primitives/text';
import { useCurrentAssignment } from './current-assignment-context';
import { StudentProgress } from 'core/models/user-manager';

import __ from 'core/lib/localization';

export const AssignmentStudentSection = observer(
  ({ show }: { show: boolean }) => {
    const { assignment } = useCurrentAssignment();
    const studentProgresses = assignment.studentProgresses;
    // const { students } = classroom;
    const { story } = assignment;
    // console.log({ classroom, assignment });

    React.useEffect(() => {
      assignment?.fetchProgressData();
    }, [assignment]);

    if (!show) {
      return null;
    }

    // const loadingData = data === null || store.loadingData;
    // const tableData = data?.studentProgresses ?? [];

    return (
      <>
        {/* // todo: confirm if studentProgresses is guarantees to include all students */}
        {studentProgresses.length > 0 ? (
          <Table
            data={studentProgresses}
            cells={[
              {
                headerLabel: __('Name', 'common.name'),
                component: StudentNameCell,
              },
              {
                headerLabel: __('Email', 'common.email'),
                component: StudentEmailCell,
              },
              {
                headerLabel: __('Progress', 'common.progress'),
                renderCell: (progress: StudentProgress) => (
                  <ProgressCell {...{ story, progress }} />
                ),
              },
              {
                headerLabel: __('Time studied', 'common.timeStudied'),
                renderCell: ({ allTimeStudiedHourMin }: StudentProgress) => {
                  return (
                    <StudentTimeStudiedCell
                      allTimeStudiedHourMin={allTimeStudiedHourMin}
                    />
                  );
                },
              },
              {
                headerLabel: __('Vocab', 'common.vocab'),
                component: VocabCountCell,
              },
            ]}
          />
        ) : (
          <Text>
            {__(
              'After you add students to this class you will see their progress for this story here',
              'classroom.studentsZeroState'
            )}
          </Text>
        )}
      </>
    );
  }
);
