import React from 'react';

import { useTitle } from 'common/hooks/use-title';
import { PageHeading } from 'components/page-heading';
// import { styled } from 'naan/stitches.config';
import { observer } from 'mobx-react';

import __ from 'core/lib/localization';
import { OldHeaderContainer } from 'components/header';
import { ContentGridInnerContainer } from 'components/global-layout';
import { isEmpty } from 'lodash';
import { Story } from '@core/models/story-manager/story';
import { AppFactory } from 'app/app-factory';
import { VSpacer } from '@naan/primitives/spacer/v-spacer';
import { StoryList } from 'components/stories/story-list';

// const LayoutWrapper = styled('section', {
//   gridColumn: '1/-1',
// });

export const DashboardScreen = observer(() => {
  const { storyManager } = AppFactory.root;
  useTitle(__('Dashboard', 'nav.dashboard'));

  let heading: string;
  let stories: Story[];

  const started = storyManager.started;
  if (isEmpty(started)) {
    heading = __('Free', 'vars.free');
    stories = storyManager.trial;
  } else {
    heading = __('In Progress', 'vars.inProgress');
    stories = started;
  }

  return (
    <>
      <OldHeaderContainer>
        <ContentGridInnerContainer>
          <PageHeading title={heading} />
          <StoryList stories={stories} /> <VSpacer size={4} />
        </ContentGridInnerContainer>
      </OldHeaderContainer>
    </>
  );
});
