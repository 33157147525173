// import { getPlayerModel } from 'player/views/player-model-context';
import { action, makeObservable, observable } from 'mobx';
// import { StudyModel } from 'study/models/study-model';
// import { PlayerModel } from 'player/models/player-model';

const SCRIPT_SCROLLING_CONTAINER_ID = 'script-scrolling-container';

class SentenceScrollObserver {
  private observer: IntersectionObserver;
  private root: HTMLElement;
  private targets: NodeListOf<Element>;
  private options: IntersectionObserverInit;
  // private model: PlayerModel;

  isCurrentSentenceVisible = true;

  constructor() {
    makeObservable(this, {
      isCurrentSentenceVisible: observable,
      setIsCurrentSentenceVisible: action,
    });
  }

  connect() {
    const root = document.getElementById(SCRIPT_SCROLLING_CONTAINER_ID);
    this.root = root;
    this.options = {
      root,
      /// the header is superimposed on the top of the script so we need to compensate for it
      rootMargin: '56px 0px 0px 0px',
      /// 75% of the target element must be visible/
      threshold: 0.75,
    };

    this.observer = new IntersectionObserver(
      this.callback.bind(this),
      this.options
    );

    this.observe();
  }

  setIsCurrentSentenceVisible(isVisible: boolean) {
    this.isCurrentSentenceVisible = isVisible;
  }

  observe() {
    this.targets = this.root.querySelectorAll('.line');
    this.targets.forEach(line => {
      this.observer.observe(line);
    });
  }

  unobserve() {
    this.targets.forEach(line => {
      this.observer.unobserve(line);
    });
  }

  disconnect() {
    this.observer.disconnect();
  }

  callback(entries: IntersectionObserverEntry[], _: IntersectionObserver) {
    // const $$ = document.getElementById;
    // const $notationListPanel = document.getElementById('NOTATION_LIST_PANEL');
    // const $backToTop = document.getElementById('BACK_TO_TOP');
    // const $backToBottom = document.getElementById('BACK_TO_BOTTOM');
    entries.forEach(entry => {
      if (entry.target.classList.contains('current')) {
        const entry = entries[0];
        if (entry.isIntersecting) {
          this.setIsCurrentSentenceVisible(true);
          // if (!this.model.selectedNotationElement) {
          //   $notationListPanel?.classList.remove('hidden');
          // }
          // $backToTop?.classList.remove('visible');
          // $backToBottom?.classList.remove('visible');
        } else {
          this.setIsCurrentSentenceVisible(false);
          // $notationListPanel?.classList.add('hidden');
          // if (entry.intersectionRect.top < 0) {
          //   $backToBottom?.classList.add('visible');
          // } else {
          //   $backToTop?.classList.add('visible');
          // }
          // console.log(entry);
        }
      }
    });
  }
}

export const sentenceScrollObserver = new SentenceScrollObserver();

// (window as any).sentenceScrollObserver = sentenceScrollObserver;

/* Puts the current sentence in the middle of the screen – we do this during playback */
/// this function will end up doing a lot more than just centering the sentence
/// but I'm keeping the name for now
export function scrollToLine(line: HTMLElement) {
  const scriptPanel = document.getElementById(SCRIPT_SCROLLING_CONTAINER_ID);
  if (scriptPanel) {
    // const sentence = document.getElementById(sentenceId);
    if (line) {
      /// we need to give time for the notation panel to be mounted
      /// otherwise it would point to the wrong thing.
      /// TODO: find a better place for this
      setTimeout(() => {
        const notationPanel = document.getElementById('NOTATION_LIST_PANEL');
        if (notationPanel) {
          const top = scriptPanel.offsetHeight - notationPanel.offsetHeight - 8;
          scriptPanel.style.setProperty('--top-notation-pos', `${top}px`);
        }

        let scrollTop: number;

        /// if there's a notationPanel in the DOM we scroll so the sentence
        /// is atop the notation panel
        if (notationPanel) {
          scrollTop = Math.min(
            line.offsetTop -
              scriptPanel.offsetHeight +
              line.offsetHeight +
              notationPanel.offsetHeight +
              8,
            line.offsetTop - 8
          );
          /// otherwise we scroll so the sentence is in the middle of the screen
        } else {
          scrollTop =
            line.offsetTop - (scriptPanel.offsetHeight - line.offsetHeight) / 2;
        }

        scriptPanel.scrollTo({
          top: scrollTop,
          behavior: 'smooth',
        });
      }, 25);
    }
  }
}

export function scrollToCurrentLine() {
  const currentLine = document.querySelector('.line.current');
  scrollToLine(currentLine as HTMLElement);
}
