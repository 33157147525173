/// @joseph I don't think we're using this anywhere
/// Do you know if we still use loggly somewhere?
/// I deleted it and nothing happened, but I'm leaving it here for now
// custom component base on https://github.com/irvined1982/react-loggly/blob/master/src/lib/components/ReactLoggly.js
import { getConfig } from 'app/env';
// import { logglyTags as contextTags } from 'common/error-reporting';

const sessionId = getConfig('sessionId');

const token = getConfig('logging.logglyToken');

const LOGGLY_COLLECTOR_DOMAIN = 'logs-01.loggly.com';

let activated = false;

export const enabled = getConfig('logging.enableLoggly');

export const isActivated = () => activated;

export const toggleActivation = () => {
  activated = !activated;
};

// poor man's logging multiplexer
const enableConsoleLog = getConfig('logging.enableConsoleLog');

class Loggly {
  constructor(appToken) {
    this.token = appToken;
    this.tokenNotPresent = false;
  }

  /**
   * Log to our services
   * @param {string | object } data - data we want to send
   * @param {Array<string>} customTags - custom tags we can add
   */
  log = (data, customTags = []) => {
    if (enableConsoleLog) {
      // eslint-disable-next-line no-console
      console.log(data);
    }

    if (!activated) {
      return;
    }

    // this will prevent to pollute our console.log in dev
    // we dont want to see "No loggly token present" all around our logs
    if (this.tokenNotPresent) {
      return;
    }

    if (!this.token) {
      this.tokenNotPresent = true;
      // eslint-disable-next-line no-console
      console.log('No loggly token present');
      return;
    }

    // Don't log if the message is not an object or pure string.
    const type = typeof data;
    if (!data || !(type === 'object' || type === 'string')) {
      // eslint-disable-next-line no-console
      console.log(`Can't log ${type} of message to loggly. Skipping`);
      return;
    }

    // if message is a string we send it inside a key text else if object
    // send it like this
    const message = type === 'string' ? { text: data } : data;

    // Add our unique Session ID
    message.sessionId = sessionId;

    const tags = [];
    if (typeof message.tags === 'string') {
      tags.push(message.tags);
    }
    if (Array.isArray(message.tags)) {
      tags.push(...message.tags);
    }
    if (Array.isArray(customTags)) {
      tags.push(...customTags);
    }

    // todo: resurrect
    // tags.push(...contextTags());

    const tag = tags.join(); // do we really join all the tags without spaces and append to the end of the url?
    message.tags = tags;

    // Build the url from the token and tags
    const logglyUrl = `https://${LOGGLY_COLLECTOR_DOMAIN}/inputs/${this.token}/tag/${tag}`;

    try {
      // Write it and catch all exceptions.
      const xmlHttp = new XMLHttpRequest();
      xmlHttp.open('POST', logglyUrl, true);
      xmlHttp.setRequestHeader('Content-Type', 'text/plain');
      xmlHttp.send(JSON.stringify(message));
    } catch (ex) {
      // eslint-disable-next-line
      console.log(`Failed to log to loggly because of this exception:\n ${ex}`);
      // eslint-disable-next-line
      console.log('Failed log data:', message);
    }
  };
}

export default new Loggly(token);
