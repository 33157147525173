import React from 'react';
import __ from 'core/lib/localization';
import { MarkdownContainer } from 'components/ds/common/markdown-container';
import styled from 'styled-components';
import { ChapterCatalogData } from 'core/models/catalog';

const Wrapper = styled.div`
  display: flex;
  margin-bottom: 56px;

  .notes {
    @media print, screen and (min-width: 700px) {
      display: flex;
      flex: 9;
    }

    background-color: var(--very-light-yellow);
    border-radius: 8px;
    padding: 16px 0;

    & > h3 {
      flex: 1;
      padding: 0 16px;
      font: var(--compact-script-font);
      font-style: italic;
    }
  }

  .note {
    padding: 0 16px;
    flex: 8;

    @media print, screen and (min-width: 700px) {
      padding-left: 0;
    }

    & > .content {
      margin-bottom: 24px; /** space between multiple notes */

      &:last-child {
        margin-bottom: 0;
      }

      p {
        font: var(--compact-script-font);
      }

      strong {
        font-weight: 600;
      }
    }
  }

  .spacer {
    flex: 3;
  }
`;

export const Notes = ({ chapter }: { chapter: ChapterCatalogData }) => {
  const { notes } = chapter;
  if (notes.length === 0) {
    return null;
  }

  return (
    <Wrapper>
      <div className="notes">
        <h3>{__('Notes', 'common.notes')}</h3>
        <div className="note">
          {notes.map(note => {
            // const text = note.head
            //   ? __('**%{head}—** %{body}', 'storyGuide.noteMarkdown', note)
            //   : note.body;

            return (
              <div className="content">
                <MarkdownContainer source={note.body} />
              </div>
            );
          })}
        </div>
      </div>
      <div className="spacer" />
    </Wrapper>
  );
};
