import React from 'react';
import * as Dialog from '@naan/primitives/modals';
import { observer } from 'mobx-react';
import { ExcerptData } from 'tikka/client/catalog-types';
import { Question } from './question';
import { Answer } from './answer';
import { Vocabulary } from './vocabulary';
import { Story } from '@core/models/story-manager/story';
import { styled } from '@naan/stitches.config';
import __ from '@core/lib/localization';
import { borderRadius } from 'polished';
import { PlainMarkdown, Text } from '@naan/primitives/text';
import { MarkdownContainer } from 'components/ds/common/markdown-container';
import { VSpacer } from '@naan/primitives/spacer';

const SmallDialogHeading = styled('div', {
  textStyle: 'small-text',
  color: '$textSecondary',
  margin: '16px 16px 0',
  paddingBottom: 16,
  borderBottom: '1px solid $colors$gray-100',
  display: 'flex',
  justifyContent: 'center',
});

const StoryHeaderWrapper = styled('div', {
  display: 'flex',
  flexDirection: 'row',
  gridGap: 16,
  '& .thumb': {
    '& > img': {
      display: 'block',
      width: 80,
      height: 80,
      borderRadius: 8,
    },
    marginRight: 16,
  },
  '& .info': {
    '& .title': {
      textStyle: 'small-heading',
      display: 'block',
    },
    '& .meta': {
      textStyle: 'small-text',
      color: '$textSecondary',
      display: 'block',
    },
  },
});

export const SoundbiteStoryInfoModal = observer(
  ({
    excerpt,
    story,
    onDismiss,
  }: {
    excerpt: ExcerptData;
    story: Story;
    onDismiss: () => void;
  }) => {
    return (
      <Dialog.Container onDismiss={onDismiss} open>
        <SmallDialogHeading>
          {__(
            'This Soundbite was selected from',
            'soundbite.thisSoundbiteWasSelectedFrom'
          )}
        </SmallDialogHeading>
        <Dialog.Body>
          <Dialog.Scroller>
            <StoryHeaderWrapper>
              <div className="thumb">
                <img src={story.listImageUrl} alt={story.title} />
              </div>
              <div className="info">
                <div className="title">
                  <PlainMarkdown source={story.title}></PlainMarkdown>
                </div>
                <div className="meta">
                  {story.countries.join(', ')} • {story.durationDescription}
                </div>
              </div>
            </StoryHeaderWrapper>
            <VSpacer size={4}></VSpacer>
            <Text textStyle={'body-bold'}>
              <PlainMarkdown source={story.tagline} />
            </Text>
            <VSpacer size={4}></VSpacer>
            <Text textStyle={'body'} color="textSecondary">
              <PlainMarkdown source={story.description} />
            </Text>
            <VSpacer size={4}></VSpacer>
            <Text textStyle={'body'} color="textSecondary">
              <i>
                {__(
                  'Get the full story in the Lupa app',
                  'soundbites.getTheFullStory'
                )}
              </i>
            </Text>
            <VSpacer size={2}></VSpacer>
          </Dialog.Scroller>
        </Dialog.Body>
        <Dialog.ButtonsContainer>
          <Dialog.Button
            label={'Close'}
            presentation="grayLight"
            type="button"
            onClick={onDismiss}
            fullWidth
          />
        </Dialog.ButtonsContainer>
      </Dialog.Container>
    );
  }
);
