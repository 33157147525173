import * as React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { styled } from 'naan/stitches.config';
import { Story } from 'core/models/story-manager';
// import * as Layout from './volume-card-layout';
// import { VolumeCardMeta } from './volume-card-meta';
// import { ResponsiveTeacherVolumeCardHeaderControls } from './volume-card-header-controls';
// import { TeacherMeta } from './teacher-meta';
// import { VolumeTagLinks } from './volume-tag-links';
import { observer } from 'mobx-react';

const Link = styled(RouterLink, {
  color: 'inherit',
  fontSize: 'inherit',
  fontFamily: 'inherit',
  textDecoration: 'none',
});

const Title = styled('h2', {
  textStyle: 'small-heading',
  color: '$colors$textPrimary',
  margin: '-8px 0',
  padding: '8px 0',
  'span.suffix': {
    marginLeft: '$space$1',
    color: '$colors$textSecondary',
  },
  '&:hover': {
    color: '$colors$teal-500',
  },
});

export const StoryCard = observer(
  ({
    story,
    truncateDescription = false,
  }: {
    story: Story;
    truncateDescription?: boolean;
  }) => {
    const permalink = `../stories/${story.slug}`;
    const title = story.baseTitle;
    const titleSuffix = story.titleSuffix;

    // const description = story.description;
    // const thumbUrl = story.listImageUrl;

    return (
      <Link to={permalink}>
        <Title data-test-id="card-title">
          {title}
          {titleSuffix ? <span className="suffix">{titleSuffix}</span> : null}
        </Title>
      </Link>
    );

    // return (
    //   <Layout.Wrapper>
    //     <Layout.Figure to={permalink}>
    //       <Layout.Thumbnail src={thumbUrl} alt={title} />
    //     </Layout.Figure>
    //     <Layout.HeaderWrapper>
    //       <Layout.Header>
    //         <Link to={permalink}>
    //           <Title data-test-id="card-title">
    //             {title}
    //             {titleSuffix ? (
    //               <span className="suffix">{titleSuffix}</span>
    //             ) : null}
    //           </Title>
    //         </Link>
    //         <Layout.Meta>
    //           <VolumeCardMeta story={story} />
    //         </Layout.Meta>
    //       </Layout.Header>
    //       <Layout.HeaderTools>
    //         <ResponsiveTeacherVolumeCardHeaderControls
    //           onFavorite={favoriteToggle}
    //           onClassAssignment={onClassAssignment}
    //           isFavorited={story.isFavorited}
    //           badgeCount={story.assignCount}
    //         />
    //       </Layout.HeaderTools>
    //     </Layout.HeaderWrapper>
    //     <Layout.Description className={truncateDescription ? 'truncate' : ''}>
    //       <Markdown
    //         source={description}
    //         css={{ textStyle: 'small-text', color: '$textSecondary' }}
    //       />
    //     </Layout.Description>
    //     <Layout.TeacherTags>
    //       <TeacherMeta story={story} />
    //       <VolumeTagLinks tags={story.allTags} />
    //     </Layout.TeacherTags>
    //   </Layout.Wrapper>
    // );
  }
);
