import * as React from 'react';
import { LinkTag, TagsContainer } from 'naan/primitives/tags';
import { Link as RouterLink } from 'react-router-dom';
import { makeFilterLink } from 'common/filtering/make-filter-link';
import { MappedTag } from 'core/models/catalog/unit-catalog-data';

type VolumeTagLinksProps = {
  tags: MappedTag[];
  linkPrefix?: string;
  mode?: 'light' | 'dark';
};

const mapTagTypeToFilterType = {
  topic: 'topics',
  country: 'countries',
} as const;

export const VolumeTagLinks = ({
  tags,
  mode,
  linkPrefix = '/discover',
}: VolumeTagLinksProps) => {
  return (
    <TagsContainer>
      {tags.map((tag, index) => (
        <LinkTag
          as={RouterLink}
          key={index}
          type={tag.type}
          mode={mode}
          to={makeFilterLink(linkPrefix, mapTagTypeToFilterType[tag.type], [
            tag.label,
          ])}
        >
          {tag.label}
        </LinkTag>
      ))}
    </TagsContainer>
  );
};
