import * as React from 'react';
import { observer } from 'mobx-react';
import { VStack } from 'naan/primitives/stack';
import { Story } from 'core/models/story-manager';
import { VSpacer } from 'naan/primitives/spacer';
import { ActionLink } from '@common/ui/action-link';
import { isEmpty } from 'lodash';
import useSWR from 'swr';
import { AppFactory } from 'app/app-factory';
import { createLogger } from 'app/logger';
import { Notation } from '@tikka/client/client-aliases';
// import { Markdown } from '@naan/primitives/text/markdown';

const log = createLogger('selected-vocabs');

const fetcher = (url: string): Promise<Story> =>
  AppFactory.root.storyManager.loadVolumeDataUrl(url);

export const SelectedVocabs = observer(({ story }: { story: Story }) => {
  const { progress } = story;

  if (isEmpty(progress.vocabs)) {
    return null;
  }

  log.debug('volumeDataUrl: ', story.volumeDataUrl);
  let { data, error } = useSWR<Story, any, any>(story.volumeDataUrl, fetcher);

  if (data === undefined && !error) {
    return <div className="App">loading...</div>;
  }

  if (error) {
    return <div className="App">{String(error)}</div>;
  }

  if (data == null) {
    return <div className="App">no volume data</div>;
  }

  const volumeDataStory: Story = data;

  const removeVocab = (slug: string) => {
    progress.removeVocab(slug);
  };

  return (
    <VStack>
      <h3>Selected vocabs</h3>
      {progress.vocabs.map(slug => {
        let vocab = volumeDataStory.vocab(slug);
        if (!vocab) {
          // hack missing data
          vocab = { id: slug, headword: slug } as Notation;
        }
        return (
          <div key={slug}>
            <strong>{vocab.headword}</strong> {vocab.note}{' '}
            <ActionLink
              onPress={() => removeVocab(slug)}
              title="[Remove vocab]"
            />
            <br />
            <VSpacer size={2} />
          </div>
        );
      })}
    </VStack>
  );
});
