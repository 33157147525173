import { ChapterCatalogData } from '@core/models/catalog';
import { OverflowIcon } from '@naan/icons/overflow-icon';
import { Button, IconButton } from '@naan/primitives/button';
import { Menu, MenuItem } from '@naan/primitives/menus';
import { observer } from 'mobx-react';
import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import { ChapterListItemPresentation } from './chapter-list-item-presentation';
import { PlayerMode } from '@common/misc-types';
import { EyeIcon } from '@naan/icons/eye-icon';
import { EarIcon } from '@naan/icons/ear-icon';
import { CheckmarkHeavyIcon } from '@naan/icons/checkmark-heavy-icon';
import { ReviewIcon } from '@naan/icons/review-icon';
import { ArrowRightIcon } from '@naan/icons/arrow-right-icon';

type ChapterListItemPresentationProps = React.ComponentProps<
  typeof ChapterListItemPresentation
>;

interface ChapterProp {
  chapter: ChapterCatalogData;
}

const ChapterListItemPresentationWrapper = (
  props: ChapterListItemPresentationProps & ChapterProp
) => {
  const { chapter, ...rest } = props;
  return (
    <ChapterListItemPresentation
      hideLeadingLine={!chapter.hasPreviousUnitChapter}
      hideTrailingLine={!chapter.hasNextUnitChapter}
      {...rest}
    />
  );
};

export const ChapterListItem = observer(
  ({ chapter }: { chapter: ChapterCatalogData }) => {
    // temporary short-circuit of catalog data without jwnext ingestion
    if (!chapter.playerDataUrl) {
      return (
        <ChapterListItemPresentationWrapper
          chapter={chapter}
          number={chapter.position}
          title={chapter.title}
        />
      );
    }

    if (chapter.unstarted) {
      return <Locked chapter={chapter} />;
    }

    if (chapter.isCurrent) {
      if (chapter.isFurthest) {
        return <CurrentAndFurthest chapter={chapter} />;
      } else {
        return <CurrentNotFurthest chapter={chapter} />;
      }
    } else {
      // not current
      if (chapter.isFurthest) {
        return <FurthestNotCurrent chapter={chapter} />;
      }
      if (chapter.isCompleted) {
        return <Completed chapter={chapter} />;
      }
      return <Unvisited chapter={chapter} />;
    }
  }
);

// when story not yet started
export const Locked = observer(
  ({ chapter }: { chapter: ChapterCatalogData }) => {
    return (
      <ChapterListItemPresentationWrapper
        chapter={chapter}
        number={chapter.position}
        title={chapter.title}
      />
    );
  }
);

export const Unvisited = observer(
  ({ chapter }: { chapter: ChapterCatalogData }) => {
    const overflowMenu = (
      <Menu
        trigger={
          <IconButton presentation="grayTransparent" icon={<OverflowIcon />} />
        }
      >
        <MenuItem label="Skip to chapter" action={() => chapter.unlock()} />
      </Menu>
    );

    return (
      <ChapterListItemPresentationWrapper
        chapter={chapter}
        number={chapter.position}
        title={chapter.title}
        presentation={'unvisited'}
        overflowMenu={overflowMenu}
      />
    );
  }
);

export const Completed = observer(
  ({ chapter }: { chapter: ChapterCatalogData }) => {
    const navigate = useNavigate();
    const basePath = `study/${chapter.position}`; // factor with below function
    const reviewPath = `${basePath}?stage=${PlayerMode.FLUENT_LISTEN}`;

    return (
      <ChapterListItemPresentationWrapper
        chapter={chapter}
        number={chapter.position}
        title={chapter.title}
        durationText={chapter.prettyDuration}
        reviewAction={() => {
          chapter.review();
          navigate(reviewPath);
        }}
        checked={true}
      />
    );
  }
);

export const CurrentAndFurthest = observer(
  ({ chapter }: { chapter: ChapterCatalogData }) => {
    const { unitData } = chapter;
    const basePath = `study/${chapter.position}`;
    const ms = chapter.currentMillis;
    const midListen = ms > 0;
    // const buttonProgressPercent = Math.round(
    //   (100 * ms) / chapter.durationMillis
    // );

    let studyIcon;
    let studyLabel;
    let studyPresentation = 'teal';
    let studyAction: () => void = undefined;
    let doneButton: React.ReactNode = undefined;

    const navigate = useNavigate();

    if (!chapter.isFirstListenComplete) {
      studyIcon = <EyeIcon />;
      studyAction = () => {
        navigate(`${basePath}?stage=${PlayerMode.STUDY}&ms=${ms}`);
      };
      studyLabel = midListen ? 'Continue listen' : 'Begin chapter';
    } else {
      studyIcon = <EarIcon />;
      studyAction = () => {
        navigate(`${basePath}?stage=${PlayerMode.FLUENT_LISTEN}&ms=${ms}`);
      };

      if (!chapter.isFluentListenComplete) {
        studyLabel = midListen ? 'Continue listen' : 'Relisten';
      } else {
        // is complete
        studyLabel = midListen ? 'Continue listen' : 'Listen again';
        studyPresentation = midListen ? 'teal' : 'secondary';
        const doneAction = () => {
          chapter.markComplete();
        };
        doneButton = (
          <Button
            key="done"
            leftIcon={<CheckmarkHeavyIcon />}
            label={'Mark as done'}
            onClick={doneAction}
            presentation={midListen ? 'secondary' : 'teal'}
            size="large"
          />
        );
      }
    }
    const studyButton = (
      <Button
        key="study"
        leftIcon={studyIcon}
        label={studyLabel}
        onClick={studyAction}
        presentation={studyPresentation as any}
        size="large"
      />
    );

    const buttons: React.ReactNode[] = [studyButton];
    if (doneButton) {
      buttons.push(doneButton);
    }

    const overflowMenu = (
      <Menu
        trigger={
          <IconButton presentation="grayTransparent" icon={<OverflowIcon />} />
        }
      >
        {chapter.isStarted ? (
          <MenuItem
            label="Restart chapter..."
            action={
              () =>
                chapter.restartChapter() /* todo: open confirmation dialog */
            }
          />
        ) : null}
        {chapter.isCompleted ? null : (
          <MenuItem
            label="Mark chapter complete"
            action={() => chapter.markComplete()}
          />
        )}
      </Menu>
    );

    return (
      <ChapterListItemPresentationWrapper
        chapter={chapter}
        number={chapter.position}
        title={chapter.title}
        presentation="current"
        durationText={chapter.prettyDuration}
        breadcrumbState={chapter.currentPoint.iteration}
        overflowMenu={overflowMenu}
        buttons={buttons}
      />
    );
  }
);

// special review mode
export const CurrentNotFurthest = observer(
  ({ chapter }: { chapter: ChapterCatalogData }) => {
    // factor this with above
    const { unitData } = chapter;
    const basePath = `study/${chapter.position}`;
    const ms = chapter.currentMillis;

    const midListen = ms > 0;
    // const buttonProgressPercent = Math.round(
    //   (100 * ms) / chapter.durationMillis
    // );

    const navigate = useNavigate();
    const studyAction = () => {
      navigate(`${basePath}?stage=${PlayerMode.FLUENT_LISTEN}&ms=${ms}`);
    };

    const studyLabel = midListen ? 'Continue review' : 'Review';
    const studyButton = (
      <Button
        leftIcon={<ReviewIcon />}
        label={studyLabel}
        onClick={studyAction}
        presentation={'teal'}
        size="large"
      />
    );

    const overflowMenu = (
      <Menu
        trigger={
          <IconButton presentation="grayTransparent" icon={<OverflowIcon />} />
        }
      >
        <MenuItem
          label="Restart study from here..."
          action={
            () => chapter.restartChapter() /* todo: open confirmation dialog */
          }
        />
      </Menu>
    );

    return (
      <ChapterListItemPresentationWrapper
        chapter={chapter}
        number={chapter.position}
        title={chapter.title}
        presentation="current"
        durationText={chapter.prettyDuration}
        checked={true}
        overflowMenu={overflowMenu}
        buttons={[studyButton]}
      />
    );
  }
);

export const FurthestNotCurrent = observer(
  ({ chapter }: { chapter: ChapterCatalogData }) => {
    const resumeButton = (
      <Button
        leftIcon={<ArrowRightIcon />}
        label={'Resume study'}
        onClick={() => chapter.resumeStudy()}
        presentation={'secondary'}
        size={'large'}
      />
    );

    return (
      <ChapterListItemPresentationWrapper
        chapter={chapter}
        number={chapter.position}
        title={chapter.title}
        durationText={chapter.prettyDuration}
        buttons={[resumeButton]}
      />
    );
  }
);
