import * as React from 'react';
import { Button } from 'naan/primitives/button';
import { ArrowLeftSmallIcon } from 'naan/icons/arrow-left-icon';
import { ArrowRightSmallIcon } from 'naan/icons/arrow-right-icon';
import { styled } from 'naan/stitches.config';

// const { colors } = theme;

const Table = styled('table', {
  width: 'fit-content',
  alignSelf: 'flex-start',
  // background: 'red',
  '&  .presentation-white': {
    backgroundColor: '#666',
  },

  '& th': {
    textAlign: 'center',
  },

  '&  td': {
    padding: '0.5rem',
    '& > div': {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      '& > * + *': {
        marginTop: '0.5rem',
      },
    },
  },
});

const presentations = [
  'gray',
  'grayLight',
  'teal',
  'tealLight',
  'green',
  'yellow',
  'blue',
  'redLight',
  'white',
  'tealTransparent',
  'grayTransparent',
];

const sizes = ['small', 'medium', 'large'];

// const AllButtonsAtOnce = () => {
//   return (
//     <>
//       <VStack>
//         {/* <Text textStyle="medium-heading" as="h2">
//           Icon Buttons
//         </Text> */}

//         {/* <HStack>
//           {['default', 'outlined', 'white-outlined', 'solid', 'activated'].map(
//             type => (
//               <VStack>
//                 <Text textStyle="small-text">{type}</Text>
//                 {['extraSmall', 'small', 'medium', 'large'].map(size => (
//                   <div
//                     style={{
//                       width: 100,
//                       height: 100,
//                       display: 'flex',
//                       justifyContent: 'center',
//                       alignItems: 'center',
//                       backgroundColor:
//                         type === 'white-outlined'
//                           ? colors['gray-500']
//                           : colors['transparent'],
//                     }}
//                   >
//                     <IconButton
//                       size={size}
//                       icon={<ArrowRightSmallIcon />}
//                       presentation={type}
//                     />
//                   </div>
//                 ))}
//               </VStack>
//             )
//           )}
//         </HStack> */}

//         <Text textStyle="medium-heading" as="h2">
//           Buttons
//         </Text>

//         <Table>
//           <tbody>
//             <tr>
//               <th></th>
//               {presentations.map(presentation => (
//                 <th>{presentation}</th>
//               ))}
//             </tr>
//             {sizes.map(size => (
//               <tr>
//                 <th>{size}</th>
//                 {presentations.map(presentation => (
//                   <td>
//                     <div>
//                       <Button
//                         presentation={presentation}
//                         size={size}
//                         label="Label"
//                       />
//                       <Button
//                         disabled
//                         presentation={presentation}
//                         size={size}
//                         label="Label"
//                       />
//                     </div>
//                   </td>
//                 ))}
//               </tr>
//             ))}
//           </tbody>
//         </Table>

//         {/* <Text textStyle="medium-heading" as="h2">
//           Buttons with icons
//         </Text>

//         <HStack>
//           {presentations.map(type => (
//             <VStack gap="large">
//               {sizes.map(size => (
//                 <>
//                   <Button
//                     leftIcon={<ArrowLeftSmallIcon />}
//                     presentation={type}
//                     size={size}
//                     label="Label"
//                   />
//                   <Button
//                     rightIcon={<ArrowRightSmallIcon />}
//                     presentation={type}
//                     size={size}
//                     label="Label"
//                   />
//                 </>
//               ))}
//             </VStack>
//           ))}
//         </HStack> */}
//       </VStack>
//     </>
//   );
// };

const AllButtonsAtOnce = () => {
  return (
    <Table>
      <thead>
        <tr>
          <th></th>
          {sizes.map(size => (
            <th colSpan={2}>{size}</th>
          ))}
        </tr>
      </thead>
      <tbody>
        {presentations.map(presentation => (
          <tr className={`presentation-${presentation}`}>
            <th>{presentation}</th>
            {sizes.map(size => (
              <>
                <td>
                  <div>
                    <Button
                      presentation={presentation as any}
                      size={size as any}
                      label="Label"
                    />
                    <Button
                      disabled
                      presentation={presentation as any}
                      size={size as any}
                      label="Label"
                    />
                  </div>
                </td>
                <td>
                  <div>
                    <Button
                      presentation={presentation as any}
                      size={size as any}
                      label="Label"
                      leftIcon={<ArrowLeftSmallIcon />}
                    />
                    <Button
                      presentation={presentation as any}
                      size={size as any}
                      label="Label"
                      rightIcon={<ArrowRightSmallIcon />}
                    />
                  </div>
                </td>
              </>
            ))}
          </tr>
        ))}
      </tbody>
    </Table>
  );
};

export const pathname = '/buttons';
export const navlabel = 'Buttons';
export const naanReady = true;

export const Screen = () => {
  return <AllButtonsAtOnce />;
};
