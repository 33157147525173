import React from 'react';
// import { DialogTarget } from 'components/ui/dialog-target';
// import { LoadingScreen } from 'components/ui/loading-screen';
// import { BrowserRouter as Router } from 'react-router-dom';
// import { Switch, Route } from 'react-router-dom';
import { SoundbitesBetaRoutes, SpaRoutes } from 'routes';
// import { StripeProvider } from 'common/stripe-context';
// import { NewsletterDialog } from 'components/account/newsletter-dialog';
// import { NotFoundScreen } from 'routes/not-found-screen';
// import { ShowcaseScreen } from './routes/showcase/showcase-screen';
import { CookiesRequired } from 'components/layouts/cookies-required';
// @ts-expect-error
import { versionCheck } from '@jw-spa-version';
import { checkForUpdate } from 'lib/update-checker';
import { soundbitesMode } from 'lib/platform';

// import { StoryGuideScreen } from 'routes/story-guide-screen';
// import { BasicScriptScreen } from 'routes/simple-script-screen';

// window.versionCheck = versionCheck;
// console.log(`versionCheck: ${versionCheck}`);

const supportsLocalStorage = () => {
  try {
    localStorage.setItem('lupa.ls', '1');
    return true;
  } catch (error) {
    return false;
  }
};

const hasLocalStorage = supportsLocalStorage();

const App = () => {
  React.useEffect(() => {
    checkForUpdate(versionCheck);
  }, []);

  if (!hasLocalStorage) {
    return <CookiesRequired />;
  }

  if (soundbitesMode) {
    return <SoundbitesBetaRoutes />;
  }

  return <SpaRoutes />;
};

export default App;
