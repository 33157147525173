import { get, set } from 'lodash';
import deepmerge from 'deepmerge';
import { env as baseEnv } from './env';
/// note: @jw-env is not a real file or package,
/// we have a custom plugin that will resolve it in realtime to
/// the contents of `env.${process.env.VITE_CONFIG_ENV}.js`;
/// That's how we are solving env specific configuration.
//@ts-ignore
import overrideEnv from '@jw-env';

// console.log('config/index');

type TConfig = typeof baseEnv;
/// had to add "string" here for lodash-style get accessors.
/// at least we get _some_ type safety.
type TConfigKey = keyof TConfig | string;

export const config = deepmerge(baseEnv, overrideEnv as Partial<TConfig>);

export const getConfig = (key: TConfigKey, defaultValue: any = null) => {
  return get(config, key, defaultValue);
};

export const setConfig = (key: TConfigKey, value: any) => {
  return set(config, key, value);
};

// todo: reassess if the 'env' export can be retired (currently needed by 'core')
export const env = { get: getConfig, set: setConfig };

export default config;

(window as any).jw_config = config;
(window as any).getConfig = getConfig;
(window as any).setConfig = setConfig;
