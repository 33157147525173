import * as React from 'react';
import { styled } from 'naan/stitches.config';
import { BarChart } from './bar-chart';
import { TotalPoints } from './total-points';
import { Text } from 'naan/primitives/text';
import { AppFactory } from 'app/app-factory';
import __ from 'core/lib/localization';
import { observer } from 'mobx-react';
import { VSpacer } from 'naan/primitives/spacer';

const Container = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  borderRadius: '12px',
  width: '100%',
  '@large': {
    width: '50%',
  },
  backgroundColor: '$yellow-100',
  padding: '$space$5 $space$6 $space$4 $space$6',
  '@small': {
    padding: '$space$7 $space$8 $space$6 $space$8',
  },
});

const Header = styled('div', {
  marginBottom: '$space$10',
});

export const PointsProgressChart = observer(() => {
  const { userData } = AppFactory.root.userManager;
  const viewData = userData.meView;

  return (
    <Container>
      <Header>
        <Text textStyle={'small-heading'}>
          {__('Last 14 days', 'progress.twoWeeksProgressTitle')}
        </Text>
        <VSpacer size={1} />
        <TotalPoints points={viewData.totalPoints} />
      </Header>
      <BarChart viewData={viewData} />
    </Container>
  );
});
