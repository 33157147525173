import * as React from 'react';
import { styled } from 'naan/stitches.config';
import arrowImage from 'naan/primitives/menus/dropdown-arrow.png';
import * as DropdownMenu from '@radix-ui/react-dropdown-menu';
import {
  LinkProps,
  useMatch,
  useNavigate,
  useResolvedPath,
} from 'react-router-dom';

export const ArrowImage = styled('img', {
  position: 'relative',
  top: '-3px',
  right: '20px',
  '@small': {
    right: '0',
    marginBottom: '8px',
  },
  '@medium': {
    marginBottom: '0',
  },
});

export const NavArrow = () => (
  <ArrowImage
    src={arrowImage}
    alt=""
    role="presentation"
    width={24}
    height={16}
  />
);

export const NavLogoWrapper = styled('div', {
  paddingLeft: 24,
  paddingTop: 28,
  paddingBottom: 28,
  display: 'none',
  color: '$$logoColor',
  '@large': {
    display: 'block',
  },
});

const NavContainerWrapper = styled('nav', {
  $$logoColor: '$colors$gray-800',
  $$navTextColor: '$colors$gray-400',
  $$highlightColor: '$colors$teal-500',
  $$backgroundColor: '$colors$gray-25',
  $$height: '$$tabbarHeight',
  $$spacing: 0,

  backgroundColor: '$$backgroundColor',
  height: '$$height',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',

  flex: 1,
  flexDirection: 'row',
  margin: '0 auto',

  '& > * + *': {
    marginLeft: '$$spacing',
  },

  '@medium': {
    $$spacing: '56px',
  },

  '@large': {
    $$height: '100%',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    flexDirection: 'column',
    $$spacing: 0,
  },

  variants: {
    presentation: {
      classroom: {
        $$backgroundColor: '$colors$gray-800',
        $$navTextColor: '$colors$white',
        $$highlightColor: '$colors$yellow-400',
        $$logoColor: '$colors$white',
      },
    },
  },
});

export const NavContainer: React.FC<
  React.ComponentProps<typeof NavContainerWrapper>
> = ({ children, ...props }) => (
  <NavContainerWrapper {...props}>{children}</NavContainerWrapper>
);

const NavItemWrapper = styled('span', {
  height: '$$height',
  listStyle: 'none',
  width: '80px',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  color: '$$navTextColor',
  textDecoration: 'none',
  paddingTop: '9px',

  '& .label': {
    textStyle: 'tiny-text',
    margin: '2px 0 0',
  },

  '& .icon': {
    lineHeight: 0,
  },

  '@medium': {
    width: 'auto',
    flexDirection: 'row',
    justifyContent: 'center',
    paddingTop: '0',

    '& .label': {
      textStyle: 'small-text-bold',
      margin: '0 0 0 10px',
    },
  },

  '@large': {
    height: 'auto',
    width: '100%',
    justifyContent: 'flex-start',
    cursor: 'pointer',
    padding: 12,
    paddingLeft: 24,

    '& .label': {
      textStyle: 'small-heading',
      marginLeft: '12px',
      marginTop: '0',
    },
  },

  variants: {
    active: {
      true: {
        color: '$$highlightColor',
      },
    },
  },
});

export const NavItem = ({
  active,
  icon,
  label,
  onClick,
}: {
  active?: boolean;
  icon: React.ReactChild;
  label: React.ReactChild;
  onClick?: () => void;
}) => {
  return (
    <NavItemWrapper onClick={onClick} active={active}>
      <span className="icon">{icon}</span>
      <span className="label">{label}</span>
    </NavItemWrapper>
  );
};

type NavItemProps = React.ComponentProps<typeof NavItem>;
export function LinkNavItem({
  to,
  beforeNavigate,
  ...props
}: Omit<NavItemProps, 'onClick'> &
  Pick<LinkProps, 'to'> & { beforeNavigate?: (to: string) => void }) {
  const navigate = useNavigate();
  const resolved = useResolvedPath(to);
  const match = useMatch({ path: resolved.pathname, end: true });

  return (
    <NavItem
      active={!!match}
      onClick={() => {
        if (beforeNavigate) {
          beforeNavigate(to as string);
        }
        navigate(to);
      }}
      {...props}
    />
  );
}

export const NavMenuTrigger = ({
  active,
  icon,
  label,
}: // onClick,
{
  active?: boolean;
  icon: React.ReactChild;
  label: string;
  // onClick: () => void;
}) => {
  return (
    <NavItemWrapper active={active}>
      <span className="icon">{icon}</span>
      <span className="label">{label}</span>
    </NavItemWrapper>
  );
};

export const NavBottomAnchor = styled('div', {
  '@large': {
    position: 'fixed',
    bottom: 16,
    left: 0,
    height: 'auto',
    width: '$$sidebarWidth',
  },
});

export const PopoverNavTrigger = styled(DropdownMenu.Trigger, {
  all: 'unset',
});

export const PopoverNavHeader = styled('div', {
  padding: '12px 16px',
  position: 'relative',
  '&::after': {
    position: 'absolute',
    content: '',
    left: '16px',
    right: '16px',
    bottom: 0,
    height: '1px',
    background: '$colors$gray-100',
  },
});

export const ExternalItemWrapper = styled('div', {
  padding: 32,
  paddingLeft: 24,
});
