import React from 'react';
import { observer } from 'mobx-react';
import { useNavigate } from 'react-router-dom';
import { AppFactory } from 'app/app-factory';
import { styled } from '@naan/stitches.config';
import { Button } from '@naan/primitives/button';
import { paletteKey } from './study-palette';
import { ClockIcon } from '@naan/icons/clock-icon';
import { PointsIcon } from '@naan/icons/points-icon';
import { CheckmarkCircleExtraExtraLargeIcon } from '@naan/icons/checkmark-circle-icon';
import { HeadphonesExtraExtraLargeIcon } from '@naan/icons/headphones-icon';

import __ from '@core/lib/localization';
import { VSpacer } from '@naan/primitives/spacer';
import { useMembershipContext } from 'player/views/membership-context';

const Wrapper = styled('div', {
  $$backgroundColor: paletteKey('$$endCard__backgroundColor'),
  $$primaryColor: paletteKey('$$endCard__primaryColor'),
  $$secondaryColor: paletteKey('$$endCard__secondaryColor'),
  $$dividerColor: paletteKey('$$endCard__dividerColor'),
  backgroundColor: '$$backgroundColor',
  color: '$$primaryColor',
  boxShadow: '0px 1px 4px rgba(0, 0, 0, 0.2)',
  borderRadius: '12px',
  padding: '0 16px 16px 16px',
  maxWidth: '600px',
  opacity: 0.3,
  transition: 'opacity 0.3s ease-out',

  '& > .heading': {
    display: 'flex',
    padding: '72px 0',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    textStyle: 'medium-heading',
    '& > .icon': {
      color: '$gray-400',
      '&.complete': {
        color: '$green-500',
      },
    },
  },

  '& > .row-title': {
    color: '$$secondaryColor',
    textStyle: 'tiny-text',
    textTransform: 'uppercase',
    borderTop: '1px solid $$dividerColor',
    paddingTop: '8px',
  },

  '& > .row': {
    display: 'flex',
    alignItems: 'flex-end',
    justifyContent: 'space-between',
    padding: '12px 0 8px 0',

    '& > .left': {
      display: 'flex',
      alignItems: 'center',
      textStyle: 'medium-heading',
      '& > .icon': {
        marginRight: '8px',
        '&.clock': {
          color: '$teal-500',
        },
        '&.points': {
          color: '$yellow-500',
        },
      },
    },
    '& > .right': {
      color: '$$secondaryColor',
    },
  },
  variants: {
    focused: {
      true: {
        opacity: 1,
      },
    },
  },
});

export const EndOfChapter = observer(() => {
  const navigate = useNavigate();
  const model = AppFactory.studyModel;

  const { chapter } = model;
  if (!chapter) {
    return <>(missing chapter data)</>; // at least during development
  }

  const { story } = chapter;
  if (!chapter) {
    return <>(missing story data)</>;
  }

  // factor this
  const storyPath = `./../..`;

  const onDone = () => {
    // updateProgress();
    model.pause(); // just in case hit while playing
    navigate(storyPath);
  };

  const completed = model.completionReached;
  const { lineMembershipReconciler: reconciler } = useMembershipContext();
  let className = reconciler.getJoinedMembershipStringForElement('OUTRO:');

  return (
    <Wrapper
      className={className}
      focused={model.completionReached && model.afterSpoken}
      id={'_OUTRO:'}
    >
      <h3 className="heading">
        {completed ? (
          <span className="complete icon">
            <CheckmarkCircleExtraExtraLargeIcon width={80} height={80} />
          </span>
        ) : (
          <span className="incomplete icon">
            <HeadphonesExtraExtraLargeIcon width={80} height={80} />
          </span>
        )}
        {completed
          ? __('Listen complete', 'player.listenComplete')
          : __('Listening…', 'player.listeningEllipsis')}
      </h3>
      <h5 className="row-title">
        {__('Time listened', 'player.timeListened')}
      </h5>
      <div className="row">
        <div className="left">
          <span className="clock icon">
            <ClockIcon />
          </span>
          {completed ? chapter.durationInWords : '--'}
        </div>
        <div className="right">{
          /*completed &&*/ `Story total: ${story.totalListenedInWords}`
        }</div>
      </div>
      <h5 className="row-title">
        {__('Points earned', 'player.pointsEarned')}
      </h5>
      <div className="row">
        <div className="left">
          <span className="points icon">
            <PointsIcon />
          </span>
          {completed ? chapter.progressPoints : '--'}
        </div>
        <div className="right">{
          /*completed &&*/ `Story total: ${story.totalPoints}`
        }</div>
      </div>
      <VSpacer size={3} />
      <Button
        label={__('Done', 'common.done')}
        onClick={onDone}
        presentation="teal"
        size="large"
        fullWidth
        disabled={!completed}
      />
    </Wrapper>
  );
});
