import * as DialogPrimitive from '@radix-ui/react-dialog';
import React from 'react';
import { keyframes, styled } from 'naan/stitches.config';
import { CloseSmallIcon } from 'naan/icons/close-icon';
import { IconButton, Button as NaanButton } from 'naan/primitives/button';
// import { isFunction } from 'lodash';

import { AlertTriangleIcon } from 'naan/icons/alert-triangle-icon';
import { AlertIcon } from 'naan/icons/alert-icon';
import { InfoCircleIcon } from 'naan/icons/info-circle-icon';
import { CheckmarkCircleIcon } from 'naan/icons/checkmark-circle-icon';

const showKeyframes = keyframes({
  '0%': { opacity: 0 },
  '100%': { opacity: 1 },
});

export const Overlay = styled(DialogPrimitive.Overlay, {
  backgroundColor: '$colors$black-alpha-20',
  position: 'fixed',
  inset: 0,
  '@media (prefers-reduced-motion: no-preference)': {
    animation: `${showKeyframes} 0.25s cubic-bezier(0.16, 1, 0.3, 1)`,
  },
});

const contentShow = keyframes({
  '0%': { opacity: 0, transform: 'translate(-50%, -48%) scale(.75)' },
  '100%': { opacity: 1, transform: 'translate(-50%, -50%) scale(1)' },
});

export const Root = DialogPrimitive.Root;
export const Portal = DialogPrimitive.Portal;
export const Close = DialogPrimitive.Close;

export const Content = styled(DialogPrimitive.Content, {
  $$maxWidth: '360px',
  backgroundColor: '$white',
  borderRadius: 12,
  boxShadow: `0px 1px 4px $colors$black-alpha-20`,
  position: 'fixed',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 'min(calc(100% - 32px), $$maxWidth)',
  // maxWidth: 'fit-content',
  maxHeight: '85vh',
  // padding: 16,
  '@media (prefers-reduced-motion: no-preference)': {
    animation: `${contentShow} 0.5s cubic-bezier(0.16, 1, 0.3, 1)`,
    willChange: 'transform',
  },
  '&:focus': { outline: 'none' },
});

export const Scroller = styled('div', {
  overflowY: 'auto',
  // background: 'red',
  maxHeight: 'clamp( 200px, 100vh - 240px, 600px)',
  '&::-webkit-scrollbar': {
    width: '4px',
  },
  '&:hover': {
    '&::-webkit-scrollbar-track': {
      position: 'relative',
      left: '-14px',
      borderRadius: '4px',
      background: '$colors$black-alpha-06',
    },
    '&::-webkit-scrollbar-thumb': {
      borderRadius: '4px',
      background: '$colors$black-alpha-10',
    },
  },
});

export const HeadingStyles = styled(DialogPrimitive.Title, {
  $$textColor: '$textPrimary',
  color: '$$textColor',
  textStyle: 'small-heading',
  padding: '$space$4',
  marginBottom: '-$space$4',
  display: 'flex',
  alignItems: 'center',

  '& > .icon': {
    lineHeight: 0,
    marginRight: '$space$1',
  },
  variants: {
    presentation: {
      success: {
        $$textColor: '$colors$green-600',
      },
      warning: {
        $$textColor: '$colors$yellow-700',
      },
      error: {
        $$textColor: '$colors$red-500',
      },
    },
  },
});

type HeadingStylesProps = React.ComponentProps<typeof HeadingStyles>;

export const Heading: React.FC<{ type?: HeadingStylesProps['presentation'] }> =
  ({ children, type }) => {
    const icon = React.useMemo(() => {
      switch (type) {
        case 'info':
          return <InfoCircleIcon />;
        case 'success':
          return <CheckmarkCircleIcon />;
        case 'warning':
          return <AlertIcon />;
        case 'error':
          return <AlertTriangleIcon />;
        default:
          break;
      }
      return null;
    }, [type]);

    return (
      <HeadingStyles presentation={type}>
        {icon ? <span className="icon">{icon}</span> : null}

        {children}
      </HeadingStyles>
    );
  };

export const Body = styled('div', {
  padding: '$space$4',
});

const DialogButton = styled(NaanButton, {
  width: '100%',
});

type ButtonProps = React.ComponentProps<typeof DialogButton>;
export const Button = (props: Omit<ButtonProps, 'size'>) => {
  return (
    /// This caused me so much pain. It was SO hard to find why the form was autoclosing
    // <DialogPrimitive.Close asChild>
    <DialogButton size={'large'} {...props} css={{ width: '30' }} />
    // </DialogPrimitive.Close>
  );
};

export const ButtonsContainer = styled('div', {
  display: 'flex',
  justifyContent: 'space-between',
  padding: '$space$4',
  marginTop: '-$space$4',
  variants: {
    direction: {
      row: {
        flexDirection: 'row',
      },
      column: {
        flexDirection: 'column',
        '& > *': {
          width: '100%',
        },
        '& * + *': {
          marginTop: '$space$2',
        },
      },
    },
  },
});

const CloseButtonContainer = styled('span', {
  all: 'unset',
  position: 'absolute',
  top: 8,
  right: 8,
});

export const CloseButton = () => {
  return (
    <DialogPrimitive.Close asChild>
      <CloseButtonContainer>
        <IconButton
          icon={<CloseSmallIcon />}
          size={'small'}
          type="button"
          tabIndex={-1}
        />
      </CloseButtonContainer>
    </DialogPrimitive.Close>
  );
};

export const LinkContainer = styled('span', {
  '& a': {
    textStyle: 'body',
    color: '$teal-500',
    textDecoration: 'underline',
  },
});

type RootProps = React.ComponentProps<typeof Root>;
type ContentProps = React.ComponentProps<typeof Content>;

type ContainerProps = { onDismiss: () => void } & Omit<
  RootProps,
  'onOpenChange'
> &
  Pick<ContentProps, 'css'>;

export const Container: React.FC<ContainerProps> = ({
  open,
  onDismiss,
  children,
  ...props
}) => {
  const handleOpenChange = React.useCallback(
    (open: boolean) => {
      if (open === false) {
        onDismiss();
      }
    },
    [onDismiss]
  );

  return (
    <Root open={open} onOpenChange={handleOpenChange}>
      <Portal>
        <Overlay css={{ zIndex: 1001 }}>
          <Content onInteractOutside={onDismiss} {...props}>
            {children}
          </Content>
        </Overlay>
      </Portal>
    </Root>
  );
};

export const PureContainer: React.FC<
  ContainerProps & { trigger: React.ReactNode; defaultOpen?: boolean }
> = ({ open, onDismiss, children, trigger, defaultOpen, ...props }) => {
  const handleOpenChange = React.useCallback(
    (open: boolean) => {
      if (open === false) {
        onDismiss();
      }
    },
    [onDismiss]
  );

  return (
    <Root onOpenChange={handleOpenChange} defaultOpen={defaultOpen}>
      <DialogPrimitive.Trigger asChild>{trigger}</DialogPrimitive.Trigger>
      <Portal>
        <Overlay css={{ zIndex: 1001 }}>
          <Content onInteractOutside={onDismiss} {...props}>
            {children}
          </Content>
        </Overlay>
      </Portal>
    </Root>
  );
};
