import * as React from 'react';
import { useLocation } from 'react-router-dom';
import { trackPage } from 'app/track';

function removeLeadingSlash(path: string) {
  return path.replace(/^\//, '') || '/'; // need at least a slash for tracking the root index view
}

export const usePageTracker = () => {
  const location = useLocation();
  // const store: any = useRootStore();

  /// track page changes automatically, whenever location changes
  React.useEffect(() => {
    // console.log(`usePageTracker : ${removeLeadingSlash(location.pathname)}`);
    /// this tracks the first page, just after the effect has mounted
    trackPage(removeLeadingSlash(location.pathname));
  }, [location]);
};
