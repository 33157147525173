import * as React from 'react';
import { SCRIPT_SCROLLING_CONTAINER_ID } from './player-ui-components';
import { getConfig } from 'app/env';
import { UaService } from 'lib/services/ua-service';

/// this is just to solve a bug in iPhone iOS 14 and older
/// which prevents the ability to scroll or see the player controls

export const useOldIosScrollFix = () => {
  if (
    // conditional flow here is safe since the conditions won't change during the lifecycle of the app
    getConfig('player.enableOldIosScrollFix') || // force on for devtest
    UaService.shouldManuallySetScriptContainerHeight
  ) {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    React.useLayoutEffect(() => {
      function adjust() {
        document.getElementById(
          SCRIPT_SCROLLING_CONTAINER_ID
        ).style.height = `${window.innerHeight - 84}px`;
      }

      adjust();
      window.addEventListener('resize', adjust);
      window.addEventListener('orientationchange', adjust);

      return () => {
        window.removeEventListener('resize', adjust);
        window.removeEventListener('orientationchange', adjust);
      };
    }, []);
  }
};
