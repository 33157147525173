import React, { useState, useRef } from 'react';
import { useSwitch } from '@naan/hooks/use-switch';
import { usePopper } from 'react-popper';
import { getColor, includeTextStyles } from 'legacylib/theme/utils';
import styled from 'styled-components';

const TipWrapper = styled.div`
  box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.2);
  border-radius: 8px;
  background-color: ${getColor('yellow200')};
  text-align: center;
  border: 1px solid ${getColor('yellow300')};
  z-index: 1001;

  & > .tip-content {
    ${includeTextStyles('body')}
    color:rgba(00,00,00, .7);
    padding: 12px 16px;
  }

  & > .tip-arrow {
    position: absolute;
    width: 10px;
    height: 10px;

    svg {
      width: 18px;
      height: 10px;

      .arrow-stroke {
        fill: ${getColor('yellow300')};
      }

      .arrow-fill {
        fill: ${getColor('yellow200')};
      }
    }
  }

  &[data-popper-placement^='bottom'] > .tip-arrow {
    left: 0;
    top: -10px;
    svg {
      transform: rotate(180deg);
    }
  }

  &[data-popper-placement^='right'] > .tip-arrow {
    left: -14px;
    svg {
      transform: rotate(90deg);
    }
  }
  &[data-popper-placement^='left'] > .tip-arrow {
    right: -6px;
    svg {
      transform: rotate(-90deg);
    }
  }
  &[data-popper-placement^='top'] > .tip-arrow {
    left: -10px;
    bottom: -10px;
  }
`;

const TipArrow = () => (
  <svg viewBox="0 0 18 10" fill="none">
    <g clipPath="url(#clip0)">
      <g filter="url(#filter0_d)">
        <path d="M9 10L1 1H17L9 10Z" className="arrow-stroke" />
      </g>
      <path d="M9 10L0 0H18L9 10Z" className="arrow-stroke" />
      <path d="M9 8.5L1.33594 0H16.6719L9 8.5Z" className="arrow-fill" />
    </g>
    <defs>
      <filter
        id="filter0_d"
        x="-3"
        y="-2"
        width="24"
        height="17"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
        />
        <feOffset dy="1" />
        <feGaussianBlur stdDeviation="2" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.2 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow"
          result="shape"
        />
      </filter>
      <clipPath id="clip0">
        <rect width="18" height="14" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export const Tooltip = ({
  placement = 'top',
  tipContent = null,
  showTip = false,
  children,
}) => {
  const buttonRef = useRef(null);
  const popperRef = useRef(null);
  // the ref for the arrow must be a callback ref
  const [arrowRef, setArrowRef] = useState(null);

  const { styles, attributes } = usePopper(
    buttonRef.current,
    popperRef.current,
    {
      placement,
      modifiers: [
        {
          name: 'arrow',
          options: {
            element: arrowRef,
          },
        },
        {
          name: 'offset',
          options: {
            offset: [0, 10],
          },
        },
      ],
    }
  );

  return (
    <>
      <span ref={buttonRef}>{children}</span>
      {showTip ? (
        <TipWrapper
          ref={popperRef}
          style={styles.popper}
          {...attributes.popper}
        >
          <div ref={setArrowRef} style={styles.arrow} className="tip-arrow">
            <TipArrow />
          </div>
          <div className="tip-content">{tipContent}</div>
        </TipWrapper>
      ) : null}
    </>
  );
};

export const UncontrolledTooltip = ({
  event = 'click',
  initialShow = false,
  children,
  ...props
}) => {
  const [show, { on, off, toggle }] = useSwitch(initialShow);

  const triggerProps =
    event === 'hover'
      ? {
          onMouseEnter: on,
          onMouseOut: off,
        }
      : {
          onClick: toggle,
        };
  return (
    <Tooltip showTip={show} {...props}>
      <span {...triggerProps}>{children}</span>
    </Tooltip>
  );
};
