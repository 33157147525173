import * as React from 'react';
import { AppFactory } from 'app/app-factory';
import { createLogger } from 'app/logger';
import { useParams, useSearchParams } from 'react-router-dom';
// import { PlayIcon } from 'naan/icons/play-icon';
// import { IconButton } from 'naan/primitives/button';
// import { HeadphonesIcon } from 'naan/icons/headphones-icon';
import { PlayerMode } from '@common/misc-types';
import { StudyData } from '@tikka/client/catalog-types';
import { reaction } from 'mobx';
import { StudyUI } from 'study/views/study-ui';
import { getSnapshot } from '@ts-state-tree/tst-core';
import { FullScreenLoader } from 'components/ds/modals';

const log = createLogger('study-screen');

export const updateProgress = () => {
  const model = AppFactory.studyModel;
  const story = model.chapter.story;

  log.debug(
    `updateProgress: eoc: ${model.completionReached}, cp: ${model.studyData.position}, sess iter: ${model.sessionIteration}, millis: ${model.furthestMillis}`
  );

  const { playerSettings } = AppFactory.root.userManager.userData;

  // will get persisted along with progress below
  if (model.playerMode === PlayerMode.STUDY) {
    playerSettings.setAll({
      playbackRate: model.player.playbackRate,
      redactionMode: model.redactionMode,
    });
  }
  log.debug(`player settings: ${JSON.stringify(getSnapshot(playerSettings))}`);

  story.progress.updateProgress({
    unitNumber: model.studyData.unitNumber,
    chapterPosition: model.studyData.position,
    sessionIteration: model.sessionIteration,
    millis: model.furthestMillis,
  });
};

export const useStudyLoader = () => {
  const { storyManager } = AppFactory.root;

  // const { slug } = useParams<{ slug: string }>();
  const { unitSlug, chapterPosition: chapterPositionStr } =
    useParams<{ unitSlug: string; chapterPosition: string }>();

  const story = storyManager.story(unitSlug);

  const [queryParams /*, setSearch*/] = useSearchParams();
  const playerMode = queryParams.get('stage') as PlayerMode;
  const startMillis = Number(queryParams.get('ms'));

  const unitData = storyManager.unitData(unitSlug);

  const chapterPosition = Number(chapterPositionStr);
  const chapter = unitData?.chapters[chapterPosition - 1];

  const [dataLoaded, setDataLoaded] = React.useState<boolean>(false);

  React.useEffect(() => {
    if (!chapter) {
      return;
    }
    var reactionDisposer: () => void;

    fetch(chapter.playerDataUrl)
      .then(response => response.json() as Promise<StudyData>)
      .then(studyData => {
        const model = AppFactory.newStudyModel();
        model.initFromStudyData(studyData);

        // assume we can only initiate a study session against the current chapter
        const sessionIteration =
          chapter.story?.progress?.currentPoint?.iteration || 1;

        model.initChapterSession({
          chapter,
          playerMode,
          startMillis,
          sessionIteration,
        });

        const { playerSettings } = AppFactory.root.userManager.userData;
        if (playerMode === PlayerMode.STUDY) {
          model.player.setPlaybackRate(playerSettings.playbackRate);
          if (startMillis === 0) {
            log.info('onloadModalNeeded startMillis', startMillis);
            model.setOnloadModalNeeded(true); // triggers auto-open of chapter notes
          }
        }
        model.setRedactionMode(playerSettings.redactionMode);
        model.setReady();

        // no current need to persist upon load
        // updateProgress();

        // automatically persist progress when end of chapter reached
        reaction(
          () => model.afterNotionalCompletion,
          atEnd => {
            if (atEnd) {
              updateProgress();
            }
          }
        );

        setDataLoaded(true);
      });

    return () => {
      // log.debug('useEffect cleanup');
      if (reactionDisposer) {
        reactionDisposer();
      }
    };
  }, [chapter, playerMode, startMillis]);

  return {
    story,
    dataLoaded,
    unitData,
    chapter,
  };
};

export const StudyScreen = () => {
  const { dataLoaded, unitData, chapter } = useStudyLoader();

  if (!dataLoaded) {
    return <FullScreenLoader />;
  }

  // we assume catalog is fully loaded now before we can reach this screen
  if (!unitData || !chapter) {
    return <>unexpectedly missing catalog data</>;
  }

  return <StudyUI />;
};
