import { useEffect } from 'react';
import { reaction } from 'mobx';
import { elementIdToDomId } from '@tikka/elements/element-id-utils';
import { usePlayerModel } from 'player/views/player-model-context';
import { scrollToLine } from '../../study/views/fx/scrolling';
import { initializeStandardMembershipContext } from './membership-context';

export const useModelSetup = () => {
  const model = usePlayerModel();
  const membershipContext = initializeStandardMembershipContext();

  const {
    wordMembershipReconciler,
    lineMembershipReconciler,
    // @jason, @armando, would you prefer this to be named Passage.. Speaker.. or SpeakerLabel..?
    speakerMembershipReconciler,
    passageMembershipReconciler,
  } = membershipContext;

  const disposers: (() => void)[] = [];

  disposers.push(
    reaction(
      () => model.wordMembershipLists,
      () => {
        wordMembershipReconciler.reconcileMembershipLists(
          '',
          model.wordMembershipLists
        );
      },
      { fireImmediately: true }
    )
  );

  disposers.push(
    reaction(
      () => model.lineMembershipLists,
      () => {
        lineMembershipReconciler.reconcileMembershipLists(
          '',
          model.lineMembershipLists
        );
        const currentSentence =
          model.lineMembershipLists.get('currentLine')?.elements.values[0] ??
          null;
        if (currentSentence) {
          const elementDomId = elementIdToDomId(null, currentSentence.id);
          scrollToLine(document.getElementById(elementDomId));
        }
      },
      { fireImmediately: true }
    )
  );

  disposers.push(
    reaction(
      () => model.speakerMembershipLists,
      () => {
        speakerMembershipReconciler.reconcileMembershipLists(
          '',
          model.speakerMembershipLists
        );
      },
      { fireImmediately: true }
    )
  );

  disposers.push(
    reaction(
      () => model.passageMembershipLists,
      () => {
        passageMembershipReconciler.reconcileMembershipLists(
          '',
          model.passageMembershipLists
        );
      },
      { fireImmediately: true }
    )
  );

  useEffect(() => {
    return () => {
      // teardown
      membershipContext.lineMembershipReconciler = null;
      membershipContext.getMemberships = null;
      for (const disposer of disposers) {
        disposer();
      }
      // disposers = [];
      disposers.length = 0;
    };
  }, []);
};
