import React from 'react';
import { Dialog } from './dialog';
import { useSwitch } from '@naan/hooks/use-switch';
import { isFunction } from 'lodash';

export const useConfirm = hookProps => {
  const [showingPrompt, { on, off }] = useSwitch(false);

  const { onConfirm } = hookProps;

  const handleOk = () => {
    off();
    if (isFunction(onConfirm)) {
      onConfirm();
    }
  };

  const Component = props => {
    if (!showingPrompt) {
      return null;
    }

    return (
      <Dialog
        okButtonAction={handleOk}
        cancelButtonAction={off}
        {...hookProps}
        {...props}
      />
    );
  };

  return [Component, { on, off }];
};
