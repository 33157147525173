import { AdhocRangeElement, IElement, NO_INDEX, WordId } from '../basic-types';
import { makeAdhocRangeElement } from '../elements/ad-hoc-word-range';
import { CreatePrecedence } from '../elements/precedence';
import { CreateElementTreeRenderer } from '../elements/element-tree-renderer';
import { trimPunctuation } from '../misc/string-utils';
import {
  stripUnderscores,
  toUnicodePunctuation,
} from '@utils/content-string-utils';
import { PlayerData, StudyData } from './catalog-types';
import {
  ElementNode,
  ElementList,
  Element,
  Word,
  SimpleElementList,
  WordElementList,
  IDTOf,
  ElementId,
  Chapter,
  Passage,
} from './client-aliases';
import { CreateElementList } from '../elements/element-list';
import { ClientSentence } from './client-types';
// import { createLogger } from 'app/logger';

// const log = createLogger('client-data');

export const playerTreePrecedence = CreatePrecedence([
  'CHAPTER', // treated as point
  'CHAPTER_NOTE', // point
  'PASSAGE', // point
  'PARAGRAPH', // treated as point
  'SENTENCE', // node
  // WORD - implied
] as const);

export function extractItalicsRanges(
  words: WordElementList
): ElementList<AdhocRangeElement> {
  const wordElements = words.values;
  let transition: boolean;
  let beginItalics: boolean;
  let endItalics: boolean;
  let beganItalics: number = NO_INDEX;
  const italicRanges: AdhocRangeElement[] = [];
  for (const [index, word] of wordElements.entries()) {
    beginItalics = false;
    endItalics = false;
    let text = word.text;
    transition = text.includes('_');
    if (transition) {
      text = trimPunctuation(text);
      beginItalics = text.startsWith('_');
      endItalics = text.endsWith('_') || text.includes('_ ');
    }
    if (beginItalics && beganItalics === NO_INDEX) {
      beganItalics = index;
    }
    if (endItalics && beganItalics !== NO_INDEX) {
      const begin = beganItalics.toString() as WordId;
      const end = index.toString() as WordId;
      italicRanges.push(makeAdhocRangeElement({ begin, end }, words));
      beganItalics = NO_INDEX;
    }
    word.text = stripUnderscores(word.text);
  }
  return CreateElementList({ elements: italicRanges, words });
}

export function buildContentElements(
  playerData: PlayerData
): ElementList<Element> {
  // TODO think assuming all used word anchors are rewritten to reference only active words on exporting data
  const wordElements = playerData.words.map((w: Word, i: number) => {
    w.kind = 'WORD';
    w.id = i.toString() as IDTOf<Word>;
    w.text = toUnicodePunctuation(w.text); // emdashes, elipsis
    return w;
  }) as Word[];

  const words = SimpleElementList(wordElements);

  // hack in passage time data until provided during ingestion
  // will eventually be an interval, but start time is enough for the moment
  for (const e of playerData.elements) {
    if (e.kind === 'PASSAGE') {
      const passage = e as Passage;
      const word = wordElements[passage.address];
      passage.time = word.time;
    }
  }

  const remapKinds = {
    PARAGRAPH_SPAN: 'PARAGRAPH',
    CHAPTER_SPAN: 'CHAPTER',
  } as any;

  const elements = playerData.elements.map((e: IElement, i: number) => {
    if (e.kind in remapKinds) {
      e.kind = remapKinds[e.kind] as any;
    }
    if (e.kind === 'CHAPTER') {
      const chapter = e as Chapter;
      // hack until all data reingested
      chapter.position = (playerData as unknown as StudyData).position;
    }
    // don't munge notation id's
    // todo: better handling of perma vocab slugs
    if (e.kind !== 'NOTATION') {
      e.id = (e.kind + ':' + i.toString()) as ElementId;
    }
    return e;
  });

  const result = CreateElementList({
    elements: elements as any, // TODO fix this when player data and local type space sync
    words: words,
  }) as ElementList<Element>;
  ensureEndOfSentenceGaps(result);
  return result;
}

export function buildElementNodes(
  elements: ElementList<Element>
): ElementNode[] {
  const treeModel = CreateElementTreeRenderer(
    elements,
    elements.words.values,
    playerTreePrecedence,
    ['CHAPTER', 'PARAGRAPH', 'CHAPTER_NOTE', 'PASSAGE'],
    ['SENTENCE']
  );
  const tree = treeModel.getTreeOfNodes();
  return tree;
}

function ensureEndOfSentenceGaps(elist: ElementList<Element>) {
  const tree = buildElementNodes(elist); // JE: took 0.5ms when testing on dev box
  const sentenceNodes = tree.filter(node => node.element.kind === 'SENTENCE');
  let lastSentenceInclusiveEndTime = -1;

  for (const sentenceNode of sentenceNodes) {
    // could optimize by slicing words by sentence addresses instead of using tree
    const sentence = sentenceNode.element as ClientSentence;
    if (sentence.time <= 0) {
      // currently need to guarantee a gap before the first sentence for back navigation to be allowed
      sentence.time = 1;
    }
    if (sentence.time <= lastSentenceInclusiveEndTime) {
      sentence.time = lastSentenceInclusiveEndTime + 1;
      // log.debug(
      //   `ensureEndOfSentenceGaps - overlapped sentence boundaries detected - ${sentence.time}`
      // );
    }
    lastSentenceInclusiveEndTime = sentence.endTime;
    const wordNodes = sentenceNode.children;
    const words = wordNodes.map(node => node.element as Word);
    ensureDurations(
      words,
      sentence.time /* inclusive floor*/,
      sentence.endTime - 2 /*exclusive ceiling*/
    ); // ensures a 3 second gap (inclusive boundaries) at the sentence end
  }
}

// ensure words time ranges are at least 1ms long and don't overlap boundaries (inclusive boundaries)
function ensureDurations(
  words: Word[],
  inclusiveFloorTime: number,
  exclusiveCeilingTime: number
) {
  if (words.length === 0) {
    return; // not expected, but ignore empty sentence if unexpectedly encountered
  }

  for (let i = words.length - 1; i >= 0; i--) {
    const word = words[i];
    if (word.endTime >= exclusiveCeilingTime) {
      word.endTime--;
      if (word.time > word.endTime) {
        word.time = word.endTime;
      }
      // log.debug(
      //   `ensureDuration ceiling adjustment: (${word.time}, ${word.endTime})`
      // );
    }
    exclusiveCeilingTime = word.time;
  }

  // deal with any zero length words at the start, or first word of overlapped sentences
  if (words[0].time < inclusiveFloorTime) {
    for (let i = 0; i < words.length; i++) {
      const word = words[i];
      if (word.time < inclusiveFloorTime) {
        word.time = inclusiveFloorTime;
        if (word.endTime < word.time) {
          word.endTime = word.time;
        }
        // log.debug(
        //   `ensureDuration floor adjustment: (${word.time}, ${word.endTime})`
        // );
      }
      inclusiveFloorTime = word.time + 1;
    }
  }
}
