import React, { useRef, useEffect } from 'react';
import { IconOverflow } from '../icons';
import { Menu } from './menu';
import { getColor } from 'legacylib/theme/utils';
import { useSwitch } from '@naan/hooks/use-switch';
import { useOnClickOutside } from 'common/hooks/use-onclick-outside';
import { CSSTransition } from 'react-transition-group';

import styled from 'styled-components';

let zIndex = 0;

// TODO: extract the icon button itself as a component
const OverflowWrapper = styled.div`
  --button-size: 24px;
  width: var(--button-size);
  height: var(--button-size);
  position: relative;
  transform: translate3d(0, 0, 0);
  z-index: ${p => 1000 + p.zIndex};

  & > .button {
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    color: ${getColor('gray400')};
    width: var(--button-size);
    height: var(--button-size);
    &.type-default {
      &:hover {
        color: ${getColor('teal600')};
      }
    }
    &.type-outline {
      --button-size: 32px;
      border: 1px solid ${getColor('gray100')};
      border-radius: 16px;
      &:hover {
        background: ${getColor('white')};
        color: ${getColor('teal600')};
      }
    }
  }

  & > .fade {
    &-enter {
      opacity: 0;
      transform: translate3d(0, -24px, 0) scale3d(1, 0.8, 1);
    }
    &-enter-active {
      opacity: 1;
      transform: translate3d(0, 0, 0) scale3d(1, 1, 1);
      transition: opacity 130ms ease-in, transform 150ms ease-in-out;
    }
    &-exit {
      opacity: 1;
      transform: translate3d(0, 0, 0) scale3d(1, 1, 1);
    }
    &-exit-active {
      opacity: 0;
      transform: translate3d(0, 0, 0) scale3d(1, 1, 1);
      transition: opacity 110ms ease-in, transform 80ms ease-out 20ms;
    }
  }

  & > .menu-container {
    min-width: 280px;
    position: absolute;
    z-index: ${p => 1500 + p.zIndex};

    &.position-top-left {
      left: 0;
      top: 28px;
    }
    &.position-top-right {
      right: 0;
      top: 28px;
    }
  }
`;

export const OverflowMenu = ({
  buttonType = 'default',
  menuPosition = 'top-left',
  ...props
}) => {
  const [show, showSwitch] = useSwitch();
  const menuRef = useRef();
  const btnRef = useRef();
  useOnClickOutside(
    menuRef,
    event => {
      // this is a neat hack to support confirmable menu actions.
      // we check if the target is a descendant of the special class `__modal-container`
      // if so, that means that the user clicked inside a dialog.
      // in that case we don't fire the `off` callback.
      if (!event.target.closest('.__modal-container')) {
        showSwitch.off();
      }
    },
    { exception: btnRef } // otherwise, clicking on the button itself retriggers the action
  );

  useEffect(() => {
    zIndex++;
  }, [show]);

  const afterAction = showSwitch.off;

  return (
    <OverflowWrapper zIndex={zIndex}>
      <div
        className={`button type-${buttonType}`}
        onClick={showSwitch.toggle}
        ref={btnRef}
        data-test-id="overflow-menu"
      >
        <IconOverflow />
      </div>
      <CSSTransition in={show} timeout={300} unmountOnExit classNames="fade">
        <div
          className={`menu-container position-${menuPosition}`}
          ref={menuRef}
        >
          <Menu {...props} afterAction={afterAction} />
        </div>
      </CSSTransition>
    </OverflowWrapper>
  );
};
