import React from 'react';
import { observer } from 'mobx-react';
import { styled } from '@naan/stitches.config';
import { IconButton } from '@naan/primitives/button';
import { CloseIcon } from '@naan/icons/close-icon';
import { HelpCircleIcon } from '@naan/icons/help-circle-icon';
import { getPlayerModel } from 'player/views/player-model-context';

const Wrapper = styled('div', {
  position: 'fixed',
  inset: 20,
  background: '$colors$gray-100',
  zIndex: 3,
  borderRadius: 12,
  display: 'none',
  flexDirection: 'column',
  padding: '$space$10 $space$4 $space$4 $space$4',

  '&.open': {
    display: 'flex',
  },

  '& > .close-button': {
    position: 'absolute',
    right: 0,
    top: 0,
  },
});

interface Props {}

@observer
export class TempPlayerControlsOverlay extends React.Component<Props> {
  model = getPlayerModel();
  keyboardist: any; // TODO typing

  render() {
    return (
      <>
        <IconButton
          icon={<HelpCircleIcon color={'cyan'} />}
          onClick={() => this.model.setHelpOverlayShown(true)}
          css={{ position: 'fixed', top: 8, right: 64 }}
        />
        <Wrapper className={`${this.model.helpOverlayShown ? 'open' : ''}`}>
          <div className="close-button">
            <IconButton
              icon={<CloseIcon />}
              onClick={() => this.model.setHelpOverlayShown(false)}
            />
          </div>
          <ul>
            <li>Tab = Toggle study/listen mode</li>
            <li>Space = Toggle Play/Pause etc</li>
            <li>Esc = Cancel delayed pause</li>
            <li>Left/Right = Rewind/Forward nav stop</li>
            <li>Up/Down = Rewind/Forward sentence</li>
            <li>+/- = Adjust speed</li>
            <li>R = Replay Current Sentence</li>
            <li>S = Snail Replay</li>
            <li>Enter = Reveal current sentence</li>
            <li>T = Toggle translations</li>
            <li>? = Toggle help</li>
            <li>~ = Toggle debug mode (then O = toggle debug overlay)</li>
          </ul>
        </Wrapper>
      </>
    );
  }
}
