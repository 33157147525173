//
// soundbite.devtest.jiveworld.com
//

const env = {
  appSlug: 'soundbites', // for demo/trial deployment, locks down UI to just the soundbite

  forcedApiEnv: 'preprod', // shouldn't be relevant

  analytics: {
    segmentWriteKey: 'HP0iVsUQB6F9CawQ6uVEZLD9kpcrZcJ3', // lupa-spa (testing)
    // property: jiveworld devtest, stream name: my.devtest.jiveworld.com
    googleAnalyticsKey: 'G-PSM9E3F4ZF',

    coalesceAnonymous: false, // enables default anonymous id tracking
  },

  logging: {
    enableConsoleLog: true,
    minimumLogLevel: 2 /* 2 = debug, 3 = info, 4 = warn */,
  },

  // no overrides needed
  // soundbites: {
  // },

  player: {
    debug: true, // controls on the '~' debug mode default
    enableOldIosScrollFix: false,
  },
};

export default env;
