import { styled, theme } from 'naan/stitches.config';
const { colors } = theme;
export const Tag = styled('div', {
  textStyle: 'tag',
  borderRadius: '4px',
  backgroundColor: colors['gray-100'],
  border: `1px solid ${colors.transparent}`,
  py: '3px',
  px: '7px',
  variants: {
    mode: {
      light: {
        $$topicColor: '$colors$gray-500',
        $$topicBackgroundColor: '$colors$white',
        $$topicBorderColor: '$colors$gray-100',
        $$topicHoverColor: '$colors$gray-500',
        $$topicHoverBackgroundColor: '$colors$gray-50',
        $$topicHoverBorderColor: '$colors$gray-100',
        $$countryColor: '$colors$gray-500',
        $$countryBackgroundColor: '$colors$gray-50',
        $$countryBorderColor: '$colors$gray-50',
        $$countryHoverColor: '$colors$gray-500',
        $$countryHoverBackgroundColor: '$colors$gray-100',
        $$countryHoverBorderColor: '$colors$gray-100',
      },
      dark: {
        $$topicColor: '$colors$white',
        $$topicBackgroundColor: '$colors$white-alpha-30',
        $$topicBorderColor: '$colors$white-alpha-30',
        $$topicHoverColor: '$colors$white',
        $$topicHoverBackgroundColor: '$colors$black-alpha-10',
        $$topicHoverBorderColor: '$colors$white-alpha-30',
        $$countryColor: '$colors$white',
        $$countryBackgroundColor: '$colors$black-alpha-15',
        $$countryBorderColor: '$colors$black-alpha-15',
        $$countryHoverColor: '$colors$white',
        $$countryHoverBackgroundColor: '$colors$black-alpha-30',
        $$countryHoverBorderColor: '$colors$black-alpha-30',
      },
    },
    type: {
      topic: {
        color: '$$topicColor',
        backgroundColor: '$$topicBackgroundColor',
        borderColor: '$$topicBorderColor',
        $$hoverColor: '$$topicHoverColor',
        $$hoverBackgroundColor: ' $$topicHoverBackgroundColor',
        $$hoverBorderColor: '$$topicHoverBorderColor',
      },
      country: {
        color: '$$countryColor',
        backgroundColor: '$$countryBackgroundColor',
        borderColor: '$$countryBorderColor',
        $$hoverColor: '$$countryHoverColor',
        $$hoverBackgroundColor: '$$countryHoverBackgroundColor',
        $$hoverBorderColor: '$$countryHoverBorderColor',
      },

      // ib: {
      //   backgroundColor: colors['transparent'],
      //   borderColor: colors['yellow-300'],
      //   color: colors['gray-500'],
      // },
      // ap: {
      //   backgroundColor: colors['transparent'],
      //   borderColor: colors['orange-300'],
      //   color: colors['gray-500'],
      // },
      // ib: {
      //   backgroundColor: colors['transparent'],
      //   borderColor: colors['gray-100'],
      //   color: colors['textSecondary'],
      //   pl: '34px',
      //   '&::before': {
      //     content: 'IB',
      //     color: colors['green-500'],
      //     textStyle: 'small-text-bold',
      //     width: '20px',
      //     marginLeft: '-34px',
      //     paddingLeft: '10px',
      //     paddingRight: '4px',
      //   },
      // },
      // ap: {
      //   backgroundColor: colors['transparent'],
      //   borderColor: colors['gray-100'],
      //   color: colors['textSecondary'],
      //   pl: '34px',
      //   '&::before': {
      //     content: 'AP',
      //     color: colors['blue-500'],
      //     textStyle: 'small-text-bold',
      //     width: '20px',
      //     marginLeft: '-34px',
      //     paddingLeft: '10px',
      //     paddingRight: '4px',
      //   },
      // },
    },
  },
  defaultVariants: {
    mode: 'light',
  },
});
