import { createAtom, IAtom } from 'mobx';
import { useEffect } from 'react';
import { useTickler } from './tickler';
import { isNull } from '@utils/conditionals';

export class Signal {
  value = 0;
  // boxed: IObservableValue<number> = null;
  mobxAtom: IAtom = null;
  fastReactions: (() => void)[] = [];

  doFastReactions() {
    for (const callback of this.fastReactions) {
      callback();
    }
  }

  set(aValue: number) {
    if (aValue !== this.value) {
      this.value = aValue;
      this.doFastReactions();
      if (this.mobxAtom) {
        this.mobxAtom.reportChanged();
      }
    }
  }

  watch(): number {
    if (isNull(this.mobxAtom)) {
      this.mobxAtom = createAtom('signal');
    }
    this.mobxAtom.reportObserved();
    return this.value;
  }

  addFastReaction(f: () => void): () => void {
    this.fastReactions.push(f);
    return () => {
      this.fastReactions = this.fastReactions.filter(r => r !== f);
    };
  }
}

export function useTickleWithSignal(tickler: () => void, signal: Signal): void {
  if (!tickler) {
    /* eslint-disable */
    tickler = useTickler();
    /* eslint-enable */
  }
  useEffect(() => {
    return signal.addFastReaction(tickler);
  }, [signal, tickler]);
}
