import * as React from 'react';
import { observer } from 'mobx-react';
import { AppFactory } from 'app/app-factory';
import { reaction } from 'mobx';
import { PlayerMode } from '@common/misc-types';
import { PlayerControls } from 'player/views/player-controls';
import cx from 'classnames';
import { PlayerStatus } from 'player/models/player-model';
import { useModelSetup } from 'player/views/player-ui-model-setup';
import {
  PlayerUIConfig,
  usePlayerUIConfig,
} from 'player/views/player-ui-config';
import { Sentence, SpeakerLabel } from 'player/views/player-ui-elements';
import {
  PlayerUIBody,
  SCRIPT_SCROLLING_CONTAINER_ID,
} from 'player/views/player-ui-components';
import {
  LayoutContainer,
  ScriptOuterContainer,
  ScriptInnerContainer,
  ControlsContainer,
} from './sb-layout';

import { SbHeader } from './sb-header';
import { ScriptHeader } from './info/script-header';
import { Question } from './info/question';
import { Answer } from './info/answer';
import { SbToolbar } from './sb-toolbar';
import { SbFinalCard } from './sb-final-card';
import { SoundbiteScrollObserver, scrollTo } from 'soundbite/fx/scrolling';
import { DebugOverlay } from 'player/views/player-ui-elements/debug-overlay';
import { useOldIosScrollFix } from 'player/views/use-old-ios-scroll-fix';
import { createLogger } from 'app/logger';
import { VSpacer } from '@naan/primitives/spacer';

const log = createLogger('soundbite-ui');

const elementTypeMap: { [index: string]: any } = {
  PARAGRAPH: SpeakerLabel,
  SENTENCE: Sentence,
};

const config: PlayerUIConfig = { elementTypeMap };

// this layer doesn't rerender, which is important for the membership reconciler setup within 'useModelSetup'.
// not entirely sure why this wasn't causing noticable problems before.
export const SoundbiteUI = () => {
  log.debug('SoundbiteUI - render');
  usePlayerUIConfig(config); // injects flavor specific behavior into player-ui-components
  useModelSetup(); // setups up membership reconcilers and reaction behaviors shared with both players
  useOldIosScrollFix();

  return <ObservingSoundbiteUI />;
};

export const ObservingSoundbiteUI = observer(() => {
  log.debug('ObservingSoundbiteUI - render');
  const model = AppFactory.soundbiteModel;
  const playing = model.playerStatus !== PlayerStatus.PAUSED;

  const observingContainerRef = React.useRef<HTMLDivElement>(null);
  const scriptContainerRef = React.useRef<HTMLDivElement>(null);

  React.useLayoutEffect(() => {
    const scrollObserver = new SoundbiteScrollObserver();
    scrollObserver.observeElement(observingContainerRef.current);
    return () => {
      scrollObserver.unobserve();
    };
  }, []);

  React.useEffect(() => {
    /// we need to use a reaction to manually update teh showing-translation class
    /// if we use react for it, we will replace the whole DOM underneath and lose
    /// other places where the DOM is updated directly.
    /// This is a non-react way to do things, but it works.
    return reaction(
      () => model.translationsShown,
      () => {
        if (model.translationsShown) {
          scrollTo(scriptContainerRef.current);
          /// on small devices we need to wait for the scroll to finish before we add the class
          /// but on desktop it adds an undesirable delay, so we avoid it
          const timeout = window.matchMedia('(max-width: 550px)').matches
            ? 300
            : 0;

          window.setTimeout(() => {
            scriptContainerRef.current?.classList.add('showing-translation');
            // this is to give time for the scroll to happen
            // so the user can see the translation sliding-in
            // but it's not perfect is the answer is too long
          }, timeout);
        } else {
          scriptContainerRef.current?.classList.remove('showing-translation');
        }
      },
      { fireImmediately: true }
    );
  }, []);

  return (
    <>
      <LayoutContainer
        // @armando, is there a need to check the playerMode here?
        className={cx({
          'listen-mode': model.playerMode === PlayerMode.FLUENT_LISTEN,
        })}
      >
        <SbHeader story={model.story} />

        <ScriptOuterContainer
          className={cx({ playing })}
          id={SCRIPT_SCROLLING_CONTAINER_ID}
        >
          <ScriptHeader excerpt={model.excerptData} story={model.story} />
          <div ref={observingContainerRef}>
            <Question excerpt={model.excerptData} />
          </div>
          <SbToolbar excerpt={model.excerptData} />
          <ScriptInnerContainer ref={scriptContainerRef}>
            <PlayerUIBody />
          </ScriptInnerContainer>
          <VSpacer size="6" />
          <Answer excerpt={model.excerptData} />
          <SbFinalCard />
        </ScriptOuterContainer>
        <ControlsContainer>
          <PlayerControls />
        </ControlsContainer>
        <DebugOverlay />
      </LayoutContainer>
    </>
  );
});
