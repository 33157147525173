import { GoogleAnalytics } from 'common/analytics/adapters/ga-analytics';
import { SegmentAnalytics } from 'common/analytics/adapters/segment-analytics';
import { SimulatedAnalytics } from 'common/analytics/adapters/sim-analytics';
import { AnalyticsManager } from 'common/analytics/analytics-manager';
import { DialogPresenter } from 'common/dialog-presenter';
import { MessageService } from 'common/notifications/message-service';
import { ToastService } from 'common/notifications/toast-service';
import { PlayerModel } from 'player/models/player-model';
import { PlayerModelHandle } from 'player/models/player-model-handle';
import { SoundbiteModel } from '../soundbite/models/soundbite-model';
import { StudyModel } from '../study/models/study-model';
import { AppRoot } from './app-root';

export class AppFactory {
  private static _root: AppRoot;

  public static createRoot(): AppRoot {
    return AppRoot.create({});
  }

  public static get root(): AppRoot {
    if (!AppFactory._root) {
      AppFactory._root = AppFactory.createRoot();
    }
    return AppFactory._root;
  }

  static _playerModelHandle: PlayerModelHandle;

  public static get playerModelHandle(): PlayerModelHandle {
    if (!AppFactory._playerModelHandle) {
      AppFactory._playerModelHandle = new PlayerModelHandle();
    }
    return AppFactory._playerModelHandle;
  }

  public static newStudyModel(): StudyModel {
    return AppFactory.playerModelHandle.newStudyModel();
  }

  public static newSoundbiteModel(): SoundbiteModel {
    return AppFactory.playerModelHandle.newSoundbiteModel();
  }

  public static get studyModel(): StudyModel {
    return AppFactory.playerModelHandle.studyModel;
  }

  public static get soundbiteModel(): SoundbiteModel {
    return AppFactory.playerModelHandle.soundbiteModel;
  }

  public static get playerModel(): PlayerModel {
    return AppFactory.playerModelHandle.model;
  }

  // static _studyModel: StudyModel;

  // public static get studyModel(): StudyModel {
  //   if (!AppFactory._studyModel) {
  //     AppFactory._studyModel = new StudyModel();
  //   }
  //   return AppFactory._studyModel;
  // }

  // static _soundbiteModel: SoundbiteModel;

  // public static get soundbiteModel(): SoundbiteModel {
  //   if (!AppFactory._soundbiteModel) {
  //     AppFactory._soundbiteModel = new SoundbiteModel();
  //   }
  //   return AppFactory._soundbiteModel;
  // }

  private static _analyticsManager: AnalyticsManager;

  public static createAnalyticsManager(): AnalyticsManager {
    const result = new AnalyticsManager();
    result.addAdapter(new SimulatedAnalytics());
    result.addAdapter(new GoogleAnalytics());
    result.addAdapter(new SegmentAnalytics());
    return result;
  }

  public static get analyticsManager(): AnalyticsManager {
    if (!AppFactory._analyticsManager) {
      AppFactory._analyticsManager = AppFactory.createAnalyticsManager();
    }
    return AppFactory._analyticsManager;
  }

  public static messageService = MessageService;
  public static dialogPresenter = DialogPresenter;
  public static toastService = ToastService;
}

(window as any)._app_ = AppFactory;
