import * as React from 'react';
import { observer } from 'mobx-react';

import { ElementNode } from '@tikka/client/client-aliases';

import { AppFactory } from 'app/app-factory';
import { PlayerStatus } from 'player/models/player-model';
import { NotationsListPanel } from './notations';
import { PassageHintPresentation } from './elements-presentations/passage-hint-presentation';
import {
  // ChapterTitlePresentation,
  StartOfChapter,
} from './elements-presentations/chapter-title-presentation';
import { /*Chapter,*/ Passage } from '@tikka/client/client-aliases';
import { PlayerMode } from '@common/misc-types';
import { PlayerControls } from 'player/views/player-controls';
import { CloseControl } from './controls/close-control';
import {
  LayoutContainer,
  HeaderContainer,
  ScriptOuterContainer,
  ScriptInnerContainer,
  ControlsContainer,
} from './study-layout';
import cx from 'classnames';
import { TempPlayerControlsOverlay } from './temp-player-controls-overlay';
import { NotationsDetailsPanel } from './notations/notation-details-panel';
import { scrollToCurrentLine, sentenceScrollObserver } from './fx/scrolling';
import { Sentence, SpeakerLabel } from 'player/views/player-ui-elements';
import {
  PlayerUIConfig,
  usePlayerUIConfig,
} from 'player/views/player-ui-config';
import { useModelSetup } from 'player/views/player-ui-model-setup';
import {
  PlayerUIBody,
  SCRIPT_SCROLLING_CONTAINER_ID,
} from 'player/views/player-ui-components';
// import { GlobalRedactionButton } from './global-redaction-button';
import { useReaction } from '@common/hooks/use-reaction';
import { ModeToggler } from './controls/mode-toggler';
import { EndOfChapter } from './end-of-chapter';
import { useMembershipContext } from 'player/views/membership-context';
import { elementIdToDomId } from '@tikka/elements/element-id-utils';
import { DebugOverlay } from 'player/views/player-ui-elements/debug-overlay';
import { computed } from 'mobx';
import { useOldIosScrollFix } from 'player/views/use-old-ios-scroll-fix';
import { createLogger } from 'app/logger';

const log = createLogger('study-ui');

const PassageHintUI = observer(({ node }: { node: ElementNode }) => {
  const { passageMembershipReconciler: reconciler } = useMembershipContext();
  const passage = node.element as Passage;

  const passageId = passage.id;
  const className = reconciler.getJoinedMembershipStringForElement(passageId);
  const elementDomId = elementIdToDomId(null, passageId);

  return (
    <PassageHintPresentation
      id={elementDomId}
      element={passage}
      className={className}
    />
  );
});

export const StudySentence = observer(({ node }: { node: ElementNode }) => {
  const model = AppFactory.studyModel;
  const elementId = node.element.id;
  // const selected = model.currentSentenceId === node.element.id;
  const showingNotationsPanel = computed(
    () => model.showingNotationsPanel && model.currentSentenceId === elementId
  ).get();

  return (
    <>
      <Sentence node={node} />
      {showingNotationsPanel ? <NotationsListPanel model={model} /> : null}
    </>
  );
});

const elementTypeMap: { [index: string]: any } = {
  // CHAPTER: ChapterTitleUI,
  PASSAGE: PassageHintUI,
  PARAGRAPH: SpeakerLabel,
  SENTENCE: StudySentence,
};

const config: PlayerUIConfig = { elementTypeMap };

// this layer doesn't rerender, which is important for the membership reconciler setup within 'useModelSetup'.
// not entirely sure why this wasn't causing noticable problems before.
export const StudyUI = () => {
  log.debug('StudyUI - render');
  usePlayerUIConfig(config); // injects flavor specific behavior into player-ui-components
  useModelSetup(); // setups up membership reconcilers and reaction behaviors shared with both players
  useOldIosScrollFix();

  const model = AppFactory.studyModel;

  useReaction(
    () => model.player.transportState.isPlaying,
    () => {
      if (model.player.transportState.isPlaying) {
        scrollToCurrentLine();
      }
    }
  );

  React.useLayoutEffect(() => {
    sentenceScrollObserver.connect();
    return () => {
      sentenceScrollObserver.disconnect();
    };
  }, []);

  React.useEffect(() => {
    scrollToCurrentLine();
  }, []);

  return <ObservingStudyUI />;
};

export const ObservingStudyUI = observer(() => {
  log.debug('ObservingStudyUI - render');
  const ref = React.useRef<HTMLDivElement>(null);

  const model = AppFactory.studyModel;
  const playing = model.playerStatus !== PlayerStatus.PAUSED;

  useReaction(
    () => model.translationsShown,
    () => {
      if (model.translationsShown) {
        ref.current?.classList.add('showing-translation');
      } else {
        ref.current?.classList.remove('showing-translation');
      }
    }
  );

  return (
    <>
      <LayoutContainer
        className={cx({
          'listen-mode': model.playerMode === PlayerMode.FLUENT_LISTEN,
        })}
      >
        {/* // armando, can we update these class names without rerendering the entire container? */}
        <HeaderContainer className={cx({ playing })}>
          <span className="left">
            <CloseControl />
          </span>
          <ModeToggler />
          <span className="right">
            <TempPlayerControlsOverlay />
            {/* {model.playerMode === PlayerMode.STUDY ? (
              <GlobalRedactionButton />
            ) : null} */}
          </span>
        </HeaderContainer>
        <ScriptOuterContainer
          className={cx({ playing })}
          id={SCRIPT_SCROLLING_CONTAINER_ID}
        >
          <ScriptInnerContainer ref={ref}>
            {/* <ScriptHeaderContainer>{story?.title}</ScriptHeaderContainer> */}
            {/* <BackToSentenceControls /> */}
            <StartOfChapter />
            <PlayerUIBody />
            <EndOfChapter />
          </ScriptInnerContainer>
        </ScriptOuterContainer>
        <NotationsDetailsPanel />
        <ControlsContainer>
          <PlayerControls />
        </ControlsContainer>
        <DebugOverlay />
      </LayoutContainer>
    </>
  );
});
