// import {
//   Word,
//   IDTOf,
//   // Sentence,
//   // ElementList,
//   // ElementNode,
//   // ElementTracker,
//   // Player,
//   // Paragraph,
// } from '@tikka/client/client-aliases';

// import { TransportState } from '@tikka/player/audio-transport';
// import { TimelineNavigator } from '@tikka/navigation/timeline-navigator';
// import { ElementId } from '@tikka/basic-types';
// import { RedactionMode } from './redaction-modes';
// import { PlayerData } from '@tikka/client/catalog-types';
// import { PlayerMode } from '@common/misc-types';
// import { MembershipList } from '@tikka/membership-reconciliation/membership-reconciler';
// import { Speaker } from '@core/models/catalog/speaker';
import { BasePlayerModel } from './base-player-model';

export const enum LoadingStatus {
  UNINITIALIZED = 'UNINITIALIZED',
  LOADING = 'LOADING',
  READY = 'READY',
}

export const enum PlayerStatus {
  PLAYING = 'PLAYING',
  PENDING_PAUSE = 'PENDING_PAUSE',
  PAUSED = 'PAUSED',
}

// export const enum ChapterStatus {
//   NEVER_PLAYED = 'NEVER_PLAYED',
//   PLAYING = 'PLAYING',
//   END_OF_CHAPTER = 'END_OF_CHAPTER',
// }

// should use the tikka defined flavor
// export type WordId = IDTOf<Word>;

export type PlayerModel = BasePlayerModel;

// export type PlayerModel = {
//   data: PlayerData;
//   player: Player;
//   transportState: TransportState;
//   wordTracker: ElementTracker<Word>;
//   navStopTracker: ElementTracker<Word>;
//   sentenceSpanTracker: ElementTracker<Sentence>;
//   speakerLabelTracker: ElementTracker<Paragraph>;
//   navigator: TimelineNavigator;
//   canNavigateForward: boolean;
//   playerMode: PlayerMode;
//   translationsShown: boolean;

//   // todo: trim down to what's actually needed
//   handleSnailReplayRestore: (force?: boolean) => void;
//   snailReplayCurrentSentence: () => void;
//   pause: () => void;
//   play: () => void;
//   clearPauseAfter: () => void;
//   pauseAfterCurrentSentence: () => void;
//   cancelPendingPause: () => void;
//   replayCurrentSentence: () => void;
//   rewind: () => void;
//   forward: () => void;

//   // getters
//   playerStatus: PlayerStatus;
//   pauseDurationInMs: number;
//   inSnailReplayMode: boolean;
//   currentSentenceId: ElementId;
//   nextSentenceId: ElementId;
//   previousSentenceId: ElementId;
//   fluentListenMode: boolean; // needed by shared pause/play code

//   seekNextSentence: () => void;
//   seekPreviousSentence: () => void;

//   // get pausingAfterSentenceElement(): Sentence {
//   // get currentSentenceElement(): Sentence {
//   // get currentSentenceTimeInterval(): Interval {
//   // seekSentenceStartTime(id: ElementId) {
//   // sentenceSelect(id: ElementId) {

//   unredactSentenceId: (sentenceId: ElementId) => void;
//   unredactCurrentSentence: () => void;
//   toggleRedactionMode: () => void;
//   togglePlayerMode: () => void;
//   toggleTranslations: () => void;
//   toggleDebugMode: () => void;
//   resetSession: () => void;

//   ready: boolean;

//   // interfaces needed by player-ui code
//   neverPlayed: () => boolean;
//   sicStarts: Set<WordId>;
//   sicIntended: Map<WordId, string>;
//   sicMembershipList: MembershipList;
//   italicsMembershipList: MembershipList;
//   navStopMembershipList: MembershipList;
//   speakerLabels: ElementList<Paragraph>;
//   elementNodes: ElementNode[];

//   // listeningFromStart: boolean;
//   // completedListeningFromStart: boolean;
//   // firstListenComplete: boolean;

//   endOfChapterReached: boolean;
//   wordMembershipLists: Map<string, MembershipList>;
//   sentenceMembershipLists: Map<string, MembershipList>;

//   sentenceSelect: (id: ElementId) => void;
//   getSentenceRedactionMode: (sentenceId: ElementId) => RedactionMode;

//   resolveSpeaker(label: string): Speaker;
// };
