import {
  Notation,
  ElementList,
  IDTOf,
  EmptyElementList,
  Chapter,
  ChapterNote,
} from '@tikka/client/client-aliases';

import { WordId } from '@tikka/basic-types';
import { computed, makeObservable, observable, runInAction } from 'mobx';
import { StudyData } from '@tikka/client/catalog-types';
import { makeAdhocRangeElement } from '@tikka/elements/ad-hoc-word-range';
import { ChapterCatalogData } from 'core/models/catalog';
import { /*FluentListenStatus,*/ PlayerMode } from 'common/misc-types';
import {
  CreateMembershipList,
  MembershipList,
} from '@tikka/membership-reconciliation/membership-reconciler';
import { CreateElementList } from '@tikka/elements/element-list';
import { createLogger } from 'app/logger';
import {
  BasePlayerModel,
  PlayerType,
  TranslationButtonState,
} from 'player/models/base-player-model';
import { LoadingStatus, PlayerStatus } from 'player/models/player-model';
import { Speaker } from '@core/models/catalog/speaker';
import { AppFactory } from 'app/app-factory';
import { RedactionMode } from 'player/models/redaction-modes';

const log = createLogger('study-model');

export class StudyModel extends BasePlayerModel {
  playerType = PlayerType.STUDY;

  @observable.ref chapter: ChapterCatalogData;
  sessionIteration = 0; // hold state needed for updateProgress operations

  // @armando, tentatively adding these also a model properties in case that's eaasier to consume
  // within the UI than mapped element types
  @observable.ref chapterTitle: /*Client*/ Chapter = null;
  @observable.ref chapterNotes: /*Client*/ ChapterNote[] = [];

  @observable.ref notations: ElementList<Notation> = EmptyElementList;
  @observable.ref selectedNotationId: IDTOf<Notation> = null;

  // not needed by current player
  // was implemented before scope of initial web player was reduced
  // will perhaps be resurrected in the future

  // @observable.ref listeningFromStart = false;
  // @observable.ref completedListeningFromStart = false;
  // @observable.ref firstListenComplete = false; // true if END_OF_CHAPTER has ever been reached

  constructor() {
    super();
    makeObservable(this);
  }

  async initFromData(data: StudyData) {
    log.debug('initFromData');
    this.initFromPlayerData(data);
    this.setReady();
    log.debug('initFromData complete');
  }

  get studyData(): StudyData {
    return this.data as StudyData;
  }

  get dataSourceUrl(): string {
    return this.chapter.playerDataUrl;
  }

  set dataSourceUrl(value) {
    // no-op, needed to appease unit tests for some reason
  }

  async initFromStudyData(data: StudyData) {
    this.initFromPlayerData(data);

    const chapters = this.elements.filterByKind('CHAPTER');
    if (chapters.notEmpty) {
      const chapterTitle = chapters.values[0];
      const chapterNotes = this.elements.filterByKind('CHAPTER_NOTE').values;
      this.chapterNotes = chapterNotes;
      // for the moment stuffing the notes both into the chapter title element and directly on the study model.
      // tighten this up once UI refactored
      chapterTitle.notes = chapterNotes;
      this.chapterTitle = chapterTitle;
    }

    this.notations = this.elements.filterByKind('NOTATION');

    // // more state to reset?
    // this.listeningFromStart = false;
    // this.completedListeningFromStart = false;
    // this.firstListenComplete = false;
  }

  resolveEndOfMaterialMillis(): number {
    const chapterCompletes = this.elements.filterByKind('CHAPTER_COMPLETE');
    if (chapterCompletes.values.length > 0) {
      const chapterComplete = chapterCompletes.values[0];
      const endOfChapterWordAddress = chapterComplete.address;
      const result = this.words.timeIntervals.intervalAt(
        endOfChapterWordAddress
      ).begin;
      return result;
    }
    // defaults to last sentence end time
    return super.resolveEndOfMaterialMillis();
  }

  resetSession() {
    this.setStatus(LoadingStatus.UNINITIALIZED);
    this.initFromData(this.studyData);
  }

  @computed
  get selectedNotationMembershipList() {
    const notation = this.selectedNotationElement;
    let elements = [];
    const words = this.elements.words;
    if (notation) {
      const begin = notation.address.toString() as WordId;
      const end = notation.endAddress.toString() as WordId;
      elements.push(makeAdhocRangeElement({ begin, end }, words));
    }
    return CreateMembershipList({
      memberships: ['SELECTED_NOTATION'],
      elements: CreateElementList({ elements, words }),
      useRanges: true,
    });
  }

  get wordMembershipLists(): Map<string, MembershipList> {
    const result = super.wordMembershipLists;
    result.set('selectedNotationWord', this.selectedNotationMembershipList);
    return result;
  }

  initChapterSession({
    chapter,
    playerMode,
    startMillis,
    sessionIteration,
  }: {
    chapter: ChapterCatalogData;
    playerMode: PlayerMode;
    startMillis: number;
    sessionIteration: number; // listening pointer iteration. needed to update progress operations can be idempotent
  }) {
    this.chapter = chapter;
    this.playerMode = playerMode ?? PlayerMode.STUDY;
    this.player.seek(startMillis);
    // this.firstListenComplete = chapter?.isFirstListenComplete;
    // this.initFluentListenStatus(chapter?.fluentListenStatus);
    this.sessionIteration = sessionIteration;
  }

  get fluentListenMode(): boolean {
    return this.playerMode === PlayerMode.FLUENT_LISTEN;
  }

  get studyMode(): boolean {
    return this.playerMode === PlayerMode.STUDY;
  }

  get redactionMode() {
    if (this.fluentListenMode) {
      return RedactionMode.SHOW_NONE;
    }
    return this._redactionMode;
  }

  togglePlayerMode() {
    runInAction(() => {
      const { playerSettings } = AppFactory.root.userManager.userData;
      if (this.playerMode === PlayerMode.STUDY) {
        // playerSettings.setAll({
        //   playbackRate: this.player.playbackRate,
        //   redactionMode: this.redactionMode, // not strictly needed now since shadowed, but might as well
        //   // won't be fully persisted until chapter complete or player cleanly exited
        // });
        playerSettings.setPlaybackRate(this.player.playbackRate);
        this.playerMode = PlayerMode.FLUENT_LISTEN;
        // this.setTranslationsShown(false);
        this.player.setNormalPlaybackRate();
      } else if (this.playerMode === PlayerMode.FLUENT_LISTEN) {
        this.playerMode = PlayerMode.STUDY;
        // this.listeningFromStart = false;
        this.player.setPlaybackRate(playerSettings.playbackRate);
      }
    });
  }

  get translationsShown() {
    if (this.fluentListenMode) {
      return false;
    }
    return this._translationsShown;
  }

  @computed
  get showingNotationsPanel() {
    if (
      this.playerStatus === PlayerStatus.PAUSED &&
      this.playerMode === PlayerMode.STUDY
    ) {
      return true;
    }
    return false;
  }

  @computed
  get notationsPanelContent(): Notation[] {
    if (!this.showingNotationsPanel) {
      return [];
    }
    const sentence = this.currentSentenceElement;
    if (!sentence) {
      return [];
    }
    const notations = this.notations;
    return notations.getElementsIntersectRangeOf(sentence) ?? [];
  }

  selectNotationId(id: IDTOf<Notation>) {
    log.trace(`selectNotation(${id})`);
    this.selectedNotationId = id;
  }

  get hasPreviousNotation(): boolean {
    return !!this.previousNotationId;
  }

  get hasNextNotation(): boolean {
    return !!this.nextNotationId;
  }

  selectNextNotation() {
    if (this.hasNextNotation) {
      this.selectNotationId(this.nextNotationId);
    }
  }

  selectPreviousNotation() {
    if (this.hasPreviousNotation) {
      this.selectNotationId(this.previousNotationId);
    }
  }

  @computed
  get previousNotationId(): IDTOf<Notation> {
    const selected = this.selectedNotationElement;
    if (selected && selected.index > 0) {
      return this.augmentedNotations[selected.index - 1].id;
    } else {
      return null;
    }
  }

  @computed
  get nextNotationId(): IDTOf<Notation> {
    const selected = this.selectedNotationElement;
    if (selected && selected.index < this.augmentedNotations.length - 1) {
      return this.augmentedNotations[selected.index + 1].id;
    } else {
      return null;
    }
  }

  // @computed
  get selectedNotationElement() {
    const id = this.selectedNotationId;
    log.trace(`selectedNotationEl - id: ${this.selectedNotationId}`);
    if (!id) {
      return null;
    }
    const visibleNotations = this.augmentedNotations;
    const returnElement = visibleNotations.find(
      (notation: Notation) => notation.id === id
    );
    if (!returnElement) {
      return null;
    }
    return returnElement;
  }

  // assigns indexes relative to current sentence
  // todo: revisit once sure if needed or not
  get augmentedNotations(): Notation[] {
    const notations = this.notationsPanelContent;
    if (!notations.length) {
      return notations;
    }
    // const words = this.elements.words;
    let index = 0;
    for (const notation of notations) {
      // const notationWords = words.elements.slice(
      //   notation.address,
      //   notation.endAddress + 1
      // );
      // assume headword always resolved during ingestion
      // if (!notation.headword) {
      //   // default to transcript text if headword not already provided
      //   let wordString = '';
      //   for (const word of notationWords) {
      //     wordString = wordString + word.text + ' ';
      //   }
      //   notation.headword = wordString.trim();
      // }
      notation.index = index;
      index++;
    }
    return notations;
  }

  neverPlayed() {
    return this.wordTracker.furthestTrackedPosition() === 0;
  }

  // todo: should perhaps resolve at data load time
  resolveSpeaker(label: string): Speaker {
    if (!label) {
      return null;
    }

    const { storySlug } = this.studyData;
    const story = AppFactory.root.storyManager.story(storySlug);
    if (story) {
      return story.resolveSpeaker(label);
    } else {
      log.error(`missing speaker data for label: ${label}`);
      return null;
    }
  }

  get complexPlayActionEnabled(): boolean {
    return !this.fluentListenMode;
  }

  get playActionDisabled(): boolean {
    return this.atAudioEnd;
  }

  playPauseAction() {
    const playerMode = this.playerMode;
    if (playerMode === PlayerMode.FLUENT_LISTEN) {
      this.simplePlayPauseAction();
    } else {
      this.complexPlayPauseAction();
    }
  }

  get translationButtonState(): TranslationButtonState {
    if (this.fluentListenMode) {
      return TranslationButtonState.hidden;
    } else {
      return TranslationButtonState.enabled;
    }
  }

  get skipForwardAllowed(): boolean {
    return true;
  }

  get metricsPrefix(): string {
    return 'study';
  }
}
